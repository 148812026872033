import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
const state = {
    utilTabs: [
        { name: 'Trade Settings', id: 0, active: true, count: '0' },
        // { name: 'Basket', id: 1, active: false, count: '0' },
        { name: 'Alerts', id: 1, active: false, count: '0' },
    ],
}
const actions = {
    async setActiveTab({state,commit},payload) {
        state.utilTabs.forEach(el => {
            if(el.id == payload) {
                el.active = true
            } else {
                el.active = false
            }
        });
    }
};

const mutations = {
    reset_state(state) {
        Object.assign(state, {
            utilTabs: [
                { name: 'Trade Settings', id: 0, active: true, count: '0' },
                { name: 'Alerts', id: 1, active: false, count: '0' },
            ],
        });
    },
};

const getters = {
    getUtilTabs: state => state.utilTabs,
};

const util = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default util