<template>
    <div>
        <input type="text" class="w-full px-4 py-2 border border-gray-400 rounded-md" placeholder="Search..." v-model="searchTerm" @input="handleInput">
        <ul v-if="showResults" class="absolute w-full mt-1 bg-white rounded-md shadow-lg">
            <li v-for="result in results" :key="result" class="px-4 py-2 cursor-pointer hover:bg-gray-200"
                @click="selectResult(result)">
                {{ result }}
            </li>
        </ul>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            searchTerm: '',
            results: [],
            showResults: false
        }
    },
    props: {
        heading: { type: String },
        list: { type: Array },
        stype: { type: String }
    },
    computed: {
        ...mapGetters('oc',['instrument','expiry']),
    },
    methods: {
        handleInput() {
            // TODO: Implement your search logic here
            // and update the "results" array
            this.showResults = true
        },
        selectResult(result) {
            this.searchTerm = result
            this.showResults = false
        }
    }
}
</script>