<template>
    <div v-if="!getIsMobileView">
        <div class="flex flex-col">
            <div class="overflow-x-auto" :class="showDropDown ? 'overflowinherit' : ''">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative overflow-hidden" :class="showDropDown ? 'overflowinherit' : 'overflow-y-auto'">
                        <table v-if="getCurrentEtfList?.length != 0" id="etf_table" class="w-full border-t dark:bg-[#181818]  dark:border-[#232325] border-[#ededed]" >
                            <thead class="border-b-1 dark:border-[#232325]">
                                <tr>
                                    <th @mouseover="isHover = true; currentIdx = idx"
                                        @mouseleave="isHover = false; currentIdx = -1" @click="changeSort(head.value)"
                                        scope="col" v-for="(head, idx) of tableHead" :key="idx"
                                        :class="[head.class, { 'bg-slate-50 dark:bg-zinc-800 cursor-pointer': isHover && currentIdx == idx && head.sortable }]">
                                        <span>
                                            <span :class="head.alignment">
                                                <span class="whitespace-nowrap" :id="`etf_th_${head.text}`">{{ head.text
                                                    }}</span>
                                                <span v-if="head.sortable" class="min-w-[12px] mx-1">
                                                    <span v-if="isHover && currentIdx == idx">
                                                        <span v-if="head.isdesc">
                                                            <img :src="getIsTheme == 1 ? upArrowDark : upArrow"
                                                                alt="upArrow">
                                                        </span>
                                                        <span v-if="!head.isdesc">
                                                            <img :src="getIsTheme == 1 ? downArrowDark : downArrow"
                                                                alt="downArrow">
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in getCurrentEtfList" :key="idx"
                                    class="border-b dark:border-[#232325]" @mousemove="currentIndex = idx"
                                    @mouseleave="currentIndex = -1">
                                    <td class="px-3 py-4 text-sm primary-color relative min-w-[220px]"
                                        :id="`etf_td_company_${idx}`">
                                        <span class="text-sm">{{ item.formattedInsName }}</span> <span
                                            class="text-[10px] ml-2 secondary-color">{{ item.exchange }}</span>
                                        <div class="flex absolute right-0 bottom-3" v-if="currentIndex == idx">
                                            <button :id="`etf_buy_${idx}`" @click="MshowModal(item, 0, 'buy')"
                                                class="rounded text-[14px] green-bg text-white h-6 mr-2 min-w-[34px]">B</button>
                                            <button :id="`etf_sell_${idx}`" @click="MshowModal(item, 1, 'sell')"
                                                class="rounded red-bg text-white text-[14px] h-6 mr-2 min-w-[34px]">S</button>
                                            <button id="mw_list_chart" type="button"
                                                class="h-6 min-w-[34px] bg-white dark:bg-zinc-900 
                                                border border-[#753ED7] text-[#753ED7] font-medium text-xs leading-tight uppercase
                                                rounded transition duration-150 ease-in-out hover:bg-[#753ED7] highlightHover "
                                                v-tippy="{ content: `Trading view`, classes: 'tooltipCusClass' }"
                                                @click="$common.callChart(item)">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="w-5 mx-auto changeColorHover">
                                                    <path
                                                        d="M11.25 4.5V5.625H14.9546L10.125 10.4546L7.71019 8.03981C7.6047 7.93436 7.46165 7.87512 7.3125 7.87512C7.16335 7.87512 7.0203 7.93436 6.91481 8.03981L1.125 13.8296L1.92037 14.625L7.3125 9.23287L9.72731 11.6477C9.8328 11.7531 9.97585 11.8124 10.125 11.8124C10.2742 11.8124 10.4172 11.7531 10.5227 11.6477L15.75 6.42037V10.125H16.875V4.5H11.25Z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </td>
                                    <td :class="['text-right py-4 px-3 text-sm primary-color']"
                                        :id="`etf_td_ltp_${idx}`">
                                        <div class="min-w-[100px]">
                                            <span>{{ $common.ruppesFormat(parseFloat(item.lp).toFixed(item.exchange ==
                                                "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                                        </div>
                                    </td>
                                    <td class="text-right px-3 py-4 text-sm primary-color " :id="`etf_td_open_${idx}`">
                                        <div class="min-w-[100px]">
                                            <span>{{ $common.ruppesFormat(parseFloat(item.o).toFixed(item.exchange ==
                                                "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                                        </div>
                                    </td>
                                    <td class="text-right px-3 py-4 text-sm primary-color" :id="`etf_td_high_${idx}`">
                                        <div class="min-w-[100px]">
                                            <span>{{ $common.ruppesFormat(parseFloat(item.h).toFixed(item.exchange ==
                                                "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                                        </div>
                                    </td>
                                    <td class="text-right px-3 py-4 text-sm primary-color" :id="`etf_td_low_${idx}`">
                                        <div class="min-w-[100px]">
                                            <span>{{ $common.ruppesFormat(parseFloat(item.l).toFixed(item.exchange ==
                                                "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                                        </div>
                                    </td>
                                    <td class="text-right px-3 py-4 text-sm primary-color" :id="`etf_td_close_${idx}`">
                                        <div class="min-w-[100px]">
                                            <span>{{ $common.ruppesFormat(parseFloat(item.c).toFixed(item.exchange ==
                                                "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                                        </div>
                                    </td>
                                    <td class="text-right px-3 py-4 text-sm primary-color" :id="`etf_td_volume_${idx}`">
                                        <div class="min-w-[100px]">
                                            <span>{{ $common.ruppesFormat(item.v) }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else
                            class="text-center text-base p-5 flex flex-col justify-center items-center h-[50vh]">
                            <img :src="nodatasvg" alt="nodata" class="max-w-[20%] h-auto">
                            <div class="primary-color mt-2" :id="`etf_nodata_text`">
                                No Data Found
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <mobileView v-else-if="getCurrentEtfList.length" :listData="getCurrentEtfList" @call_action="callAction" />
    <div v-else class="text-center text-base p-5 flex flex-col justify-center items-center h-[50vh]">
        <img :src="nodatasvg" alt="nodata" class="max-w-[20%] h-auto">
        <div class="primary-color mt-2" :id="`etf_nodata_text`">
            No Data Found
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import nodatasvg from '@/assets/img/no_data.svg'
import upArrow from '@/assets/img/upArrow.svg'
import upArrowDark from '@/assets/img/upArrowDark.svg'
import downArrow from '@/assets/img/downArrow.svg'
import downArrowDark from '@/assets/img/downArrowDark.svg'
import mobileView from './ETFMobView.vue';

export default {
    data() {
        return {
            currentIndex: -1,
            nodatasvg,
            tableHead: [
                { alignment: 'flex justify-start items-center', text: "Company", sortable: true, value: "formattedInsName", class: "min-w-[12rem] py-3.5 px-3 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "LTP", value: "lp", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Open", value: "o", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "High", value: 'h', sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Low", value: "l", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Close", value: "c", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Volume", value: "v", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
            ],
            pagination: {
                sortBy: null,
                descending: false
            },
            upArrow,
            upArrowDark,
            downArrow,
            downArrowDark,
            isHover: false,
            currentIdx: -1,
            showDropDown: false
        }
    },
    components: {
        mobileView
    },
    computed: {
        ...mapGetters('home', ['getCurrentEtfList']),
        ...mapGetters('login', ['userRole']),
        ...mapGetters('pref', ['getIsTheme']),
        ...mapGetters(['getIsMobileView'])
    },
    methods: {
        async MshowModal(post, type, o_type) {
            if (this.userRole != 'GUEST_USER') {
                this.$store.state.buysellpopup.showModal = true;
                this.$store.state.buysellpopup.bsselected = type;
                this.$store.commit('ow/setIsAddOrExit', o_type)
                this.$store.commit('ow/setCurrentScripData', post)
                this.$store.commit("ow/setIsLocalPreference", true);
                // 
                await this.$store.dispatch('ow/getContractInfo', { exchange: post.exchange, token: post.token })
                //
                this.$store.commit('ow/setCurrentPage', this.$options.name)
                // 
                this.$store.dispatch('ow/setDefaultValues')

                setTimeout(() => {
                    this.$store.dispatch('ow/getMargin')
                }, 100);
            }
        },
        callAction(){

        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
            this.getDataFromApi()
        },
        getDataFromApi() {
            return new Promise((resolve, reject) => {
                const { sortBy, descending } = this.pagination
                let items = this.getCurrentEtfList
                if (sortBy) {
                    items = items.sort((a, b) => {
                        const sortA = sortBy == 'formattedInsName' ? a[sortBy] : parseFloat(a[sortBy]?.toString()?.replace(/,/g, ''))
                        const sortB = sortBy == 'formattedInsName' ? b[sortBy] : parseFloat(b[sortBy]?.toString()?.replace(/,/g, ''))
                        if (descending) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                    var strArr = []
                    for (let item of items) {
                        strArr.push(item.token)
                    }
                    this.$store.commit('home/setStringEtfList', strArr)
                }
                this.tableHead.findIndex((idex) => {
                    idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
                    idex.value == sortBy ? (idex.isdesc = descending) : "";
                });
            })
        },
    },
}
</script>