import store from '../_store/index'
import FingerprintJS from "@fingerprintjs/fingerprintjs";

async function getDeviceId() {
	try {
		const fpPromise = await FingerprintJS.load();
		const fp = await fpPromise.get(); 
		store.commit("login/setDeviceId", fp.visitorId);
	} catch (error) {}
}

const fingerPrint = {
    getDeviceId: getDeviceId
}

export default fingerPrint