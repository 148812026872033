import { authHeader } from '../_helpers';
import axios from 'axios'

const AXIOS = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});



function placeOcoOrder(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/oco/execute`, payload, requestOptions)
}

function modifyOcoOrder(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/oco/modify`, payload, requestOptions)
}

function cancelOcoOrder(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/oco/cancel`, payload, requestOptions)
}

function getGTTOrders() {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.get(`od-rest/info/gtt/orderbook`, requestOptions)
}

function cancelGtt(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/oco/cancel`, payload, requestOptions)
}


function modifySingleOrder(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/gtt/modify`, payload, requestOptions)
}

function placeSingleOrder(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/gtt/execute`, payload, requestOptions)
}

function cancelSingleOrder(payload:any) {
    const requestOptions = {
        headers: authHeader(),
    };
    return AXIOS.post(`od-rest/orders/gtt/cancel`, payload, requestOptions)
}

export const service = {
    placeOcoOrder, modifyOcoOrder, cancelOcoOrder, getGTTOrders, cancelGtt, modifySingleOrder, placeSingleOrder, cancelSingleOrder
}