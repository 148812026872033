<template>
    <loader  v-if="otpLoader"/>
    <div class="flex flex-col background-img h-screen overflow-auto" v-else>
            <div class="absolute inset-0 text-slate-900/[0.03] dark:text-slate-900/[0.09]" >
                <svg class="absolute inset-0 h-screen w-full" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern id="grid-bg" width="32" height="32" patternUnits="userSpaceOnUse" x="100%"
                            patternTransform="translate(0 -1)">
                            <path d="M0 32V.5H32" fill="none" stroke="currentColor"></path>
                        </pattern>
                    </defs>
                    <rect width="100vw" height="100vh" fill="url(#grid-bg)"></rect>
                </svg>
            </div>
            <div class="relative flex flex-1 flex-col items-center justify-between py-4">
                <div>
                    <div class="py-8">
                        <div class="flex justify-center">
                            <img :src="clientLogo" alt="logo" class="max-w-[80px] h-auto" />
                        </div>

                        <div v-if="$store.state.login?.vendorDetails?.appName" class="primary-color text-base my-2 capitalize flex justify-center">
                            {{ $store.state.login?.vendorDetails?.appName }}
                        </div>
                    </div>

                    <div :class="stage == 'enableTotp' ? 'min-w-[340px]' : 'md:min-w-[680px] md:max-w-[680px]' " class="flex items-center justify-center w-full min-h-[406px] ">
                        <div class="bg-white dark:bg-zinc-800 py-4 rounded-[8px] px-6 shadow-md w-full">
                            <div :id="`${stage}_stage`" class="loginHeader font-semibold pb-5 text-xl text-center">
                                {{ (stage == 'unblockAcc' || stage == 'verifyUnblockOtp') ? 'Unblock Your Account' : (stage == 'forgotPass' || stage == 'verifyForgotOtp') ? 'Account Recovery' : stage == 'resetPass' ? 'Reset Password' : 'Let’s get started' }}
                            </div> 
                            <div class="" :class="stage == 'enableTotp' ? 'w-[340px]' : 'w-full grid divide-x md:grid-cols-2 gap-8'">
                            <div>
                                <mobile  v-if="stage == 'mobileNo'" />
                                <password  v-if=" stage == 'password'"/>
                                <email v-if="stage == 'email'"/>
                                <unblockAcc v-if="stage == 'unblockAcc'" />
                                <createPass  v-if="stage == 'createPass'" />
                                <forgetPass v-if="stage == 'forgotPass'"/>
                                <totp v-if="stage == 'totp'" />
                                <webAuthOtp v-if="stage == 'verifyWebAuthOtp'" />
                                <mobileNoOtp v-if="stage == 'verifyNoOtp'"/>
                                <emailOtp v-if="stage == 'verifyEmailOtp'"/>
                                <verifyUnblockOtp v-if="stage == 'verifyUnblockOtp'"/>
                                <verifyForgotOtp v-if="stage == 'verifyForgotOtp'"/>
                                <registerTotp v-if="stage == 'verifyScannerOtp'" />
                                <enableTotp v-if="stage == 'enableTotp'"/>
                                <resetPass v-if="stage == 'resetPass'" />
                                <authorizeVendor v-if="stage == 'authorize'" />
                            </div>
                            <!-- v-if="stage != 'forgotPass' && stage != 'unblockAcc' && stage != 'verifyForgotOtp' && stage != 'verifyUnblockOtp' && stage != 'enableTotp'" -->
                            <div class="md:flex items-center justify-center hidden max-w-[300px] w-full">
                                <div v-if="stage == 'unblockAcc' || stage == 'forgotPass' || stage == 'createPass' || stage == 'resetPass' || stage == 'email' "  class="flex items-center justify-center w-full">
                                    <img src="../../assets/img/login/recovery.svg" alt="#Account Recovery" >
                                </div>
                                <div v-else-if="stage == 'verifyUnblockOtp' || stage == 'verifyScannerOtp' || stage == 'totp' || stage == 'verifyWebAuthOtp' || stage == 'verifyForgotOtp' || stage == 'authorize' || stage == 'verifyEmailOtp' || stage == 'verifyNoOtp'"  class="flex items-center justify-center">
                                    <img src="../../assets/img/login/otp.svg" alt="#Account Recovery" >
                                </div>
                                <div v-else-if="stage == 'mobileNo'  || stage == 'password'"  class="flex items-center justify-center w-full">
                                    <!-- <img src="../../assets/img/login/mobile.svg" alt="#Account Recovery" > -->
                                    <qrLogin />
                                </div>
                                <div v-else-if="stage != 'enableTotp'"  class="flex items-center justify-center w-full">
                                    <img src="../../assets/img/login/recovery.svg" alt="#Logo" >
                                </div>
                                <!-- <qrLogin class="" v-else /> -->
                            </div>
                            </div>
                            
                            <div class="w-full flex flex-col mt-4 pt-4 items-center justify-center border-t" v-if="stage == 'mobileNo'">
                                <div class="text-center text-xs">Don't have an account?
                                    <a class="!m-0 hyperLink font-semibold opacity-70" :href="ekycUrl" target="_blank">
                                        Sign up
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
            <footer class="flex justify-center items-center w-full opacity-[0.5]">
                <div class="w-[680px]">
                <div class="border-slate-900/5 py-8 text-[#334155]">
                    <p class="text-center text-xs leading-4">
                        Sky Commodities India Private Limited |
                        SEBI Registration No: INZ000049235 | NSE Member Code: 90221 |<br>
                        BSE clearing Code: 6745 | MCX Membership No: 46365 | Exchange Version {{$store.state.exchangeVersion}} | v{{$store.state.appVersion}}</p>
                </div>
                </div>
            </footer>
            </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import mobile from "../../views/login/mobileNo.vue"
import createPass from "../../views/login/createpass.vue"
import email from "../../views/login/email.vue"
import password from "../../views/login/password.vue"
import unblockAcc from  "../../views/login/unblockAcc.vue"
import forgetPass from "../../views/login/forgetPass.vue"
import totp from "../../views/login/totp.vue"
import webAuthOtp from "../../views/login/verifyWebAuthOtp.vue"
import mobileNoOtp from "../../views/login/verifyNoOtp.vue"
import emailOtp from "../../views/login/verifyEmaiOtp.vue"
import verifyUnblockOtp from "../../views/login/verifyUnblockOtp.vue" 
import verifyForgotOtp from "../../views/login/verifyForgotOtp.vue"
import registerTotp from "../../views/login/registerForTotp.vue"
import enableTotp from "../../views/login/enableTotp.vue"
import resetPass from "../../views/login/resetPassword.vue"
// import clientLogo from "../../assets/img/nidhi-logo.svg"
import clientLogo from "../../assets/img/client-logo.svg"
import authorizeVendor from "./authorizeVendor.vue"
import qrLogin from "./qrLogin.vue"
import {env} from "../../_helpers/env_index"
import loader from "../../components/loader.vue";
export default {
    name: 'login',
    data(){
        return{
            clientLogo,
            ekycUrl : env().EKYCBASEURL,
             copyMsg: 'Copy Key'
        }
    },
    components: {
        mobile,
        password,
        email,
        createPass,
        unblockAcc,
        forgetPass,
        totp,
        webAuthOtp,
        mobileNoOtp,
        emailOtp,
        verifyUnblockOtp,
        verifyForgotOtp,
        registerTotp,
        enableTotp,
        resetPass,
        authorizeVendor,
        qrLogin,
        loader
    },
    computed: {
        ...mapGetters('login',['loader','stage','submitted' , 'otpLoader', 'getScannerData'])
    },
    methods: {
        copySecretKey() {
            navigator.clipboard.writeText(this.getScannerData?.secKey);
            this.copyMsg = 'Key Copied'
            setTimeout(() => {
                this.copyMsg = 'Copy Key'
            }, 1300);
        },
        
    },
    unmounted(){
        clearInterval(this.$store.state.login.sessionInterval)
        clearInterval(this.$store.state.login.interval)
    },
    mounted(){
        this.$fingerPrint.getDeviceId()
    }
}
</script>