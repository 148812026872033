<template>
  <div :class="{'secondary-violet-bg rounded-t': isBgBlue}" >
    <div class="flex flex-wrap gap-2 text-sm font-medium text-center " id="myTab">
      <div v-tippy="{content: isDisable(b.name) ? `Can't access mini optionchain while open full view` : ''}" class="mr-2 hover:font-bold " role="presentation" v-for="(b, id) in getTabs()" :key='id' @click="changeActive(b)" :id="`${$route.name}_${id}_b.name`">
        <button :disabled="isDisable(b.name)" :id="`${b.name}_order`" class="inline-block p-3 rounded-t-lg " type="button" :class="{ 'border-b-2 violet-color hover:violet-color dark:text-[#753ED7] dark:hover:text-[#753ED7] border-[#753ED7] dark:border-[#753ED7]' : b.active,
        'secondary-color hover:text-gray-600 dark:text-[#7a9fd2] border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300' : !b.active, 
        'cursor-not-allowed' : ($route.path == '/optionchain' && b.name == 'Option chain' || getMwListLoader)}">
          {{ b.name }} <span v-if="b.count != '0'">{{ b.count }}</span>
        </button>
      </div>
      <spinner v-if="isMwLoader && getLoader && ($route.path == '/order' || $route.path == '/position' || $route.path == '/funds' || $route.path == '/util')" />
      <!-- <spinner v-if="getMwListLoader && !isMwLoader"/> -->
      <span v-if="(page == 'positions' || page == 'orders') && !getLoader" class="ml-auto flex items-center md:px-3 px-1 py-2">
        <div class="relative w-full" v-if="isSearch">
          <div v-if="!getIsMobileView" class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-[#56585A] dark:text-[#94A3B8]">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd">
                  </path>
              </svg>
          </div>
        <div class="flex">
          <input  v-if="!getIsMobileView" maxlength="30" type="text" placeholder="Search" class="h-[26px] border border-gray-100 primary-color text-xs rounded  block w-full !pl-8 p-1 dark:bg-[#2f2f33] dark:border-[#232325] dark:placeholder-gray-400 dark:text-white focus:max-w-[170px] max-w-[100px] transition-all duration-300" autocomplete="off" v-model="searchTerm" :id="`${$store.state.activePageTab == 3 ? 'Basket' : $store.state.activePageTab == 4 ? 'Alerts': $store.state.activePageTab == 5 ? 'GTT' :$route.name}_search`">
          <button :id="`create_${$store.state.activePageTab == 3 ? 'Basket': $store.state.activePageTab == 5 ? 'GTT': 'Alerts'}`" @click="createAlertOrBasket()" v-if="($store.state.activePageTab == 3 || $store.state.activePageTab == 4 ||  $store.state.activePageTab == 5 ) && page == 'orders'" class="border-[#753ED7] violet-bg text-white px-3 ml-2 flex justify-center items-center
                        font-medium text-xs rounded hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none focus:ring-0 active:bg-indigo-800 h-[26px] w-18 dark:border-gray-500">
                        Create
                    </button>
         </div>
        </div>
        <a :id="'downloadcsv'" v-if="isDownload && !getIsMobileView">
          <button type="button" class="px-2 text-blue-600 hover:text-blue-700 font-semibold text-xs rounded  focus:outline-none hover:ring-0 transition duration-150 ease-in-out flex self-center content-center align-middle justify-center center item-center item-middle" @click="downloadAsCsv()">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                  class="w-3 mr-1 text-blue-600" role="img" width="20" height="20" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.36959 11.3215V3.33333C9.36959 2.8731 9.74268 2.5 10.2029 2.5C10.6632 2.5 11.0363 2.8731 11.0363 3.33333V11.3215L13.1492 9.20858C13.4746 8.88314 14.0022 8.88314 14.3277 9.20858C14.6531 9.53401 14.6531 10.0617 14.3277 10.3871L10.7922 13.9226C10.4667 14.248 9.93911 14.248 9.61367 13.9226L6.07809 10.3871C5.75265 10.0617 5.75264 9.53403 6.07807 9.20858C6.40351 8.88314 6.93115 8.88314 7.25659 9.20857L9.36959 11.3215ZM1.6665 11.6667C1.6665 11.2064 2.0396 10.8333 2.49984 10.8333C2.96007 10.8333 3.33317 11.2064 3.33317 11.6667C3.33317 12.4797 3.33359 14.4893 3.334 15C3.334 15.4596 3.70774 15.8333 4.16734 15.8333H15.834C16.2936 15.8333 16.6673 15.4596 16.6673 15V11.6667C16.6673 11.2064 17.0404 10.8333 17.5007 10.8333C17.9609 10.8333 18.334 11.2064 18.334 11.6667V15C18.334 16.3801 17.2141 17.5 15.834 17.5H4.16734C2.78727 17.5 1.66734 16.3801 1.66734 15.0007C1.66693 14.4901 1.6665 12.4802 1.6665 11.6667Z"
                  class=" fill-blue-600 hover:text-blue-700" />
              </svg>
              <span class="self-center text-[10px]">Download</span>
          </button>
        </a>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'tabsComponent',
  props: {
    data: { data: Array },
    isBgBlue: { type: Boolean, default: false },
    isMwLoader: { type: Boolean, default: true },
    page: { page: String }
  },

  methods: {
    changeActive(d) {
      if(this.getMwListLoader) return
      if (this.tablist) {
        this.tablist.forEach(el => {
          el.active = false;
        });
      }
      d.active = true;
      this.$emit('activeTab', d.id);
      // this.$route.path == '/sectorlist' ||  this.$route.path == '/screener' 
      if (this.$route.path == '/sectorlist' || this.$route.path == '/etflist' || this.$route.path == '/setting' || this.$route.path == '/indicestable') {
        this.$emit('activeTabName', d.name);
      }
    },
    getTabs() {
      if (this.$route.path == '/sectorlist' && this.page == 'sectors') {
        return this.getSectorsTabs
      } else if (this.$route.path == '/etflist' && this.page == 'etf') {
        return this.getEtfTabs
      } else if(this.$route.path == '/setting' && this.page == 'settings') {
        this.tablist = this.getSettingsTab
      } else if(this.$route.path == '/setting' && this.page == 'markets') {
        this.tablist = this.getMarketTabs
      } else {
        return this.tablist
      }
    },
    downloadAsCsv() {
      let pageName = ''
      if(this.page == 'orders') {
        let tabIndex = this.$store.state.activePageTab || 0
        pageName = tabIndex == 0 ? 'pending_orders' : tabIndex == 1 ? 'executed_orders' : 'tradebook'
        this.$common.checkDownload('file', pageName, this.tableData)
      } else {
        pageName = 'positions'
        this.$common.checkDownload('file', pageName, this.tableData)
      }
    },
    isDisable(tabName) {
      return this.$route.path == '/optionchain' && tabName == 'Option chain'
    },
    disableClass(tabName) {
      return this.$route.path == '/optionchain' && tabName == 'Option chain' ? 'cursor-disabled' : ''
    },
    createAlertOrBasket() {
            if (this.$store.state.activePageTab == '4') {
                this.$store.commit('alert/setCurrentAlertData', '')
                this.$store.commit('alert/setIsAlertDialog', { boolean: true, type: 'Create' })
            } else if(this.$store.state.activePageTab == '3') {
                this.$store.commit('setCreateBaskettDialog', true)
            } else if(this.$store.state.activePageTab == '5') {
              this.$store.commit('gtt/setGttInitialDialog', true)
            }
        },
  },
  computed: {
    ...mapGetters('pref', ['getSectorsTabs', 'getSettingsTab']),
    ...mapGetters('home', ['getEtfTabs']),
    ...mapGetters(['getLoader','getSearch','getIsMobileView']),
    ...mapGetters('watchlist', ['getMwListLoader', 'getMwTabs']),
    ...mapGetters('positions',['getPositionsData','getMcxData','getNseData','getNfoData']),
    ...mapGetters("order", ["getOpenOrders","getExcutedOrders","getTradeBook",]),
    ...mapGetters('alert', ['getMarketTabs', 'getAlerts']),
    ...mapGetters('basket', ['getBasketList']),
    ...mapGetters('gtt', ['getGTTList']),
    tablist() {
      if (this.$route.path == '/sectorlist' && this.page == 'sectors') {
        return this.getSectorsTabs
      } else if (this.$route.path == '/etflist' && this.page == 'etf') {
        return this.getEtfTabs
      } else if(this.$route.path == '/setting' && this.page == 'settings') {
        return this.getSettingsTab
      } else if(this.$route.path == '/setting' && this.page == 'markets') {
        return this.getMarketTabs
      } else {
        return this.data
      }
    },
    searchTerm: {
        get() {
            return this.getSearch
        },
        set(val) {
            this.$store.commit('setSearch', val)
        }
    },
    tableData() {
      let tabIndex = this.$store.state.activePageTab || 0
      let data = []
      if(this.page == 'positions') {
        if(tabIndex == 0) {
          data = this.getPositionsData
        } else if(tabIndex == 1) {
          data = this.getNfoData
        } else if(tabIndex == 2) {
          data = this.getMcxData
        } else {
          data = this.getNseData
        }
      } else {
        if(tabIndex == 0) {
          data = this.getOpenOrders
        } else if(tabIndex == 1) {
          data = this.getExcutedOrders
        } else if(tabIndex == 2) {
          data = this.getTradeBook
        } else if(tabIndex == 3) {
          data = this.getBasketList
        } else if(tabIndex == 4) {
          data = this.getAlerts
        } else if(tabIndex == 5) {
          data = this.getGTTList
        }
      }
      return data
    },
    isDownload() {
      if(this.page == 'positions'){
        return this.tableData?.length > 0
      }else{
        let tabIndex = this.$store.state.activePageTab || 0
        return tabIndex == 3 || tabIndex == 4 || tabIndex == 5 ? false : this.tableData?.length > 0
      }
    },
    isSearch() {
      return this.tableData?.length > 0
    }
  },
}
</script>