<template>
  <box class="h-full">
    <div style="height: 100%" ref="TVChartContainer" v-if="$store.state.chart.chartUrl">
      <iframe class="rounded" id="chartFrame" :src="$store.state.chart.chartUrl" frameborder="0" width="100%" height="100%"></iframe>
    </div>
  </box>
</template>

<script>
import { mapGetters } from "vuex";
import chartListener from './commonChart'
import chartBus from './chartBus'
var enableStreaming = false
var updatedResponseFeed = {}
var data = null
export default {
  mixins: [chartListener, chartBus],
  computed: {
    ...mapGetters("login", ["getAccessToken"]),
    ...mapGetters("chart",["getTradeChartData"])
  },
  methods: {
    wsChart(wsd, token){
      let chartId = document.getElementById('chartFrame')
     let tradeChart = this.getTradeChartData
     let exc = tradeChart.exchange.includes('::index') ? tradeChart.exchange.replaceAll('::index', '').toString().trim(): tradeChart.exchange
      if(`${exc}|${tradeChart.token}` == token){
      if(wsd && chartId && chartId.contentWindow ){
        let obj = {}
        obj[token] = wsd[`${exc}|${tradeChart.token}`]
       let message = {
        id: "stream",
        message:   obj
      };
      chartId.contentWindow?.postMessage(message, "*")
    }
      }
    }
  },
  mounted() {
    window.chart = {
      callback: this.wsChart
    }
    let PopoutData = localStorage.getItem("iframeUrl") && localStorage.getItem("iframeUrl") != "undefined" ? JSON.parse(localStorage.getItem("iframeUrl")) : []
    this.$store.commit('chart/setPopOutIframeData',PopoutData)
    let getLocal = JSON.parse(localStorage.getItem("chartData"));
    if (getLocal) {
      this.$store.commit('chart/setChartData', getLocal)
      this.refresh(getLocal);
    }
  },
};
</script>