<template>
  <div class="flex flex-col background-img h-screen overflow-auto">
    <div class="flex justify-center align-middle items-center h-[100vh]">
      <div class="">
        <img :src="clientLogo" alt="logo" class="max-w-[80px] h-auto" />
        <!-- <div class="loadingComponent pr-5 pb-8">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div> -->
        <div class="ml-9 mt-3"><div class="loaderComponent"></div></div>
        
      </div>
    </div>
  </div>
</template>

<script>
import clientLogo from "../assets/img/client-logo.svg";

export default {
  name: "loader",
  data() {
    return {
      clientLogo,
    };
  },
};
</script>
<style>
.loaderComponent {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
            0%  {
                box-shadow: 
                    20px 0 #753ED7, 
                    -20px 0 #c2ace9,
                    40px 0 #c2ace9,
                    -40px 0 #c2ace9;
                background: #753ED7;
            }
            20% {
                box-shadow: 
                    20px 0 #753ED7, 
                    -20px 0 #c2ace9,
                    40px 0 #c2ace9,
                    -40px 0 #c2ace9;
                background: #c2ace9;
            }
            40% {
                box-shadow: 
                    20px 0 #c2ace9, 
                    -20px 0 #c2ace9, 
                    40px 0 #c2ace9,
                    -40px 0 #c2ace9;
                background: #c2ace9;
            }
            60% {
                box-shadow: 
                    20px 0 #c2ace9, 
                    -20px 0 #c2ace9, 
                    40px 0 #753ED7, 
                    -40px 0 #c2ace9;
                background: #c2ace9;
            }
            80% {
                box-shadow: 
                    20px 0 #c2ace9, 
                    -20px 0 #c2ace9, 
                    40px 0 #c2ace9, 
                    -40px 0 #753ED7;
                background: #c2ace9;
            }
            100% {
                box-shadow: 
                    20px 0 #c2ace9, 
                    -20px 0 #c2ace9, 
                    40px 0 #c2ace9, 
                    -40px 0 #753ED7;
                background: #753ED7;
            }
        }
.wrapperComponent {
  width: 100px;
  height: 10px;
  position: relative;
  z-index: 1;
}

.loadingComponent {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
}

.loadingComponent span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loadingComponent span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loadingComponent span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loadingComponent span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loadingComponent span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}
</style>