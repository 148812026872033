import store from '../_store/index'
import router from '../router/index'
import chartBus from '../views/home/chart/chartBus.js'
import formula from '../mixins/formula'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

function getDropDownHeight(td) {
    let table_td = document.getElementById(td)
    let diff = window?.innerHeight - table_td?.getBoundingClientRect()?.top
    return (diff - 72) > 72 ? false : true
}

function getPcodeClass(t) {
    let cn = ''
    switch (t) {
        case 'MIS':
            cn = 'orange-btn-c'
            break;
        case 'CNC':
            cn = 'purple-btn-c'
            break;
        case 'NRML':
            cn = 'pink-btn-c'
            break;
        default:
            cn = 'gray-btn-c'
            break;
    }
    return cn += ' ' + 'lg:min-w-[100px] min-h-[24px] tracking-[0.4px]';
}

function getPcode(t) {
    let pc = ''
    if (t.orderType != 'Cover' && t.orderType != 'Bracket') {
        switch (t.product) {
            case 'MIS':
                pc = 'INTRADAY'
                break;
            case 'CNC':
                pc = 'HOLDINGS'
                break;
            case 'NRML':
                pc = 'POSITIONAL'
                break;
            case 'Cover':
                pc = 'COVER'
                break;
            case 'Bracket':
                pc = 'BRACKET'
                break;
        }
    } else {
        pc = (t.orderType).toUpperCase()
    }
    return pc;
}

function closeOrderWindow() {
    store.commit('ow/setIsVisible', false)
    this.$modal.hide("buy-sell-popup");
}

function ruppesFormat(num) {
    var n1, n2;
    num = num + "" || "";
    // works for integer and floating as well
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0]?.toString()?.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
}

function getTypeTdClass(type) {
    let cn = '';
    if (type == 'B') {
        cn = 'blue-btn-c'
    } else {
        cn = 'red-btn-c'
    }
    return cn += ` tracking-[0.4px] text-[10px]`
}

function formatNum(value) {
    return !!value && value != 'NA' ? value?.toString()?.replace(/,/g, "") : value
}

function validateAndFormatValue(data, key, parseFloat) {
    if (data) {
        if (data[key]?.toString()) {
            let value = Number(data[key])
            if (isNaN(value)) {
                return 'NA'
            } else {
                return ruppesFormat(value.toFixed(parseFloat))
            }
        } else {
            return 'NA'
        }
    } else {
        return '0.00'
    }
}

function getFormatedInsName(item) {
    if (item.exchange != 'NSE' && item.exchange != 'BSE') {
        let sp = item.formattedInsName?.split(' ')
        if (sp && sp[1]) {
            let name = sp[1]?.match(/[a-zA-Z]+|[0-9]+/g)
            if (/[0-9]/.test(sp[1]) && /[a-zA-Z]/.test(name[1]) && item?.weekTag && item.weekTag != 'null' && item.weekTag.includes('W')) {
                return `
          <span class="grid">
          <span>${sp[0]} ${name[0]}<sup class="inline-flex leading-[2]">${name[1]}</sup> ${sp[2] ? sp[2] : ''} ${sp[3] ? sp[3] : ''} ${sp[4] ? sp[4] : ''}</span>
          <div class="mt-1 w-full text-[11px] secondary-color flex items-center">${name[0]} ${sp[2]} WEEKLY <span class=" bg-blue-100 text-[8px] text-[#0060b9] dark:bg-[#1D242E] dark:text-[#4987EE] h-[20px] w-[20px] rounded-full flex items-center justify-center px-[1px] mx-1">${item?.weekTag}</span></div>
          </span>`
            } else {
                return `<span>${item.formattedInsName}</span>`
            }
        } else {
            return `<span>${item.formattedInsName}</span>`
        }
    } else if (item.segment != 'INDEX') {
        return `<span>${item.formattedInsName}<div class="mt-1 text-[11px] secondary-color">${item?.companyName ? item?.companyName : ''}</div></span>`
    } else {
        return `<span>${item.formattedInsName}</span>`
    }

    function reverseString(value) {
        return value?.split("")?.reverse()?.join("")
    }
}


function checkDownload(fileName, page, value) {
    const modifyData = []
    if (page == 'pending_orders' || page == 'executed_orders' || page == 'tradebook') {
        var tableHead;
        page == 'executed_orders' ? tableHead = {
            Time: '',
            Type: '',
            Instrument: '',
            Product: '',
            Qty: '',
            Price: '',
            Status: ''
        } : page == 'tradebook' ? tableHead = {
            Time: '',
            Type: '',
            Instrument: '',
            Product: '',
            Qty: '',
            Price: '',
        } : tableHead = {
            Time: '',
            Type: '',
            Instrument: '',
            Product: '',
            Qty: '',
            LTP: '',
            Price: '',
            Status: ''
        }

        for (let item of value) {
            var price
            if (page !== 'tradebook') {
                var tempPrice = item.exch == "CDS" || item.exch == "BCD" ? parseFloat(item.price).toFixed(4) : parseFloat(item.price).toFixed(2)
                var TriggerPrice = item.exch == "CDS" || item.exch == "BCD" ? parseFloat(item.triggerPrice).toFixed(4) : parseFloat(item.triggerPrice).toFixed(2)
                price = TriggerPrice > 0 ? tempPrice + " / " + TriggerPrice + ' trg' : tempPrice
            }
            page == 'executed_orders' ? tableHead = {
                UserId: store.state.login.currentUserId,
                Time: item.orderTime.split(" ")[0],
                Type: item.transType == "B" ? "BUY" : "SELL",
                Instrument: item.formattedInsName == null ? item.tradingSymbol : item.formattedInsName,
                Exchange: item.exchange,
                Product: getPcode(item),
                OrderType: item.priceType,
                Qty: `${item["qty"]}`,
                Price: price,
                Status: item.orderStatus
            } : page == 'tradebook' ? tableHead = {
                UserId: store.state.login.currentUserId,
                Time: item.orderTime.split(" ")[0],
                Type: item.transType == "B" ? "BUY" : "SELL",
                Instrument: item.formattedInsName == null ? item.tradingSymbol : item.formattedInsName,
                Exchange: item.exchange,
                Product: getPcode(item),
                OrderType: item.priceType,
                Qty: `${item["qty"]}`,
                Price: item.exchange == "CDS" || item.exchange == "BCD" ? parseFloat(item.fillprc).toFixed(4) : parseFloat(item.fillprc).toFixed(2),
                Status: "COMPLETE"
            } : tableHead = {
                UserId: store.state.login.currentUserId,
                Time: item.orderTime.split(" ")[0],
                Type: item.transType == "B" ? "BUY" : "SELL",
                Instrument: item.formattedInsName == null ? item.Trsym : item.formattedInsName,
                Exchange: item.exch,
                Product: getPcode(item),
                OrderType: item.priceType,
                Qty: `${item["Qty"]}`,
                LTP: item.ltp && (item.exchange == "CDS" || item.exchange == "BCD") ? parseFloat(item.ltp).toFixed(4) : item.ltp && item.exchange !== "CDS" && item.exchange !== "BCD" ? parseFloat(item.ltp).toFixed(2) : '',
                Price: price,
                Status: item.orderStatus
            }

            // tableHead.Exchange_Order_Id = item.ExchOrdID ? item.ExchOrdID.toString() : 'NA'
            tableHead.Nest_Order_No = item.orderNo ? item.orderNo?.toString() : 'NA'
            modifyData.push(tableHead)
        }
    }
    else if (page.includes('Holdings')) {
        for (let item of value) {
            var tableHead = {
                'UserId': store.state.login.currentUserId,
                'Instrument': item.tradingSymbol,
                "Qty.": item.netQty,
                "Buy Avg": item.buyPrice,
                'LTP': item.ltp,
                "Buy Value": (item.buyPrice * item.netQty),
                "P&L": item.netPnl,
                "Net chg.": item.netChg,
                "Day chg.": item.dayChg
            }
            modifyData.push(tableHead)
        }
    } else if (page.includes('positions')) {
        for (let item of value) {
            var tableHead = {
                'UserId': store.state.login.UCC,
                'Type': getPcode(item),
                "Instrument": item.displayName,
                "Exchange": item.exchange,
                "Qty": item.netQty,
                'Buy Avg': item.exchange == "CDS" || item.exchange == "BCD"
                    ? parseFloat(item.buyPrice).toFixed(4)
                    : parseFloat(item.buyPrice).toFixed(2),
                "Sell Avg": item.exchange == "CDS" || item.exchange == "BCD"
                    ? parseFloat(item.sellPrice).toFixed(4)
                    : parseFloat(item.sellPrice).toFixed(2),
                "LTP": item.exchange == "CDS" || item.exchange == "BCD"
                    ? parseFloat(item.ltp).toFixed(4)
                    : parseFloat(item.ltp).toFixed(2),
                "P&L": item.pnl?.toString()?.replace(/,/g, ""),
                "MTM": item.MtoM?.toString()?.replace(/,/g, ""),
            }
            modifyData.push(tableHead)
        }
        var footer = {
            'UserId': '',
            'Type': '',
            'Instrument': '',
            'Exchange': '',
            'Qty': '',
            'Buy Avg': '',
            'Sell Avg': '',
            'LTP': 'Total',
            'P&L': totalGroup(value)?.toString()?.replace(/,/g, ""),
            'MTM': getTotalDaysPnl(value)?.toString()?.replace(/,/g, "")
        }
        modifyData.push(footer)
    }

    // get keys as array
    if (modifyData.length != 0) {
        const keys = Object.keys(modifyData[0]);
        const commaSeparatedString = [keys.join(","), modifyData.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")
        const csvBlob = new Blob([commaSeparatedString])
        let a2 = null
        // if(page == 'Trade Book') {
        //     a2 = document.getElementById("downloadcsv1")
        // } else {
        //     a2 = document.getElementById("downloadcsv")
        // }
        a2 = document.getElementById("downloadcsv")
        var tempFileName = page?.toString()?.replace(/ +/g, "") + '.csv'
        a2.download = tempFileName
        if (!tempFileName.includes('.csv')) {
            tempFileName = tempFileName + '.csv'
        }
        a2.href = URL.createObjectURL(csvBlob)
    }
}

// sum of value with array object
function totalGroup(group) {
    let total = [];
    if (group) {
        group.forEach((val) => {
            total.push(val.pnl?.toString()?.replace(/,/g, ''));
        });
        return ruppesFormat(
            parseFloat(total.reduce(function (total, num) { return parseFloat(total) + parseFloat(num) }, 0)).toFixed(2)
        );
    }
}

function getTotalDaysPnl(group) {
    let total = [];
    if (group) {
        group.forEach((val) => {
            total.push(val.MtoM?.toString().replace(/,/g, ''));
        });
        return ruppesFormat(
            parseFloat(total.reduce(function (total, num) { return parseFloat(total) + parseFloat(num) }, 0)).toFixed(2)
        );
    }
}


function getRealisedPnl(group) {
    let total = [];
    if (group) {
        group.forEach((val) => {
            total.push(formula.realisedProfitLoss(val, 'pnl'));
        });
        return ruppesFormat(parseFloat(total.reduce(function (total, num) {
            return parseFloat(total) + parseFloat(num)
        }, 0)).toFixed(2));
    }
}

function getUnRealisedPnl(group) {
    let total = [];
    if (group) {
        group.forEach((val) => {
            total.push(formula.unRealisedProfitLoss(val, 'pnl'));
        });
        return ruppesFormat(
            parseFloat(total.reduce(function (total, num) { return parseFloat(total) + parseFloat(num) }, 0)).toFixed(2)
        );
    }
}

function toFixed(ltp) {
    ltp = parseFloat(ltp)
    if (store.state.ow?.currentExchange == 'CDS') {
        ltp = ltp.toFixed(4)
    } else {
        ltp = ltp.toFixed(2)
    }
    return ltp;
}

function focusQty() {
    let qty = document.getElementById('qty')
    if (qty) {
        qty.focus()
        qty.select();
        // qty.setSelectionRange(0, 99999); // For mobile devices
    }
}

function getLotSize(lot, qty, exch) {
    qty = !qty ? 0 : exch == 'MCX' ? (qty / lot) : qty
    return qty
}

function capitalize(data) {
    let str = data;
    let firstLetter = str?.charAt(0)?.toUpperCase();
    let restOfStr = str?.slice(1)?.toLowerCase();
    if (firstLetter && restOfStr) {
        let capitalizedStr = firstLetter + restOfStr;
        return capitalizedStr
    } else {
        return ''
    }
}

function getFormatedDate(date) {
    let splitedDate = date?.split('T')
    return formatDate(splitedDate[0])
}

function formatDate(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
}

function removeComma(value) {
    let v = value?.toString().replace(/,/g, ",")
    return parseFloat(v);
}

async function addToMw(item) {
    let isValidToAdd = false
    let exch = item.exchange
    let token = item.token
    let acTab = store.state.watchlist.tab
    let currentTabScrips = store.state.watchlist.watchlistData
    let allWatchlistData = store.state.watchlist.allWatchListData

    if (allWatchlistData && allWatchlistData.length > 0) {

        if (currentTabScrips.length == 0) {
            isValidToAdd = true
        } else {
            for (let i = 0; i < currentTabScrips?.length; i++) {
                if (currentTabScrips[i]?.token == token) {
                    isValidToAdd = false
                    break;
                } else {
                    isValidToAdd = true
                }
            }
        }
        notify({ group: "auth", clean: true });
        if (!isValidToAdd) {
            notify({ group: 'auth', type: 'warn', text: `Scrip already available in current market watch` })
        } else if (currentTabScrips?.length > (store.state.watchlistMaxCount - 1)) {
            notify({ group: 'auth', type: 'warn', text: `Can't add more than 30 scrips. Use other tab` })
        } else if (isValidToAdd) {
            let payload = [{
                "exch": exch,
                "token": token
            }]
            await store.dispatch('watchlist/addSearchList', payload, { root: true })
            notify({ group: 'auth', type: 'success', text: `Scrip added in market watch current tab` })
        }
    }

}

function preventSpace(event) {
    if (event.code == "Space") {
        event.preventDefault();
    }
}

async function resetOwForm() {
    store.commit('ow/setPriceModel', '')
    store.commit('ow/setTrigPriceModel', '')
    store.commit('ow/setQtyModel', '')
    store.commit('ow/setDiscQtyModel', 0)
    store.commit('ow/setStopLossPrice', 0)
    store.commit('ow/setTargetPrice', 0)
}

function numDifferentiation(val) {
    if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' Lac';
    }
    return val ? val : 0;
}

function calcCeGval(oi) {
    let mf = 0
    let gval = 0
    let currentOi = parseFloat(oi)
    mf = 80 / (store.state.oc.bco / store.state.oc.sco)
    gval = (currentOi / store.state.oc.sco) * mf
    return gval;
}

function calcPeGval(oi) {
    let mf = 0
    let gval = 0
    let currentOi = parseFloat(oi)
    mf = 80 / (store.state.oc.bpo / store.state.oc.spo)
    gval = (currentOi / store.state.oc.spo) * mf
    return gval;
}


function gvalCalcset() {
    store.state.oc.sco = 0
    store.state.oc.bco = 0
    store.state.oc.spo = 0
    store.state.oc.bpo = 0

    store.state.oc.ocd?.forEach(el => {
        var coi = Number(el.CE?.oi)
        store.state.oc.sco += coi
        if (store.state.oc.bco < coi) {
            store.state.oc.bco = coi;
        }

        var poi = Number(el.PE?.oi)
        store.state.oc.spo += poi
        if (store.state.oc.bpo < poi) {
            store.state.oc.bpo = poi;
        }
    })

    store.state.oc.ocd?.forEach(el => {
        el.CE.ceGval = calcCeGval(Number(el.CE?.oi))
        el.PE.peGval = calcPeGval(Number(el.PE?.oi))
    })
}

/***** No Use Method ******/
function getBarVal(data, type, qty) {
    let activeTab = store.state.watchlist.tab
    let ctData = store.state.mkWatch.objectWatchList[activeTab]
    ctData = ctData[data.wsToken]

    if (type == 'bid' && ctData) {
        let biggestOfBidQty = Math.max(Number(ctData['BQty1']), Number(ctData['BQty2']), Number(ctData['BQty3']), Number(ctData['BQty4']), Number(ctData['BQty5']))
        let sumOfBidQty = Number(ctData['BQty1']) + Number(ctData['BQty2']) + Number(ctData['BQty3']) + Number(ctData['BQty4']) + Number(ctData['BQty5'])
        let mf = 0
        let barVal = 0
        let cqty = parseFloat(qty)
        mf = 100 / (biggestOfBidQty / sumOfBidQty)
        barVal = (cqty / sumOfBidQty) * mf
        return barVal;
    }
    if (type == 'ask' && ctData) {
        let biggestOfAskQty = Math.max(Number(ctData['SQty1']), Number(ctData['SQty2']), Number(ctData['SQty3']), Number(ctData['SQty4']), Number(ctData['SQty5']))
        let sumOfAskQty = Number(ctData['SQty1']) + Number(ctData['SQty2']) + Number(ctData['SQty3']) + Number(ctData['SQty4']) + Number(ctData['SQty5'])
        let mf = 0
        let barVal = 0
        let cqty = parseFloat(qty)
        mf = 100 / (biggestOfAskQty / sumOfAskQty)
        barVal = (cqty / sumOfAskQty) * mf
        return barVal;
    }
}

function formatDateAmpm(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year}, ${hours}:${minutes} ${ampm}`;
}

function callChart(data) {
    chartBus.methods.checkMethod(data)
}

function formatDateIPO(from, to) {
    const [day, month, year] = from?.split('-');
    const [to_day, to_month, to_year] = to?.split('-');
    const date = new Date(`${year}-${month}-${day}`);
    const to_date = new Date(`${to_year}-${to_month}-${to_day}`);

    // Get day and month names
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Format the date
    const formattedDate = `${this.addOrdinalSuffix(day)} ${months[parseInt(month, 10) - 1] != months[parseInt(to_month, 10) - 1] ? months[parseInt(month, 10) - 1] : ''} - ${this.addOrdinalSuffix(to_day)} ${months[parseInt(to_month, 10) - 1]} `;

    return formattedDate;
}

function addOrdinalSuffix(n) {
    const j = n % 10;
    const k = n % 100;

    if (j === 1 && k !== 11) {
        return `${n}<sup>st</sup>`;
    }
    if (j === 2 && k !== 12) {
        return `${n}<sup>nd</sup>`;
    }
    if (j === 3 && k !== 13) {
        return `${n}<sup>rd</sup>`;
    }

    return `${n}<sup>th</sup>`;
}


function getDocumentTitle(val) {
    let title = ''
    let path = val && val.path ? val.path : ''
    if (val && path) {
        if (path == '/holdings') {
            title = 'Holdings'
        }else if (path == '/ipolist') {
            title = 'IPO'
        } else if (path == '/dashboard') {
            title = 'Dashboard'
        } else if (path == '/optionchain') {
            title = 'Option Chain'
        } else if (path == '/funds') {
            title = 'Funds'
        } else if (path == '/marketWatch') {
            title = 'Marketwatch'
        } else if (path == '/chart') {
            title = 'Chart'
        }else if(store.state.activePageTab == 0){
            title = path == '/order' ? 'Pending Orders' : path == '/position' ? 'Positions' : path == '/settings' ? 'Account Info' :  path == '/sectorlist' ? 'IT Industry' : path == '/indicestable' ? 'Indian Indices' : path == '/screener' ? 'Top Gainers' : path == '/etflist' ? 'Index Based' : ''
        }else if(store.state.activePageTab == 1){
            title = path == '/order' ? 'Executed Orders' : path == '/position' ? 'F&O' : path == '/settings' ? 'Trade Settings' :  path == '/sectorlist' ? 'Automobile' : path == '/indicestable' ? 'Global Indices' : path == '/screener' ? 'Top Losers' : path == '/etflist' ? 'Equity' : ''
        }else if(store.state.activePageTab == 2){
            title =  path == '/order' ? 'Tradebook' : path == '/position' ? 'MCX' : path == '/settings' ? 'Security Settings' :  path == '/sectorlist' ? 'Telecom' : path == '/etflist' ? 'Sector' : ''
        }else if(store.state.activePageTab == 3){
            title =  path == '/order' ? 'Basket' : path == '/settings' ? 'Markets' : path == '/etflist' ? 'Gold' :  path == '/sectorlist' ? 'Pharma' : ''
        }else if(store.state.activePageTab == 4){
            title =  path == '/order' ? 'Alerts' : path == '/sectorlist' ? 'Healthcare' : path == '/etflist' ? 'Debt' : ''
        }else if(store.state.activePageTab == 5){
            title =  path == '/etflist' ? 'Global' : path == '/sectorlist' ? 'Banking' : path == '/order' ? 'GTT' : ''
        }else if(store.state.activePageTab == 6){
            title =  path == '/sectorlist' ? 'Energy' : path == '/order' ? 'SIP' : ''
        }else if(store.state.activePageTab == 7){
            title =  path == '/sectorlist' ? 'FMCG' : ''
        }else if(store.state.activePageTab == 8){
            title =  path == '/sectorlist' ? 'Financials' : ''
        }
    }
    return document.title = val.path != '/' && title ? `${title} / Pocket` : 'Pocket - Fast and nimble trading platform with Rs 0 brokerage for 30 days.'
}

function navigateToCosmos() {
    let parmas = { appcode: 'oJGBKnveAXcHOWG' }
    store.commit('login/setSSOQuery', parmas)
    store.dispatch('login/getVendorDetails', parmas)
}

function navigateToOptionbees(tag) {
    // BETA URL
    // let params = { appcode: 'tEcUhnubTaxTRRa', tag: tag}
    // LIVE URL
    let params = { appcode: 'QWlotbrHaZKJJHD', tag: tag }
    store.commit('login/setSSOQuery', params)
    store.dispatch('login/getVendorDetails', params)
}

function convertIntoRupees(value) {
    if (!value) return 0
    value = value || 0;
    if (value >= 10000000) {
        return (value / 10000000).toFixed(2) + " C";
    } else if (value >= 100000) {
        return (value / 100000).toFixed(2) + " L";
    } else if (value >= 1000) {
        return (value / 1000).toFixed(2) + " K";
    } else {
        return ruppesFormat(value);
    }
}

function getGttPrice(ltp, percentage, tickSize) {
    let result = (percentage / 100) * Number(ltp);
    result = Math.round(result / Number(tickSize)) * Number(tickSize);
    result = Number(ltp) + Number(result)
    result = (result).toFixed(2);
    return result
}

function getGttPercentage(ltp, result, tickSize) {
    let diff = Number(result) - Number(ltp);
    let percentage = (diff / Number(ltp)) * 100;
    percentage = Math.round(percentage / Number(tickSize)) * Number(tickSize);
    return percentage.toFixed(2);
}

function commonDateFormate(time){
    if(time){
        let appliedDate = time.split('T')[0] ? time.split('T')[0]?.split('-')?.reverse()?.join('-') : ''
        let appliedTime = time.split('T')[1] ? time.split('T')[1]?.split('.')[0] : ''
        return `${appliedDate} ${appliedTime}`
    }    
}

const common = {
    getDropDownHeight: getDropDownHeight,
    getPcodeClass: getPcodeClass,
    getPcode: getPcode,
    closeOrderWindow: closeOrderWindow,
    ruppesFormat: ruppesFormat,
    formatNum: formatNum,
    validateAndFormatValue: validateAndFormatValue,
    getFormatedInsName: getFormatedInsName,
    checkDownload: checkDownload,
    totalGroup: totalGroup,
    getTotalDaysPnl: getTotalDaysPnl,
    getRealisedPnl: getRealisedPnl,
    getUnRealisedPnl: getUnRealisedPnl,
    toFixed: toFixed,
    focusQty: focusQty,
    getLotSize: getLotSize,
    capitalize: capitalize,
    getFormatedDate: getFormatedDate,
    formatDate: formatDate,
    removeComma: removeComma,
    addToMw: addToMw,
    preventSpace: preventSpace,
    resetOwForm: resetOwForm,
    numDifferentiation: numDifferentiation,
    calcCeGval: calcCeGval,
    calcPeGval: calcPeGval,
    gvalCalcset: gvalCalcset,
    getBarVal: getBarVal,
    formatDateAmpm: formatDateAmpm,
    callChart: callChart,
    getTypeTdClass: getTypeTdClass,
    formatDateIPO: formatDateIPO,
    addOrdinalSuffix: addOrdinalSuffix,
    getDocumentTitle: getDocumentTitle,
    navigateToCosmos: navigateToCosmos,
    convertIntoRupees: convertIntoRupees,
    getGttPrice: getGttPrice,
    getGttPercentage: getGttPercentage,
    commonDateFormate: commonDateFormate,
    navigateToOptionbees: navigateToOptionbees
}

export default common
