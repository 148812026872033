<template>
    <div v-if="!getIsMobileView">
        <div v-if="getAlerts.length != 0 && !getLoader">
        <div  class="flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative ring-black ring-opacity-5 overflow-y-auto" >
                        <table class="min-w-full table-fixed divide-y divide-gray-300 dark:divide-[#232325] rounded-b border-[#ededed] dark:border-[#232325] table-common">
                            <thead class="border-b-1 dark:border-[#9c9cb1]">
                                <tr>
                                    <th id="alert_th_created_on" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Created On</th>
                                    <th id="alert_th_name" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Name</th>
                                    <th id="alert_th_symbol" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Symbol</th>
                                    <th id="alert_th_alert_type" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Alert Type</th>
                                    <th id="alert_th_status" scope="col" class="p-3 text-center text-xs font-semibold secondary-color">Status</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="(alert, idx) in this.getSearch == '' ? getAlerts : filterData" :key="idx" @mouseover="currentIndex = idx"
                                    @mouseleave="currentIndex = -1;" class="border-b border-[#ededed] dark:border-[#232325] h-[56px]">
                                    <td :id="`alert_td_created_on_${idx}`" class="p-3 text-left text-sm relative primary-color">{{ $common.formatDateAmpm(new Date(alert?.createdOn)) }}</td>
                                    <td :id="`alert_td_name_${idx}`" :name="alert?.alertName" class="p-3 text-left text-sm relative primary-color">{{ alert?.alertName }}</td>
                                    <td :id="`alert_td_symbol_${idx}`" class="p-3 text-left text-sm primary-color">
                                        <span class="mb-2">{{ alert.scripName }}</span>
                                    </td>
                                    <td class="text-left p-3 text-sm relative primary-color">
                                        <span :id="`alert_td_alert_type_${idx}`">{{ alert?.alertType }} {{ alert?.operator }} than {{ alert.exch == 'CDS' || alert.exch == 'BCD' ? (+alert?.value).toFixed(4) : (+alert?.value).toFixed(2) }}</span>
                                        <span class="absolute right-0" v-if="currentIndex == idx">
                                            <button :id="`alert_td_modify_${idx}`" @click="modifyAlert(alert, 'modify')"
                                                class="px-3 py-1 text-xs text-white mr-2 green-bg rounded">Modify</button>
                                            <button :id="`alert_td_cancel_${idx}`" @click="modifyAlert(alert, 'cancel')"
                                                class="px-3 py-1 text-xs text-white mr-2 red-bg rounded">Cancel</button>
                                        </span>
                                    </td>
                                    <td class="text-center p-3 text-sm primary-color">
                                        <button :id="`alert_td_status_${idx}`" :class="alert?.triggerStatus == null || alert?.triggerStatus == '0' ? 'gray-btn-c' : 'green-btn-c'" class="px-3 text-[10px] rounded min-w-[100px] cursor-default tracking-[0.4px] min-h-[24px]">{{ alert?.triggerStatus == null || alert?.triggerStatus == '0' ? 'Open' : 'Triggered' }}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div v-else-if="!getLoader" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
            <nodata type="Alert"/>
        </div>
    </div>
    <mobileView v-else-if="getAlerts.length" :listData="getAlerts" @call_action="mobileActions"/>
    <div v-else-if="!getLoader" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
        <nodata type="Alert"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import table_head_section from '../../../component/table-head-section.vue'
import nodata from '../../no-data.vue'
import mobileView from "./mobile-view.vue"
export default{
    data() {
        return {
            currentIndex: -1,
        }
    },
    computed: {
        ...mapGetters('alert', ['getAlerts', 'getIsAlertDialog']),
        ...mapGetters(['getSearch','getLoader','getIsMobileView']),
        alertsData() {
            return this.getAlerts
        },
        filterData() {
            return this.getAlerts.filter((post) => {
                return (
                    post.scripName?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.createdOn?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.alertName?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.alertType?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.operator?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    String(post.value)?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) ||
                    post.triggerStatus?.toLowerCase()?.includes(this.getSearch?.toLowerCase())
                );
            });
        },
    },
    components: { table_head_section, nodata, mobileView },
    methods: {
        modifyAlert(data, type) {
            this.$store.commit('alert/setCurrentAlertData', data)
            if (type == 'modify') {
                this.$store.commit('alert/setIsAlertDialog', { boolean: true, type: 'Modify' })
            } else {
                this.$store.commit('setCancelAlertDialog', true)
            }
        },
        mobileActions(data) {
            let item = data.data.item
            if(data.action == 'Modify') {
                this.modifyAlert(item, 'modify')
            } else if(data.action == 'Cancel') {
                this.modifyAlert(item, 'cancel')
            } 
        }
    },
}
</script>

<style>
.alert-btn {
    @apply px-3 py-2 text-[12px] rounded cursor-default min-w-[100px] font-medium
}
.alert-btn.enabled {
    @apply bg-lime-50 text-lime-500
}
.alert-btn.disabled {
    @apply bg-orange-50 text-orange-500
}
</style>