
<template>
  <div :class="{ 'w-full' : $store.state.windowWidth < 900, 'w-[400px]' : $store.state.windowWidth > 900, 'mw-calc-height-2' : getIsTickerTape == '1', 'mw-calc-height-1' : getIsTickerTape == '0'}" class="my-3 bg-white dark:bg-[#181818] dark:border-[#232325] flex-none border border-gray-300 rounded mw-watch relative mw-calc-height" v-click-outside="onClickOutside">
    <Tabs page="mkw" :isBgBlue="true" :isMwLoader="false" class="m-0" :data="getMwTabs" @activeTab="activeTabObj" />
    <LinearLoader v-if="getMwListLoader"/>
    <minioc v-if="selectList == 'Option Chain'"/>
    <watchlist v-else @changeTab="changeTabEvent"/>
  </div>

</template>
<script lang="ts" setup>
import { onMounted, computed, defineAsyncComponent } from "vue"
import { useStore } from 'vuex'

const minioc = defineAsyncComponent(()=> import('../home/mini-optionchain.vue'))

const Tabs = defineAsyncComponent(()=> import('../../components/tabs.vue'))

const watchlist = defineAsyncComponent(()=> import('./index.vue'))

const LinearLoader = defineAsyncComponent(() => import("./linearLoader.vue"))

const store = useStore();


/**********
 * Final Code
 * **********/ 
const getMwListLoader = computed(() => store.getters['watchlist/getMwListLoader'])
const getMwTabs = computed(() => store.getters['watchlist/getMwTabs'])
const tabValue = ['My Watchlist', 'Predefined Watchlist','Option Chain']
const selectList = computed(() => store.getters['pref/getMarketwatchType'])
const getIsTickerTape = computed(() => store.getters['pref/getIsTickerTape'])

const userRole = computed(() => store.getters['login/userRole'])

async function activeTabObj(tab:any) {
    await changeTabName(tabValue[tab],'')
}
async function changeTabEvent(val: any){
  await changeTabName(val,'')
}


async function changeTabName(key:any, stage:any) {
  let setKey = tabValue.indexOf(key)
  store.commit('pref/setIsMarketwatch', Number(setKey) != -1 ? setKey : 0)

  if (userRole['value'] != 'GUEST_USER' && stage != 'initial') {
    let reqVal = key == 'My Watchlist' ? '0' : key == 'Predefined Watchlist' ? '1' : key == 'Option Chain' ? '2' : 'My Watchlist'
    store.dispatch('pref/updateUserPref', { keyVariable: 'mwo', value: reqVal })
  }

  store.commit('watchlist/setMwTabs', key)

  if (key == 'My Watchlist' || key == 'Predefined Watchlist') {
    if((window as any).hasOwnProperty('changeList') && typeof (window as any).changeList.callback === 'function'){
            (window as any).changeList.callback()
  }
  }
}
function onClickOutside(){
  if((window as any).hasOwnProperty('outsideClick') && typeof (window as any).outsideClick.callback === 'function'){
            (window as any).outsideClick.callback()
  }
}
onMounted(async() => { 
  let nameTab = localStorage.getItem('activeMwNameTab') && JSON.parse(localStorage.getItem('activeMwNameTab')!) != 'undefined' ? JSON.parse(localStorage.getItem('activeMwNameTab')!) : 0
  store.commit('pref/setIsMarketwatch', nameTab)
})

</script>
<style>
.mw-calc-height-2 {
  height: calc(100vh - 98px);
}

.mw-calc-height-1 {
  height: calc(100vh - 72px);
}
</style>