<template>
    <ol class="flex flex-col border-t border-[#ededed] dark:border-[#252323] px-2">
        <li v-for="(item, idx) in listData" :key="idx" class="p-2 w-full flex flex-col gap-2 border-b border-[#ededed] dark:border-[#252323]" @click="toggleBottomSheet(item, idx)">
            <section class="flex justify-between primary-color">
                <span>{{ item.alertName }}</span>
                <span class="text-right text-sm dark:text-[#94A3B8]">{{ item.scripName }}</span>
            </section>
            <section class="flex items-center justify-between text-xs">
                <span class="primary-color">
                    {{ item.alertType }} {{ item.operator }} {{ item.exch == 'CDS' || item.exch == 'BCD' ? (+item.value).toFixed(4) : (+item.value).toFixed(2) }}
                </span>
                <span>
                    <button :class="item?.triggerStatus == null || item?.triggerStatus == '0' ? 'gray-btn-c' : 'green-btn-c'" class="px-3 text-[10px] rounded cursor-default tracking-[0.4px] min-h-[24px]">{{ item?.triggerStatus == null || item?.triggerStatus == '0' ? 'Open' : 'Triggered' }}</button>
                </span>
            </section>
            <section class="flex justify-between text-xs">
                <span class="text-right text-sm secondary-color">{{ $common.formatDateAmpm(new Date(item?.createdOn)) }}</span>
            </section>
        </li>
    </ol>
    <BottomSheet page="holdings" v-if="isOpenBottomSheet" :isOpen="isOpenBottomSheet" @close="closeBottomSheet"
        :options="sheetOptions" :sheetData="sheetData" @call_action="callAction" />
</template>

<script setup lang="ts">
import { PropType, ref, defineAsyncComponent } from "vue"

const BottomSheet = defineAsyncComponent(()=> import("../../../component/bottom-sheet.vue"))
defineProps({
    listData: {
        type: Array as PropType<any[]>,
        required: true
    }
})
const isOpenBottomSheet = ref(false)
const sheetData = ref()
const toggleBottomSheet = async (data: any, index: Number) => {
    await setBottomSheetOptions()
    sheetData.value = { data, index }
    isOpenBottomSheet.value = !isOpenBottomSheet.value
}

const sheetOptions = ref()
const setBottomSheetOptions = async () => {
    sheetOptions.value = ["Modify", "Cancel"]
}
const closeBottomSheet = () => {
    isOpenBottomSheet.value = false
}

const emits = defineEmits(['call_action'])

const callAction = (data:any) => {
    emits('call_action', data)
}
</script>