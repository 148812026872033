<template>
  <div ref="draggableContainer" :view="name" :width="wwidth" :style="{ top: position.top + 'px', left: position.left + 'px',width: wwidth + 'px' }" :class="{ 'animationOw' : name == 'ow', 'bottom-2 right-4 absolute' : isFixed, 'draggableClass' : !isFixed , 'w-[97%] left-2 top-[20%]' : name == 'basket_dialog' && getIsMobileView, '!left-0 !top-0 max-w-[624px] overflow-y-auto' : getIsMobileView, '!w-[94%] m-2' : lessThan600PX }">
    <slot name="promt"></slot>
    <div id="draggable-header" @mousedown="startDrag">
      <slot name="header"></slot>
    </div>
    <slot name="main"></slot>
    <slot name="footer"></slot>
  </div>
</template>
<script >
import { mapGetters } from 'vuex'
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'DraggableDiv',
  setup() {
  const isDragging = ref(false)
  const position = ref({
        top: 100,
        left: 100
      })
  const offset = ref({
        x: 0,
        y: 0
      })
  const boundary = ref({
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      })
    return {
      isDragging,position,offset,boundary
    };
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    wwidth: { type:String },
    name: { type: String }
  },

  computed:{
    ...mapGetters(['getIsSmallView','getIsMobileView','getWindowWidth']),
    lessThan600PX() {
      return this.getWindowWidth < 600
    }
  },

  mounted() {
    this.setBoundary();
    window.addEventListener('resize', this.setBoundary);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBoundary);
  },
  methods: {
    setBoundary() {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      this.boundary = {
        top: 0,
        left: 0,
        right: windowWidth,
        bottom: windowHeight
      };
    },
    startDrag(event) {
      this.isDragging = true;
      this.offset.x = event.clientX - this.position.left;
      this.offset.y = event.clientY - this.position.top;

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.stopDrag);
    },
    onMouseMove(event) {
      if (this.isDragging) {
        const newLeft = event.clientX - this.offset.x;
        const newTop = event.clientY - this.offset.y;

        // Ensure the draggable element stays within the window boundaries
        this.position.left = Math.max(this.boundary.left, Math.min(newLeft, this.boundary.right - this.$el.offsetWidth));
        this.position.top = Math.max(this.boundary.top, Math.min(newTop, this.boundary.bottom - this.$el.offsetHeight));
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.stopDrag);
    }
  }
});
</script>

<style>
.draggableClass {
  left: 30%;
  top: 25%;
  position: absolute;
  z-index: 999;
  height: auto !important;
  max-width: 634px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @apply border border-gray-300 rounded-lg
}

#draggable-header {
  z-index: 10;
  cursor: move !important;
}
.centerShow{
  top: 25vh;
  left: 40%;
}
@keyframes modal-slide {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>