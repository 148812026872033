import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
const state = {
   sipList:[]
}

const actions = {
    
    // get GTT Orders
    async getSIPOrders({ state, commit, dispatch, rootGetters }, payload) {
        commit('setLoader', true, { root: true })
        commit('setSIPList', [])
        commonService.getSIPOrders().then((resp)=>{
            if (resp.status == 200 && resp.data.status == "Ok") {
                commit('setSIPList', resp.data?.result)
            }
        },(error) =>{
            errHandle.statusCodeCheck(error)
        }).finally(()=>{
            commit('setLoader', false, { root: true })
        })
    },
    
};

const mutations = {
    setSIPList(state, payload){
        state.sipList = payload
    }
};

const getters = {
    getSIPList: (state)=>state.sipList
};

const sip = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default sip