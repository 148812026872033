<template>
    <div>
        <div class="space-y-6 w-full min-h-[250px] flex flex-col justify-center items-center">
            <div class="mx-auto space-y-4">
                <p class="font-medium text-base text-center primary-color">Login with QR Code</p>
                <div class="">
                    <div class="flex flex-col justify-center items-center">
                          <div class="h-[180px] w-[180px] flex justify-center items-center relative" >
                              <img v-if="!getIsQrLoader" :src="getQrDetails?.byteCode" alt="qrcode" class="max-w-full h-auto rounded" :class="{ 'opacity-[0.2]' : getCounter == 3}"/>
                              <div v-else class="secondaryColor">
                                  Loading...
                              </div>
                              <button v-if="getCounter == 3" class="absolute top-[35%] z-10 primary-color h-10 w-10 rounded-full border bg-[#16A34A]" @click="getQrCode()">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" aria-hidden="true" focusable="false" class="text-white p-2"><defs></defs><g stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"><path d="M14.8 5.525S15.866 5 12 5a7 7 0 107 7"></path><path d="M12 2l3.5 3L12 8.5"></path></g></svg>
                              </button>
                          </div>
                        </div>
                    <!-- <img src="../../assets/img/qr.svg" alt="#QR" class=""> -->
                    <!-- <div class="pt-2">
                        <div v-if="countDown > 0" class="text-xs secondary-color flex justify-end secondaryColor">
                            00 : {{ countDown && countDown < 10 ? `0${countDown}` : countDown }}
                        </div>
                        <div v-if="countDown == 0" class="text-xs flex justify-end secondaryColor">
                            <span @click="resendOtp()"
                                class="hyperLink hover:text-blue-500 ml-1 cursor-pointer opacity-70">Resend OTP</span>
                        </div>
                    </div> -->
                </div>

            </div>
            <div class="w-full flex justify-center text-xs">
                <p class="secondary-color w-[250px] text-center">Scan this with the <span class="hyperLink hover:text-blue-500 opacity-70"> Pocket mobile app </span>to login instantly</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default{
    data(){
        return{
            countDown: 30,
        }
    },
    computed: {
        ...mapGetters('login', ["getQrDetails", "getInterval", "getCounter", "getIsQrLoader"])
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                   this.countTimer = setTimeout(() => {
                   this.countDown -= 1;
                   this.countDownTimer();
               }, 1000);
            }
        },
        resendOtp(type) {
            this.countDown = 30;
            this.countDownTimer();
        },
        async getQrCode() {
            this.$store.commit('login/setCounter', { type : 'set', count: 0})
            await this.$store.dispatch("login/getQrCode")
            if(this.getQrDetails.qrName) {
                this.$store.dispatch("login/getQrSession")
            }
        },
    },
    created(){
    },
    mounted(){
        this.countDownTimer()
        this.getQrCode()
    },
    unmounted(){
        clearInterval(this.$store.state.login.sessionInterval)
        clearInterval(this.$store.state.login.interval)
    }

}
</script>