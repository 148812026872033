import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import common from '../../../mixins/common';

function findNearest(arr, num) {
    let nearest = null;
    let minDiff = Infinity;

    for (let i = 0; i < arr.length; i++) {
        const diff = Math.abs(Number(num) - Number(arr[i].strikeprice));

        if (diff < minDiff) {
            minDiff = diff;
            nearest = Number(arr[i].strikeprice);
        }
    }

    return nearest;
}

const state = {
    ocd: '',
    ocdFull: '',
    underlyingList: [],
    underlyingExpiryList: [],
    interval: 10,
    instrument: '',
    expiry: '',
    nearestStrike: '',
    sco: 0,
    bco: 0,
    spo: 0,
    bpo: 0,
    spotPosition: 'top',
    isCallFromOut: false,
    insExp: '',

    // 
    isOcLoader: false,
    active_OC_exch: 'nse_fo',
    exchangeList: [
        { name: "NFO", key: "nse_fo" },
        { name: "BFO", key: "bse_fo" },
        { name: "CDS", key: "cde_fo" },
        { name: "MCX", key: "mcx_fo" },
    ], 
    // 
    childActive: '',
}

const actions = {
    async getOptionChain({ state, commit, dispatch, rootGetters }, payload) {
            // commit('watchlist/setMwLoader', true, { root: true } )
            commit('setIsOcLoader', true)
            commit('setChildActive', state.insExp)
            let json = {
                underlying: state.insExp.ins,
                expiry: state.insExp.exp,
                interval: state.interval,
                exch: state.insExp.exch,
            };
            await commonService.getOptionChain(json).then(async(resp) => {
                if (resp.data.status == "Ok" && resp.status == 200 && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                    let spotLTP = resp.data.result[0].spotLTP ? resp.data.result[0].spotLTP : 0
                    commit('setOCD', resp.data.result[0].data)
                    dispatch('subsOptionChain', resp.data.result[0])
                    commit('setOCDFull', resp.data.result[0])
                    // commit('setNearestStrike', spotLTP)
                    
                } else {
                    commit('setOCD', [])
                    commit('setOCDFull', [])
                }
            }, (error) => {
                errHandle.statusCodeCheck(error)
            }).finally(() => {
                commit('watchlist/setMwLoader', false, { root: true } )
                commit('setIsOcLoader', false)
                if ( window.hasOwnProperty("opSymbolChange") && typeof window.opSymbolChange.callback === "function") {
                    window.opSymbolChange.callback('', 'scroll');
                }
            })
    },

    async getUnderlying({ state, commit, dispatch, rootGetters }, payload) {
        let underlingList = []
        let json = {
            exch: state.insExp.exch
        }
        if (payload.type != 'full') {
            commit('watchlist/setMwLoader', true, { root: true })
        }
        await commonService.getUnderlying(json).then(async (resp) => {
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                await commit('setUnderlyingList', resp.data.result[0].list_underlying)
                underlingList = resp.data.result[0].list_underlying
                let tempInsExp = state.insExp
                tempInsExp.ins && underlingList && underlingList.includes(tempInsExp.ins) ? '' : tempInsExp.ins = underlingList[0]
                tempInsExp.where = "getUnderline"
                await commit('setInsExp', tempInsExp)
                await commit('setChildActive', tempInsExp)
            } else {
                commit('setUnderlyingList', [])
            }
        }, (error) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit('watchlist/setMwLoader', false, { root: true })
            if (state.underlyingList && state.underlyingList.length > 0) {
                dispatch('getUnderlyingExpiry')
            }
        })
    },

    async getUnderlyingExpiry({ state, commit, dispatch, rootGetters }, payload) {
        let underlineExpiryList = []
        let json = {
            underlying: state.insExp.ins,
            exch: state.insExp.exch,
        };
        await commonService.getUnderlyingExpiry(json).then(async (resp) => {
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data.result && resp.data.result.length > 0) {
                commit('setUnderlyingExpiryList', resp.data.result[0].underlying_expiry)
                underlineExpiryList = resp.data.result[0].underlying_expiry
                let tempInsExp = state.insExp
                tempInsExp.exp && underlineExpiryList && underlineExpiryList.includes(tempInsExp.exp) ? '' : tempInsExp.exp = underlineExpiryList[0]
                tempInsExp.where = "getUnderlineExpiry"
                await commit('setInsExp', tempInsExp)
                await commit('setChildActive', tempInsExp)
            } else {
                commit('setUnderlyingExpiryList', [])
            }
        }, (error) => {
            errHandle.statusCodeCheck(error)
        }).finally(() => {
            commit('watchlist/setMwLoader', false, { root: true })
            if (state.underlyingExpiryList && state.underlyingExpiryList.length > 0) {
                dispatch('getOptionChain')
            }
        })
    },

    async subsOptionChain({ state, rootGetters, dispatch }, payload) {
        let activeDetails = state.exchangeList.filter((el) => {
            return el.key == state.insExp.exch
        })
        if (rootGetters['ws/getConnectionStatus'] && activeDetails.length > 0) {
            let spotExchange = activeDetails[0].name == 'NFO' ? 'NSE' : activeDetails[0].name == 'BFO' ? 'BSE' : activeDetails[0].name
            let channelExch = activeDetails[0].name
            let channel = ''
            if(payload) {
                if(payload.spotToken) {
                    channel = `${spotExchange}|${payload.spotToken}#`
                }
                localStorage.setItem('ocTokens' , JSON.stringify({spotToken : `${spotExchange}|${payload.spotToken}`}))
                if(payload?.data?.length) {
                    await payload?.data.forEach(el => {
                        channel += `${channelExch}|${el.CE['token']}#${channelExch}|${el.PE['token']}#`
                    });
                }
            }
            var tempData = {
                subscribe: channel,
                where: 'optionchain',
                data:[]
            }
            
            dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },
};

const mutations = {
    setOCD(state, payload) {
        state.ocd = payload
        common.gvalCalcset()
    },
    setOCDFull(state, payload) {
        state.ocdFull = payload
        let tickers = window.tickers
        let tokenList = JSON.parse(localStorage.getItem('ocTokens'))
        if(tickers && tokenList?.spotToken) {
            state.ocdFull.spotLTP = tickers[tokenList.spotToken]?.lp ? tickers[tokenList.spotToken]?.lp : tickers[tokenList.spotToken]?.c
            this.commit("oc/setNearestStrike", state.ocdFull.spotLTP);
        }
    },
    setUnderlyingList(state, payload) {
        state.underlyingList = payload
    },
    setUnderlyingExpiryList(state, payload) {
        state.underlyingExpiryList = payload
    },
    setInstrument(state, payload) {
        state.instrument = payload
    },
    setExpiry(state, payload) {
        state.expiry = payload
    },
    setNearestStrike(state, spotLTP) {
        state.nearestStrike = findNearest(state.ocd, spotLTP)
        state.spotPosition = state.nearestStrike > state.ocdFull.spotLTP ? "top" : "bottom"
    },
    setInsExp(state, payload) {
        state.insExp = payload
        localStorage.setItem('ocInsExData', JSON.stringify(payload))
    },
    setIsOcLoader(state, payload) {
        state.isOcLoader = payload
    },
    setActive_OC_exch(state, payload){
        state.active_OC_exch = payload
    },
    setExchList(state, payload){
        state.exchangeList = payload
    },
    reset_state(state) {
        Object.assign(state,  {
            ocd: '',
            ocdFull: '',
            underlyingList: [],
            underlyingExpiryList: [],
            interval: 10,
            instrument: '',
            expiry: '',
            nearestStrike: '',
            sco: 0,
            bco: 0,
            spo: 0,
            bpo: 0,
            spotPosition: 'top',
            isCallFromOut: false,
            insExp: '',
            isOcLoader: false
        });
    },

    resetOc(state) {
        state.sco = 0
        state.bco = 0
        state.spo = 0
        state.bpo = 0
    },

    setChildActive(state, payload) {
        let exchkeyArr = state.exchangeList.filter((el) => {
            return el.key == payload.exch
        })
        let exKey = ''
        if (exchkeyArr && exchkeyArr.length > 0) {
            exKey = exchkeyArr[0].name
        }
        state.childActive = exKey ? { ...payload, exch: exKey } : payload
    },
};

const getters = {
    OCD: state => state.ocd,
    OCDFull: state => state.ocdFull,
    nearestStrike: state => state.nearestStrike,
    underlyingList: state => state.underlyingList,
    expiryList: state => state.underlyingExpiryList,
    instrument: state => state.instrument,
    expiry: state => state.expiry,
    sco: state => state.sco,
    bco: state => state.bco,
    spo: state => state.spo,
    bpo: state => state.bpo,
    spotPosition: state => state.spotPosition,
    isCallFromOut: state => state.isCallFromOut,
    insExp: state => state.insExp,
    getIsOcLoader: state => state.isOcLoader,
    getExchangeList: state => state.exchangeList
};

const optionChain = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default optionChain