<template>
    <ol class="flex flex-col border-t border-[#ededed] dark:border-[#252323]">
        <li v-for="(item, idx) in listData" :key="idx"
            class="p-2 w-full flex flex-col gap-2 border-b border-[#ededed] dark:border-[#252323]"
            @click="toggleBottomSheet(item, idx)">
            <section class="flex justify-between">
                <span class="text-sm primary-color">{{ item.formattedInsName }}</span>
                <span class="text-right text-sm dark:text-[#94A3B8]">
                    <span v-if="item.orderStatus != 'COMPLETE'">
                        {{ item.exchange == "CDS" || item.exchange == "BCD"
                            ? parseFloat(item?.price).toFixed(4)
                            : parseFloat(item?.price).toFixed(2)
                        }}
                    </span>

                    <span v-else>
                        {{ item.exchange == "CDS" || item.exchange == "BCD"
                            ? parseFloat(item?.avgTradePrice).toFixed(4)
                            : parseFloat(item?.avgTradePrice).toFixed(2)
                        }}
                    </span>

                    <span v-if="parseFloat(item?.triggerPrice) > 0 && item?.priceType != 'L'">&nbsp;/&nbsp;{{
                        item.exchange == "CDS" || item.exchange == "BCD"
                            ? parseFloat(item?.triggerPrice).toFixed(4)
                            : parseFloat(item?.triggerPrice).toFixed(2)
                    }} trg.</span>
                </span>
            </section>
            <section class="flex justify-between text-xs">
                <span class="primary-color">
                    <button :class="$common.getTypeTdClass(item.transType)" class="px-3 rounded min-h-[24px]">{{  item.transType == 'B' ? 'BUY' : 'SELL' }}</button>
                    <span class="pl-1">{{ ` ${item["fillShares"] ? getFillShares(item) : '0'} / ${getLot(item)} `}}</span>
                </span>
                <span>
                    <button :class="getClass(item.orderStatus)"
                        v-tippy="{ content: item.orderStatus.includes('REJECTED') ? item?.rejectedReason : '', classes:'tooltipCusClass' }"
                        class="px-2 lg:px-3 min-h-[24px] text-[10px] rounded lg:min-w-[100px] cursor-default">{{
                        getStatusNames(item.orderStatus) }}</button>
                </span>
            </section>
            <section class="flex justify-between text-xs">
                <span class="flex gap-1 secondary-color">
                    <span>{{ item?.exchange }}</span>
                    <span>{{ item?.priceType == 'L' ? 'LIMIT' : item?.priceType }}</span>
                    <span>{{ $common.getPcode(item) }}</span>
                </span>
                <span class="secondary-color">
                    {{ item.orderTime?.split(' ')[0] }}
                </span>
            </section>
        </li>
    </ol>
    <BottomSheet page="orders" v-if="isOpenBottomSheet" :isOpen="isOpenBottomSheet" @close="closeBottomSheet"
        :options="sheetOptions" :sheetData="sheetData" @call_action="callAction" />
</template>

<script setup lang="ts">
import { PropType, ref, defineAsyncComponent } from "vue"

const BottomSheet = defineAsyncComponent(()=> import("../../component/bottom-sheet.vue"))
const props = defineProps({
    listData: {
        type: Array as PropType<any[]>,
        required: true
    },
    isOpen: {
        type:Boolean,
    }
})
const { isOpen } = props
const isOpenBottomSheet = ref(false)
const sheetData = ref()
const toggleBottomSheet = async (data: any, index: Number) => {
    await setBottomSheetOptions()
    sheetData.value = { data, index }
    isOpenBottomSheet.value = !isOpenBottomSheet.value
}

const sheetOptions = ref()
const setBottomSheetOptions = async () => {
    sheetOptions.value = []
    if(isOpen) {
        sheetOptions.value.push('Modify', 'Cancel')
    } else {
        sheetOptions.value.push('Repeat')
    }
    sheetOptions.value.push('Info', 'Chart')
}
const closeBottomSheet = () => {
    isOpenBottomSheet.value = false
}

const emits = defineEmits(['call_action'])

const callAction = (data:any) => {
    emits('call_action', data)
}
const getStatusNames = (s:any) => {
    let n = ''
    switch (s) {
        case 'open':
            n = s
            break;
        case 'after market order req received':
            n = 'AMO'
            break;
        default:
            n = s
            break;
    }
    return n?.toUpperCase();
}

const getClass = (status: any) => {
    let cn = '';
    let stat = status?.toLowerCase()
    if (stat == 'open') {
        cn = 'orange-btn-c'
    } else if (stat == 'after market order req received') {
        cn = 'gray-btn-c'
    } else if (stat == 'rejected') {
        cn = 'red-btn-c'
    } else if (stat == 'complete') {
        cn = 'green-btn-c'
    } else {
        cn = 'gray-btn-c'
    }
    return cn += ` tracking-[0.4px] min-h-[22px]`
}
const getFillShares = (item: any) => {
    let fillShares = item.exchange == 'MCX' ? item.fillShares / item.lotSize : item.fillShares
    return fillShares
}
const getLot = (item: any) => {
    let qty = item.exchange == 'MCX' ? item.qty / item.lotSize : item.qty
    return qty
}
</script>