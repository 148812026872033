<template>
    <div v-if="!getIsMobileView">
        <div v-if="getDataCount.length != 0">
        <div class="flex flex-col">
            <div class="table-od" :class="showDropDown ? 'overflowinherit' : 'overflow-y-auto'">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative ring-black ring-opacity-5 " :class="showDropDown ? 'overflowinherit' : 'overflow-y-auto'">
                        <!-- border-t -->
                        <table id="order_table" class="min-w-full table-fixed rounded-b border-b border-[#ededed] dark:border-[#232325] order-table" >
                            <thead class="border-b dark:border-[#232325]">
                                <tr>
                                    <th @mouseover="isHover = true;currentIdx = idx" @mouseleave="isHover = false;currentIdx = -1" @click="changeSort(head.value)" scope="col" v-for="(head, idx) of isOpen ? tableHeadOpen : tableHeadExecuted" :key="idx" :class="[head.class,{ 'bg-slate-50 dark:bg-zinc-800 cursor-pointer' :isHover && currentIdx == idx && head.sortable}]" >
                                        <span v-if="head.text == 'checkBox' && isOpen">
                                            <input :id="`order_th_cb`" type="checkbox" class="-mt-2 h-4 w-4 dark:border-gray-400 border-gray-300 accent-[#753ED7] cursor-pointer  sm:left-6" v-model="checkBoxSelectAll" />
                                        </span>
                                        <span v-else>
                                            <span :class="head.alignment">
                                                <span class="whitespace-nowrap" :id="`order_th_${head?.text}`">{{ head?.text }}</span>
                                                <span v-if="head.sortable" class="min-w-[12px] mx-1">
                                                    <span v-if="isHover && currentIdx == idx">
                                                        <span v-if="head.isdesc">
                                                            <img :src="getIsTheme == 1 ? upArrowDark : upArrow" alt="upArrow">
                                                        </span>
                                                        <span v-if="!head.isdesc">
                                                            <img :src="getIsTheme == 1 ? downArrowDark : downArrow" alt="downArrow">
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody :class="getTableData().length != 0 ? '' : ''">
                                <tr :id="`executed_${idx}`" v-for="(item, idx) in getTableData()" :key="idx" @mouseover="currentIndex = idx"
                                    @mouseleave="currentIndex = -1; showDropDown = false" class="border-b border-[#ededed] dark:border-[#232325]">
                                    <td v-if="isOpen == true" class="w-12 px-6 sm:w-16 sm:px-8" :id="`order_td_cb_${idx}`">
                                        <input :id="`order_td_cb_${idx}`" type="checkbox" class="-mt-2 h-4 w-4 rounded border-gray-300 accent-[#753ED7] cursor-pointer  sm:left-6" v-model="selectedCheckbox" :value="item" />
                                    </td>
                                    <td  class="w-[80px] py-4 px-2 text-sm" :id="`order_td_${idx}_time`">
                                        {{ item.orderTime?.split(' ')[0] }}
                                    </td>
                                    <td class="whitespace-nowrap text-center px-3 py-4" :id="`order_td_${idx}_type`">
                                        <button :class="$common.getTypeTdClass(item.transType)" class="px-3 rounded min-h-[24px]">{{  item.transType == 'B' ? 'BUY' : 'SELL' }}</button>
                                    </td>
                                    <td class="px-2 py-4 text-sm relative min-w-[200px]"
                                        :id="`scripTd_${idx}`">
                                        <span :id="`order_td_${idx}_name`">{{ item?.formattedInsName || item?.tradingSymbol }} </span>
                                        <span :id="`order_td_${idx}_exch`" class="px-2 secondary-color text-[10px]">{{ item?.exchange }}</span>
                                        <span v-if="currentIndex == idx" class="absolute mx-4 cursor-pointer right-0 flex top-[25%]">
                                            
                                            <button :id="`order_td_${idx}_modify_btn`" @click="MshowModal(item,item.transType == 'B' ? 0 : 1,'modify')" v-if="isOpen"
                                                class="px-3 py-1 text-xs text-white mr-2 bg-green-600 rounded">Modify</button>
                                            <button :id="`order_td_${idx}_cancel_btn`" @click="singleCancel(item)" v-if="isOpen"
                                                class="px-3 py-1 text-xs text-white mr-2 bg-red-600 border border-red-600 rounded">{{ !item.snoOrderNumber ? 'Cancel' : 'Exit'}}</button>
                                            <button :id="`order_td_${idx}_repeat_btn`" @click="MshowModal(item,item.transType == 'B' ? 0 : 1,'repeat')" v-if="!isOpen"
                                                class="px-3 py-1 text-xs text-white mr-2 bg-[#f75723] rounded">Repeat</button>

                                            <div class="relative flex text-left">
                                                <button :id="`order_td_${idx}_more_btn`" type="button" class="inline-flex w-full items-center justify-center px-3 py-[3px] text-xs text-blue-500 border border-blue-500 rounded text-center bg-white dark:bg-[#181818]"  aria-expanded="true" aria-haspopup="true"
                                                    @click="showDropDown = !showDropDown;"> More
                                                </button>
                                                <transition v-if="showDropDown"
                                                    enter-active-class="transition ease-out duration-100"
                                                    enter-from-class="transform opacity-0 scale-95"
                                                    enter-to-class="transform opacity-100 scale-100"
                                                    leave-active-class="transition ease-in duration-75"
                                                    leave-from-class="transform opacity-100 scale-100"
                                                    leave-to-class="transform opacity-0 scale-95">
                                                    <div class="absolute right-0 z-10 mt-7 origin-top-right rounded-md w-[158px] bg-white shadow-lg dark:bg-[#1F1F1F] dark:text-[#bbb]"
                                                        role="menu" aria-orientation="vertical"
                                                        tabindex="-1" id="menu_Item"
                                                        :class="$common.getDropDownHeight(`scripTd_${idx}`) ? 'bottom-[25px]' : ''">
                                                        <div class="py-1" role="none">
                                                            <div class="flex items-center px-2 hover:bg-gray-50 hover:dark:bg-[#763ed71c]" @click="getOrderInfo(item)">
                                                                <div>
                                                                    <img :src="getIsTheme == 1 ? infoSvgDark : infoSvg" class="h-[16px] w-[16px] primary-color" alt="info">
                                                                </div>
                                                                <div>
                                                                    <a :id="`order_td_${idx}_info_btn`" class="primary-color dark:text-[#bbb] block px-2 py-2 text-xs" role="menuitem" tabindex="-1">Info</a>
                                                                </div>
                                                            </div>
                                                            <div class="flex items-center px-2 hover:bg-gray-50 hover:dark:bg-[#763ed71c]" @click="$common.callChart(item) ">
                                                                <div>
                                                                    <img :src="getIsTheme == 1 ? chartsvgDark : chartSvg" class="h-[16px] w-[16px] primary-color" alt="chart">
                                                                </div>
                                                                <div>
                                                                    <a :id="`order_td_${idx}_chart_btn`" class="primary-color dark:text-[#bbb] block px-2 py-2 text-xs" role="menuitem" tabindex="-1">Chart</a>
                                                                </div>
                                                            </div>
                                                            <div class="px-2 hover:bg-gray-50 hover:dark:bg-[#763ed71c]" @click="$common.addToMw(item,'orders')"  v-if="$store.state.pref.marketwatchType == 'My Watchlist'">
                                                                <div class="flex">
                                                                    <img :src="getIsTheme == 1 ? bookDark : book" alt="MW" class="[h-16px] w-[16px] object-scale-down">
                                                                    <span>
                                                                        <a :id="`order_td_addToMw_${idx}`" class="whitespace-nowrap primary-color dark:text-[#bbb] block px-[10px] py-2 text-xs" role="menuitem" tabindex="-1"
                                                                        >Add to Watchlist</a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                        </span>
                                    </td>
                                    <td class=" text-left px-2 py-4 text-sm whitespace-nowrap" :id="`order_td_${idx}_pricetype`">
                                        {{ $common.getPcode(item) }} / {{ item?.priceType == 'L' ? 'LIMIT' : item?.priceType }} 
                                    </td>
                                    <td class=" text-right px-2 py-4 text-sm" :id="`order_td_${idx}_qty`">
                                        {{ ` ${item["fillShares"] ? getFillShares(item) : '0'} / ${getLot(item)} `}}
                                    </td>
                                    <td class="text-right px-2 py-4 text-sm font-semibold" v-if="isOpen" :id="`order_td_${idx}_ltp`">
                                        {{ item?.ltp || '0.00' }}
                                    </td>
                                    <td class="text-right px-2 py-4 text-sm font-semibold" :id="`order_td_${idx}_price`">
                                        <span v-if="item.orderStatus != 'COMPLETE'">
                                            {{ item.exchange == "CDS" || item.exchange == "BCD"
                                         ? parseFloat(item?.price).toFixed(4)
                                         : parseFloat(item?.price).toFixed(2)
                                        }}
                                        </span>

                                        <span v-else>
                                            {{ item.exchange == "CDS" || item.exchange == "BCD"
                                                ? parseFloat(item?.avgTradePrice).toFixed(4)
                                                : parseFloat(item?.avgTradePrice).toFixed(2)
                                            }}
                                        </span>
                                        
                                        <span v-if="parseFloat(item?.triggerPrice) > 0 && item?.priceType != 'L'">&nbsp;/&nbsp;{{
                                         item.exchange == "CDS" || item.exchange == "BCD"
                                           ? parseFloat(item?.triggerPrice).toFixed(4)
                                           : parseFloat(item?.triggerPrice).toFixed(2)
                                        }} trg.</span>
                                    </td>
                                    <td class="whitespace-nowrap text-center px-2 py-4 text-sm" :id="`order_td_${idx}_status`">
                                        <button :class="getClass(item.orderStatus)" v-tippy="{ content: item.orderStatus.includes('REJECTED') ? item?.rejectedReason : '', classes:'tooltipCusClass' }"
                                            class="px-3 min-h-[24px] text-[10px] rounded min-w-[100px] cursor-default">{{ getStatusNames(item.orderStatus) }}</button>
                                    </td>
                                </tr>
                                <tr class="text-center border-[0px]" v-if="getOpenOrders.length == 0 && isOpen">
                                    <td class="px-6 py-4" colspan="10">No Orders Found</td>
                                </tr>
                                <tr class="text-center" v-if="getExcutedOrders.length == 0 && !isOpen">
                                    <td class="px-6 py-4" colspan="10">No Executed Orders Found</td>
                                </tr>
                            </tbody>
                            
                            <tfoot class="divide-y divide-gray-200 bg-white dark:bg-[#181818] dark:divide-gray-500" v-if="selectedCheckbox.length != 0">
                                <tr>
                                    <td colspan="9">
                                        <button id="order_cancel_btn" @click="$store.commit('setCancelOrderDialog', true);$store.commit('setDraggableCallFrom', 'centerShow')"
                                            type="button" class="inline-flex items-center rounded-md border border-transparent violet-bg px-3 py-2 text-sm font-medium leading-4
                                             text-white hover:bg-indigo-700 mx-5 my-3">Cancel {{ selectedCheckbox.length > 1 ? 'Orders' : 'Order' }}
                                            <span class="ml-1" v-if="ordersData.length > 1">({{selectedCheckbox.length}})</span></button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div v-else class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
            <nodata :type="isOpen ? `Pending orders` : `Executed orders`"/>
        </div>
    </div>
    <mobileView v-else-if="getDataCount.length" :isOpen="isOpen" :listData="isOpen ? getOpenOrders : getExcutedOrders" @call_action="mobileActions"/>
    <div v-else class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
        <nodata :type="isOpen ? `Pending orders` : `Executed orders`"/>
    </div>
    <info-modal v-if="getIsOrderInfoDialog" :infoData="infoData"/>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import table_head_section from '../../component/table-head-section.vue'
import nodata from '../no-data.vue'
import infoModal from './info-model.vue'
import TradeBook from './trade-book.vue';
import mobileView from "./mobile-view.vue"
// images
import upArrow from '@/assets/img/upArrow.svg'
import upArrowDark from '@/assets/img/upArrowDark.svg'
import downArrow from '@/assets/img/downArrow.svg'
import downArrowDark from '@/assets/img/downArrowDark.svg'
import infoSvg from "@/assets/img/infoLogo.svg"
import infoSvgDark from '@/assets/img/infoLogoDark.svg'
import chartSvg from "@/assets/img/chartSvg.svg"
import chartsvgDark from '@/assets/img/chartSvgDark.svg'
import book from '@/assets/img/dropdown/bookmark.svg'
import bookDark from '@/assets/img/dropdown/bookmarkDark.svg'
export default {
    name:'orders',
    data() {
        return {
            selectedCheckbox: [],
            currentIndex: -1,
            infoData: '',
            tableHeadOpen: [
                { alignment: 'flex justify-center items-center', text: "checkBox", sortable: false, value: "Pcode", class: "w-12 px-2 sm:w-16", show : this.isOpen ? true : false},
                { alignment: 'flex justify-start items-center', text: "Time",  sortable: true, value: "orderTime", class: "w-12 py-3.5 px-3 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-center items-center', text: "Type",  value: "displayName", sortable: true, class: "px-3 py-3.5 text-center text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-start items-center', text: "Instrument",  value: "formattedInsName", sortable: true, class: "px-3 py-3.5 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-start items-center', text: "Product", value: 'product', sortable: true, class: "px-3 py-3.5 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Qty.", value: "qty", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "LTP", value: "ltp", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show : this.isOpen ? true : false },
                { alignment: 'flex flex-row-reverse items-center', text: "Price", value: "price", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-center items-center', text: "Status", value: "orderStatus", sortable: true, class: "px-3 py-3.5 text-center text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
            ],
            tableHeadExecuted: [
                { alignment: 'flex justify-start items-center', text: "Time",  sortable: true, value: "orderTime", class: "w-12 py-3.5 px-3 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-center items-center', text: "Type",  value: "displayName", sortable: true, class: "px-3 py-3.5 text-center text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-start items-center', text: "Instrument",  value: "formattedInsName", sortable: true, class: "px-3 py-3.5 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-start items-center', text: "Product", value: 'product', sortable: true, class: "px-3 py-3.5 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Qty.", value: "qty", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Price", value: "price", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-center items-center', text: "Status", value: "orderStatus", sortable: true, class: "px-3 py-3.5 text-center text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
            ],
            pagination: {
                sortBy: null,
                descending: false
            },
            upArrow,
            upArrowDark,
            downArrow,
            downArrowDark,
            isHover:false,
            currentIdx: -1,
            infoSvg,
            infoSvgDark,
            chartSvg,
            chartsvgDark,
            book,
            bookDark,
            showDropDown: false
        }
    },
    components: { table_head_section, nodata, infoModal, TradeBook, mobileView},
    props: {
        data: { type: Array },
        header: { type: Array, },
        name: { type: String },
        full: { type: Object },
        isOpen: Boolean,
    },
    computed: {
        ...mapGetters(['getSearch','getIsOrderInfoDialog','getIsMobileView']),
        ...mapState("order", ["ordersData","getOpenOrders","getExcutedOrders","getTradeBook"]),
        ...mapGetters("order", ["getOpenOrders","getExcutedOrders","getObjectOrdersList"]),
        ...mapGetters('ws', ['getCToken']),
        ...mapGetters('pref',['getIsTheme']),
        checkBoxSelectAll: {
            get: function () {
                this.$store.commit('setCancelOrders', this.selectedCheckbox)
                if(this.getOpenOrders.length == 0) return false
                return this.getOpenOrders ? this.selectedCheckbox.length == this.getOpenOrders.length : false
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.getOpenOrders.forEach(function (item) {
                        selected.push(item);
                    });
                }
                this.selectedCheckbox = selected;
            },
        },

        filterData() {
            let data = this.isOpen ? this.getOpenOrders : this.getExcutedOrders
            return data.filter((post) => {
                return (
                    post.formattedInsName?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) ||
                    post.orderTime?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.transType?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.priceType?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.product?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.qty?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.price?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.orderStatus?.toLowerCase()?.includes(this.getSearch?.toLowerCase())
                );
            });
        },

        getDataCount() {
            let data = this.isOpen ? this.getOpenOrders : this.getExcutedOrders
            return data
        }
    },

    methods: {
        getClass(status) {
            let cn = '';
            let stat = status?.toLowerCase()
            if (stat == 'open') {
                cn = 'orange-btn-c'
            } else if (stat == 'after market order req received') {
                cn = 'gray-btn-c'
            } else if (stat == 'rejected') {
                cn = 'red-btn-c'
            } else if(stat == 'complete') {
                cn = 'green-btn-c'
            }else {
                cn = 'gray-btn-c'
            }
            return cn += ` tracking-[0.4px] min-h-[22px]`
        },

        getStatusNames(s) {
            let n = ''
            switch (s) {
                case 'open':
                    n = s
                    break;
                case 'after market order req received':
                    n = 'AMO'
                    break;
                default:
                    n = s
                    break;
            }
            return n?.toUpperCase();
        },

        getTableData() {
            let data = this.isOpen ? this.getOpenOrders : this.getExcutedOrders
            data = this.getSearch == '' ? data : this.filterData
            return data;
        },

        async MshowModal(post, type, o_type) {
            this.$store.state.buysellpopup.showModal = true;
            this.$store.state.buysellpopup.bsselected = type;
            this.$store.commit('ow/setIsAddOrExit', o_type)
            this.$store.commit('ow/setCurrentScripData', post)
            if(o_type == 'modify' || o_type == 'repeat') {
                this.$store.commit('ow/setIsLocalPreference', false)
            } else {
                this.$store.commit('ow/setIsLocalPreference', true)
            }
            await this.$store.dispatch('ow/getContractInfo',{exchange:post?.exchange, token:post?.token})
            this.$store.commit('ow/setCurrentPage', this.$options.name)
            this.$store.dispatch('ow/setDefaultValues')
            setTimeout(() => {
                this.$store.dispatch('ow/getMargin')
            }, 50);
        },

        singleCancel(data) {
            this.$store.commit('setCancelOrderDialog', true); 
            this.$store.commit('setDraggableCallFrom', 'centerShow');
            this.$store.commit('setCancelOrders', [data])
        },

        async getOrderInfo(item) {
            this.infoData = item
            this.$store.dispatch('order/getOrderHistory',{ orderNo:item.orderNo })
            this.$store.commit('setOrderInfoDialog', true)
        },

        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
            this.getDataFromApi()
        },

        getDataFromApi() {
            return new Promise((resolve, reject) => {
                const { sortBy, descending } = this.pagination
                let items = this.getTableData();
                if (sortBy) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy]
                        const sortB = b[sortBy]
                        if (descending) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                    var strArr = []
                    for (let item of items) {
                        strArr.push(item.token)
                    }
                    this.$store.commit('order/setStringOrderList', strArr)
                }
                let headers = this.isOpen ? this.tableHeadOpen : this.tableHeadExecuted
                headers.findIndex((idex) => {
                    idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
                    idex.value == sortBy ? (idex.isdesc = descending) : "";
                });
            })
        },

        getLot(item) {
            let qty = item.exchange == 'MCX' ? item.qty / item.lotSize : item.qty
            return qty
        },

        getFillShares(item) {
            let fillShares = item.exchange == 'MCX' ? item.fillShares / item.lotSize : item.fillShares
            return fillShares
        },

        mobileActions(data) {
            let item = data.data.item
            if(data.action == 'Repeat') {
                this.MshowModal(item, item.transType == 'B' ? 0 : 1,'repeat')
            } else if(data.action == 'Modify') {
                this.MshowModal(item, item.transType == 'B' ? 0 : 1,'modify')
            } else if(data.action == 'Cancel') {
                this.singleCancel(item)
            } else if(data.action == 'Info') {
                this.getOrderInfo(item)
            } 
        }
    },
}
</script>

<style>
.order-table td {
    @apply text-[#282828] font-medium dark:text-[#EBEBEB]
}
</style>