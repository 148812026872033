<template>
    <div >
        <box :class="getIsTickerTape == '1' ? 'calc-height-2' : 'calc-height-1'">
        <div class="px-3 pt-3 flex secondary-violet-bg rounded-t">
            <Breadcrumb id="etf_breadcrumb" :data="[{ name: 'Dashboard', link: 'dashboard' }, { name: 'ETF - Exchange Traded Funds', link: 'etflist' }]" />
            <div class="mx-2">
                <spinner v-if="getLoader"/>
            </div>
        </div>
        <Tabs page="etf" :data="getEtfTabs" @activeTabName="activeTabObj"/>
        <div v-if="userRole == 'GUEST_USER' && !getMwListLoader" class="text-xs !text-[#070A26] dark:text-[#4e4e4e] flex items-center w-[320px] bg-yellow-100 py-1 px-2 rounded my-2 dark:bg-[#726638]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1 !text-[#070A26] dark:text-[#4e4e4e]">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
            </svg>
            Live Feeds are available only for Clients.
        </div>
        <etftable v-if="!getLoader"/>
        </box>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue';
import Tabs from '@/components/tabs.vue';
import etftable from './ETF-Table.vue';
import { mapGetters } from 'vuex'
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters('home', ['getEtfList', 'getEtfTabs',"getCurrentEtfObject","getStringEtfList"]),
        ...mapGetters(['getLoader']),
        ...mapGetters('login', ['userRole']),
        ...mapGetters('pref',['getIsTickerTape']),
    },
    components: { 
        Breadcrumb, Tabs, etftable
    },
    methods: {
        activeTabObj(sname) {
            this.$store.commit('home/setEtfTabs', { data: this.getEtfList, sname: sname })
            localStorage.setItem('etftab', sname)
            // this.$router.push(`/etflist?tab=${sname}`).catch(() => { })
            this.$store.commit("setQuries", { data: { tab: this.getActiveTab(sname) }, action: "change" });
            this.$store.commit('setActivePageTab', this.getActiveTab(sname))
            let tokenArr = []
            this.getEtfList.forEach(el => {
                if (el.etfName === sname) {
                    let data
                    let object
                    if (el.scrips) {
                        let temp = {}
                        for (let item of el.scrips) {
                            item.wsToken = `${item.exchange}|${item.token}`
                            let tickers = window.tickers
                            item.l = tickers && tickers[item.token] && tickers[item.token].l ? tickers[item.token].l : 0
                            item.lp = tickers && tickers[item.token] && tickers[item.token].lp ? tickers[item.token].lp : 0
                            item.h = tickers && tickers[item.token] && tickers[item.token].h ? tickers[item.token].h : 0
                            item.o = tickers && tickers[item.token] && tickers[item.token].o ? tickers[item.token].o : 0
                            item.c = tickers && tickers[item.token] && tickers[item.token].c ? tickers[item.token].c : 0
                            item.v = tickers && tickers[item.token] && tickers[item.token].v ? tickers[item.token].v : 0
                            temp[item.wsToken] = item
                            tokenArr.push(item.token)
                        }
                        data = el.scrips
                        object = temp
                    } else {
                        data = [],
                        object = ''
                    }
                    this.$store.commit('home/setCurrentEtfList', { data, object, where: 'etfs' })
                }
                this.$store.commit('home/setStringEtfList', tokenArr)
                this.$common.getDocumentTitle(this.$route)
            });
        },

        etfListen(wsd, token) {
            let currentObject = this.getCurrentEtfObject
            if (currentObject && currentObject[token] && wsd && wsd[token]) {
        wsd[token].lp ? currentObject[token].lp = wsd[token].lp : ''
        wsd[token].o ? currentObject[token].o = wsd[token].o : ''
        wsd[token].h ? currentObject[token].h = wsd[token].h : ''
        wsd[token].l ? currentObject[token].l = wsd[token].l : ''
        wsd[token].c ? currentObject[token].c = wsd[token].c : ''
        wsd[token].v ? currentObject[token].v = wsd[token].v : ''
        let data = Object.values(currentObject)

        var OrderedArray = this.getStringEtfList
        const sortArray = data.sort((a, b) => {
            const aKey = a['token']
            const bKey = b['token']
            return OrderedArray?.indexOf(aKey) - OrderedArray?.indexOf(bKey);
        });
        this.$store.commit('home/setCurrentEtfList', { data: sortArray, object: currentObject, where: 'websocket' })
    }
        },
        getActiveTab(val){
            let value = ''
            if(val == 'INDEX BASED' || val == 0){
                value = val == 0 ? 'INDEX BASED' : 0
            }else if(val == 'EQUITY' || val == 1){
                value = val == 1 ? 'EQUITY' : 1
            }else if(val == 'SECTOR' || val == 2){
                value = val == 2 ? 'SECTOR' : 2
            }else if(val == 'GOLD' || val == 3){
                value = val == 3 ? 'GOLD' : 3
            }else if(val == 'DEBT' || val == 4){
                value = val == 4 ? 'DEBT' : 4
            }else if(val == 'GLOBAL' || val == 5){
                value = val == 5 ? 'GLOBAL' : 5
            }
            return value
        }
    },
    async beforeCreate() {
        await this.$store.dispatch('home/getETFDetails')
        // let tab = localStorage.getItem('etftab')
        this.$store.commit( "setActivePageTab", this.$store.state.queries["etflist"] ? this.$store.state.queries["etflist"].query.tab : 0 );
        let tab = this.getActiveTab(this.$store.state.activePageTab)
    
        if (tab) {
            this.activeTabObj(tab)
        } else {
            this.activeTabObj('INDEX BASED')
        }

        window.etfWatch = {
            callback: this.etfListen,
        }
    },
}
</script>