import { createStore } from "vuex";
import ctabs from './modules/tabs.js'
import holdings from './modules/web-app/holdings.js'
import positions from './modules/web-app/positions.js'
import ow from './modules/web-app/orderwindow.ts'
import sector from './modules/web-app/sector.js'
import order from './modules/web-app/orders.js'
import funds from './modules/web-app/funds.js'
import pref from './modules/web-app/client-utils.js'
import alert from './modules/web-app/alert.js'
import home from './modules/web-app/home.js'
import basket from './modules/web-app/basket.js'
import ws from './modules/web-app/websocket.js'
import osf_ws from './modules/web-app/osf-websocket.js'
import chart from './modules/web-app/chart.js'
import util from './modules/web-app/util.js'
import login from './modules/web-app/authentication.js'
import oc from './modules/web-app/optionchain.js'
import brokerage from './modules/web-app/brokerage.js'
import feedback from "./modules/web-app/feedback.js"
import ipo from "./modules/web-app/ipo.js"
// import gtt from './modules/web-app/gtt.js'
import sip from './modules/web-app/sip.js'
import {version} from '../../package.json'
import { commonService } from '../_services/common.service.js';
import errHandle from '../handleError/errorHandling.js';
import router from '../router/index.js'

import watchlist from './modules/web-app/new_mw.js'
import gtt from './modules/web-app/gtt.ts'
import freeze from './modules/web-app/accFreeze.ts'

export default createStore({
  state: {
    appName: "GoPocket",
    appVersion: version,
    exchangeVersion: "1.0.0",
    latestBuild: "new_mw",
    lastBuildName: "live_build_16_01_2025_17_00",
    count: 0,
    buysellpopup: {
      showModal: false,
      moreOption: false,
      bsselected: null,
      tradeType: 0,
      stock: "INFY-EQ",
    },
    cancelOrderDialog: false,
    cancelOrdersArr: [],
    exitPositionDialog: false,
    exitPositionsArr: [],
    tableSearch: "",
    draggableCallFrom: "",
    signOutDialog: false,
    isFloatPnl: "0",
    floatingWidgetData: [
      // { name: "Net P&L", value: "0", change: "0", width:141 },
      { name: "NIFTY50", value: "0", change: "0", width: 141 },
      { name: "BANKNIFTY", value: "0", change: "0", width: 141 },
    ],
    mwPosition: "left",
    loader: false,
    cancelAlertDialog: false,
    createBasketDialog: false,
    isConvertDialog: false,
    isOrderInfoDialog: false,
    isErrorDialog: false,
    deleteScripDialog: false,
    basketDeleteType: "",
    basketDeleteData: "",
    isMwInfoDialog: false,
    windowWidth: 0,
    windowHeigth: 0,
    deviceId: "",
    isPositionInfo: false,
    isFloatingPnl: false,
    isOpenOw: false,
    isBasketDialog: false,
    isRiskDialog: false,
    popDialogData: [],
    watchlistMaxCount: 30,

    // tab active
    defaultQueries: {
      order: {
        query: { tab: 0 },
      },
      position: {
        query: { tab: 0 }
      },
      funds: {
        query: { tab: 0 }
      },
      settings: {
        query: { tab: 0}
      },
      indicestable: {
        query: { tab: 0 }
      },
      screener: {
        query: { tab: 0 }
      },
      sectorlist: {
        query: { tab: 0 }
      }, 
      etflist: {
        query: { tab: 0 }
      }
    },
    queries: {
      order: {
        query: { tab: 0 },
      },
      position: {
        query: { tab: 0 }
      },
      funds: {
        query: { tab: 0 }
      },
      settings: {
        query: { tab: 0}
      },
      indicestable: {
        query: { tab: 0 }
      },
      screener: {
        query: { tab: 0 }
      },
      sectorlist: {
        query: { tab: 0 }
      },
      etflist: {
        query: { tab: 0 }
      }
    },
    activePageTab: 0,
    notifyLayer:true,
    authDialog:false,
    activeTextColor : '#753ED7',
    isGtt_OW: false
  },
  mutations: {
    increment: (state: { count: number }) => state.count++,
    decrement: (state: { count: number }) => state.count--,
    setCancelOrderDialog(state: { cancelOrderDialog: any }, payload: any) {
      state.cancelOrderDialog = payload;
    },
    setCancelOrders(state: { cancelOrdersArr: any }, payload: any) {
      state.cancelOrdersArr = payload;
    },
    //
    setExitPositionDialog(state: { exitPositionDialog: any }, payload: any) {
      state.exitPositionDialog = payload;
    },
    setExitPositions(state: { exitPositionsArr: any }, payload: any) {
      state.exitPositionsArr = payload;
    },
    setSearch(state: { tableSearch: any }, payload: any) {
      state.tableSearch = payload;
    },
    setDraggableCallFrom(state: { draggableCallFrom: any }, payload: any) {
      state.draggableCallFrom = payload;
    },
    setSignOutDialog(state: { signOutDialog: any }, payload: any) {
      state.signOutDialog = payload;
    },
    setIsFloatPnl(state: { isFloatPnl: any }, payload: any) {
      state.isFloatPnl = payload;
    },
    setFloatingWidgetData(state: { floatingWidgetData: any }, payload: any) {
      state.floatingWidgetData = payload;
    },

    setMwPosition(state: { mwPosition: any }, payload: any) {
      state.mwPosition = payload;
    },
    setLoader(state: { loader: any }, payload: any) {
      state.loader = payload;
    },
    setCancelAlertDialog(state: { cancelAlertDialog: any }, payload: any) {
      state.cancelAlertDialog = payload;
    },
    setCreateBaskettDialog(state: { createBasketDialog: any }, payload: any) {
      state.createBasketDialog = payload;
    },
    setIsConvertDialog(state: { isConvertDialog: any }, payload: any) {
      state.isConvertDialog = payload;
    },
    setOrderInfoDialog(state: { isOrderInfoDialog: any }, payload: any) {
      state.isOrderInfoDialog = payload;
    },
    setIsErrorDialog(state: { isErrorDialog: any }, payload: any) {
      state.isErrorDialog = payload;
    },
    setDeleteScripDialog(state: { deleteScripDialog: any }, payload: any) {
      state.deleteScripDialog = payload;
    },
    setBasketDeleteType(state: { basketDeleteType: any }, payload: any) {
      state.basketDeleteType = payload;
    },
    setBasketDeleteData(state: { basketDeleteData: any }, payload: any) {
      state.basketDeleteData = payload;
    },
    // notify(state: any, payload: any) {
    //   // this.$notify({ group: 'auth', type: '', text: `${response.data.message}` })
    // },
    setIsMwInfoDialog(state: { isMwInfoDialog: any }, payload: any) {
      state.isMwInfoDialog = payload;
    },
    setWindowHeightWidth(
      state: { windowWidth: any; windowHeigth: any },
      payload: { w: any; h: any }
    ) {
      state.windowWidth = payload.w;
      state.windowHeigth = payload.h;
    },
    setDeviceId(state: { deviceId: any }, payload: any) {
      state.deviceId = payload;
    },
    setIsPositionInfo(state: { isPositionInfo: any }, payload: any) {
      state.isPositionInfo = payload;
    },
    setIsFloatingPnl(state: { isFloatingPnl: any }, payload: any) {
      state.isFloatingPnl = payload;
    },
    setIsOpenOw(state: { isOpenOw: any }, payload: any) {
      state.isOpenOw = payload;
    },
    setIsBasketDialog(state: { isBasketDialog: any }, payload: any) {
      state.isBasketDialog = payload;
    },
    setIsRiskDialog(state: { isRiskDialog: any }, payload: any) {
      state.isRiskDialog = payload;
      if (payload) {
        localStorage.setItem("isRiskDialog", JSON.stringify(payload));
      } else {
        localStorage.removeItem("isRiskDialog");
        localStorage.removeItem("popDialogData");
      }
    },
    setPopDialogData(state: { popDialogData: any }, payload: any) {
      state.popDialogData = payload;
      localStorage.setItem("popDialogData", JSON.stringify(payload));
    },
    reset_state(state: any) {
      Object.assign(state, {
        appName: "GoPocket",
        appVersion: version,
        exchangeVersion: "1.0.0",
        latestBuild: "build_05_07_2024_16_40",
        count: 0,
        buysellpopup: {
          showModal: false,
          moreOption: false,
          bsselected: null,
          tradeType: 0,
          stock: "INFY-EQ",
        },
        cancelOrderDialog: false,
        cancelOrdersArr: [],
        exitPositionDialog: false,
        exitPositionsArr: [],
        tableSearch: "",
        draggableCallFrom: "",
        signOutDialog: false,
        isFloatPnl: "0",
        floatingWidgetData: [
          // { name: "Net P&L", value: "0", change: "0", width:141 },
          { name: "NIFTY50", value: "0", change: "0", width: 141 },
          { name: "BANKNIFTY", value: "0", change: "0", width: 141 },
        ],
        mwPosition: "left",
        loader: false,
        cancelAlertDialog: false,
        createBasketDialog: false,
        isConvertDialog: false,
        isOrderInfoDialog: false,
        isErrorDialog: false,
        deleteScripDialog: false,
        basketDeleteType: "",
        basketDeleteData: "",
        isMwInfoDialog: false,
        deviceId: "",
        isPositionInfo: false,
        isFloatingPnl: false,
        isOpenOw: false,
        isBasketDialog: false,
        isRiskDialog: false,
        popDialogData: [],
        watchlistMaxCount: 30,
      });
    },

    // query set tab Active
    setQuries(state: any, payload: any) {
      let tempQuries: any = state.queries;
      let path = payload.path
        ? payload.path.toString().replace("/", "")
        : router.currentRoute.value.path.toString().replace("/", "");
      if (payload.action == "intial") {
        tempQuries = payload.data;
      } else {
       state.defaultQueries && state.defaultQueries[path] && !tempQuries[path] ? tempQuries[path] = state.defaultQueries[path] : '' 
        if (path && tempQuries[path]) {
          payload.data.hasOwnProperty("tab")
            ? (tempQuries[path].query.tab = payload.data.tab)
            : "";
          payload.data.hasOwnProperty("sTab1")
            ? (tempQuries[path].query.sTab1 = payload.data.sTab1)
            : "";
          payload.data.hasOwnProperty("sTab2")
            ? (tempQuries[path].query.sTab2 = payload.data.sTab2)
            : "";
        }
      }
      state.queries = tempQuries;
      localStorage.setItem("tabQuries", JSON.stringify(tempQuries));
    },
    setActivePageTab(state: any, payload: any) {
      state.activePageTab = payload;
    },
    setNotifyLayer(state:any, payload:any){
      state.notifyLayer = payload
    },
    setAuthDialog(state:any, payload:any){
      state.authDialog = payload
    },
    setIsGTT_ow(state:any, payload:any){
      state.isGtt_OW = payload
    }
  },
  getters: {
    // countOrders: (state: any) => {
    //   // return state.basketGval.basketList.length
    // },
    getCancelOrderDialog: (state: { cancelOrderDialog: any }) =>
      state.cancelOrderDialog,
    getCancelOrders: (state: { cancelOrdersArr: any }) => state.cancelOrdersArr,
    getExitPositionDialog: (state: { exitPositionDialog: any }) =>
      state.exitPositionDialog,
    getExitPositions: (state: { exitPositionsArr: any }) =>
      state.exitPositionsArr,
    getSearch: (state: { tableSearch: any }) => state.tableSearch,
    getBuysellpopup: (state: { buysellpopup: any }) => state.buysellpopup,
    getSignOutDialog: (state: { signOutDialog: any }) => state.signOutDialog,
    getFloatPnl: (state: { isFloatPnl: any }) => state.isFloatPnl,
    getFloatingWidgetData: (state: { floatingWidgetData: any }) =>
      state.floatingWidgetData,
    getMwPosition: (state: { mwPosition: any }) => state.mwPosition,
    getLoader: (state: { loader: any }) => state.loader,
    getCancelAlertDialog: (state: { cancelAlertDialog: any }) =>
      state.cancelAlertDialog,
    getCreateBasketDialog: (state: { createBasketDialog: any }) =>
      state.createBasketDialog,
    getIsConvertDialog: (state: { isConvertDialog: any }) =>
      state.isConvertDialog,
    getIsOrderInfoDialog: (state: { isOrderInfoDialog: any }) =>
      state.isOrderInfoDialog,
    getIsErrorDialog: (state: { isErrorDialog: any }) => state.isErrorDialog,
    getDeleteScripDialog: (state: { deleteScripDialog: any }) =>
      state.deleteScripDialog,
    getBasketDeleteType: (state: { basketDeleteType: any }) =>
      state.basketDeleteType,
    getBasketDeleteData: (state: { basketDeleteData: any }) =>
      state.basketDeleteData,
    isMwInfoDialog: (state: { isMwInfoDialog: any }) => state.isMwInfoDialog,
    getDeviceId: (state: { deviceId: any }) => state.deviceId,
    getIsPositionInfo: (state: { isPositionInfo: any }) => state.isPositionInfo,
    getIsFloatingPnl: (state: { isFloatingPnl: any }) => state.isFloatingPnl,
    getIsOpenOw: (state: { isOpenOw: any }) => state.isOpenOw,
    getIsBasketDialog: (state: { isBasketDialog: any }) => state.isBasketDialog,
    getIsRiskDialog: (state: { isRiskDialog: any }) => state.isRiskDialog,
    getWindowWidth: (state: { windowWidth:any }) => state.windowWidth,
    getIsMobileView: (state: { windowWidth:any } ) => state.windowWidth < 1024,
    getIsSmallView: (state: { windowWidth:any } ) => state.windowWidth < 640,
    getActiveTextColor: (state: { activeTextColor:any }) => state.activeTextColor,
    getIsGttOw: (state: { isGtt_OW:any }) => state.isGtt_OW
  },
  actions: {
    addOrder: (
      context: { commit: (arg0: string, arg1: any) => void },
      obj: any
    ) => {
      context.commit("ADD_ORDER", obj);
    },
    async callNotifyPop({ state, commit, rootGetters }: any) {
      try {
        let json = {
          userId: rootGetters["login/getUCC"],
        };
        var resp = await commonService.getPopContent(json);
        let respResult: any = [];
        if (
          resp.status == 200 &&
          resp.data.status == "Ok" &&
          resp.data.message == "Success" &&
          resp.data.result &&
          resp.data.result.length > 0
        ) {
          respResult = [...respResult, ...resp.data.result];
          await commit("setPopDialogData", respResult);
          commit(
            "setIsRiskDialog",
            state.popDialogData.length > 0 ? true : false
          );
        }
      } catch (error) {
        errHandle.statusCodeCheck(error);
      }
    },
  },
  modules: {
    ctabs,
    // marginCalc,
    login,
    holdings,
    positions,
    ow,
    sector,
    order,
    funds,
    pref,
    alert,
    home,
    basket,
    ws,
    chart,
    oc,
    brokerage,
    util,
    feedback,
    osf_ws,
    ipo,
    watchlist,
    gtt,
    sip,
    freeze
  },
});
