import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()
import sha256 from "crypto-js/sha256";
import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import route from "../../../router";
import store from "../../../_store"

const url = `wss://feed.gopocket.in/NorenWS/`
const osfurl = `wss://skypro.skybroking.com/NorenWSWebCodi/`
const source = 'WEB'
const osfsource = 'API'

let socket;
let state = {
    connectionStatus: false,
    previousSubsription: null,
    preDepthSubsription: '',
    preDepthPayload: '',
    isReconnect: false,
    isValidSession: false,
    isConnect: false,
    otherSubscription: '',
    validToken: '',
    chartSubscribe: null,
    ocSubs: [], basketSubs: [], floatCardsSubs: [],
    heartBeatInterval: '',
    webSocketCount: 0
}

let actions = {
    async inValidateSession({ rootGetters, dispatch }, payload) {
        try {
            let jsonObj = {
                "userId": rootGetters['login/getUCC'],
                "source": source
            }
            let response = await commonService.inValidateSession(jsonObj)
            if (response.status === 200 && response.data.status == 'Ok') {
                dispatch('createSession')
            } else {
                // errHandle.unauthorizeHandle()
            }
        } catch (error) {
            if (error.response.status == 401) {
                // errHandle.unauthorizeHandle()
            }
        }
    },

    async createSession({ state, rootGetters, dispatch, commit }, payload) {
        try {
            let jsonObj ={
                "userId": rootGetters['login/getUCC'],
                "source": source
            }
            let response = await commonService.createWsSession(jsonObj)
            if (response.status === 200 && response.data.status == 'Ok') {
                // commit('setIsValidSession', true)
                !state.isConnect ? dispatch('connect', 'createSession') : ''
            } else {
                // errHandle.unauthorizeHandle()
            }
        } catch (error) {
            if (error.response.status == 401) {
                // errHandle.unauthorizeHandle()
            }
        }
    },

    async connect({ state, commit, dispatch, rootGetters, rootState }, payload) {
        socket = new WebSocket(url);
        socket.onopen = async function () {
            await dispatch("connectionRequest");
        };
        socket.onmessage = function (msg) {
            var responseFeed = JSON.parse(msg.data);
            if (!!responseFeed.t && responseFeed.t == 'ck' && responseFeed.s == 'OK') {
                commit('setConnectionStatus', true)
                dispatch('subsOrderUpdate')
                if (state.isReconnect || !state.previousSubsription) {
                    dispatch('reconnect')
                    commit('setReconnect', false)
                    state.heartBeatInterval = setInterval(() => dispatch('sendHB'), 10000)
                }
            }
            if (!!responseFeed.tk && !!responseFeed.e) {
                let tickers = window.tickers
                commit('setValidToken', `${responseFeed.e}|${responseFeed.tk}`)
                if (tickers) {
                    if (tickers[`${responseFeed.e}|${responseFeed.tk}`]) {
                    !responseFeed.hasOwnProperty('lp') && tickers[`${responseFeed.e}|${responseFeed.tk}`].lp  ? responseFeed.lp = tickers[`${responseFeed.e}|${responseFeed.tk}`].lp : ''
                    !responseFeed.hasOwnProperty('o') && tickers[`${responseFeed.e}|${responseFeed.tk}`].o  ? responseFeed.o = tickers[`${responseFeed.e}|${responseFeed.tk}`].o : ''
                    !responseFeed.hasOwnProperty('h') && tickers[`${responseFeed.e}|${responseFeed.tk}`].h  ? responseFeed.h = tickers[`${responseFeed.e}|${responseFeed.tk}`].h : ''
                    !responseFeed.hasOwnProperty('l') && tickers[`${responseFeed.e}|${responseFeed.tk}`].l  ? responseFeed.l = tickers[`${responseFeed.e}|${responseFeed.tk}`].l : ''
                    !responseFeed.hasOwnProperty('c') && tickers[`${responseFeed.e}|${responseFeed.tk}`].c  ? responseFeed.c = tickers[`${responseFeed.e}|${responseFeed.tk}`].c : ''
                    !responseFeed.hasOwnProperty('v') && tickers[`${responseFeed.e}|${responseFeed.tk}`].v  ? responseFeed.v = tickers[`${responseFeed.e}|${responseFeed.tk}`].v : ''
                    !responseFeed.hasOwnProperty('pc') && tickers[`${responseFeed.e}|${responseFeed.tk}`].pc  ? responseFeed.pc = tickers[`${responseFeed.e}|${responseFeed.tk}`].pc : ''

                    !responseFeed.hasOwnProperty('ap') && tickers[`${responseFeed.e}|${responseFeed.tk}`].ap  ? responseFeed.ap = tickers[`${responseFeed.e}|${responseFeed.tk}`].ap : ''
                    !responseFeed.hasOwnProperty('52h') && tickers[`${responseFeed.e}|${responseFeed.tk}`]['52h']  ? responseFeed['52h'] = tickers[`${responseFeed.e}|${responseFeed.tk}`]['52h'] : ''
                    !responseFeed.hasOwnProperty('52l') && tickers[`${responseFeed.e}|${responseFeed.tk}`]['52l']  ? responseFeed['52l'] = tickers[`${responseFeed.e}|${responseFeed.tk}`]['52l'] : ''
                    !responseFeed.hasOwnProperty('lc') && tickers[`${responseFeed.e}|${responseFeed.tk}`].lc  ? responseFeed.lc = tickers[`${responseFeed.e}|${responseFeed.tk}`].lc : ''
                    !responseFeed.hasOwnProperty('uc') && tickers[`${responseFeed.e}|${responseFeed.tk}`].uc  ? responseFeed.uc = tickers[`${responseFeed.e}|${responseFeed.tk}`].uc : ''
                    !responseFeed.hasOwnProperty('ltt') && tickers[`${responseFeed.e}|${responseFeed.tk}`].ltt  ? responseFeed.ltt = tickers[`${responseFeed.e}|${responseFeed.tk}`].ltt : ''
                    !responseFeed.hasOwnProperty('ltq') && tickers[`${responseFeed.e}|${responseFeed.tk}`].ltq  ? responseFeed.ltq = tickers[`${responseFeed.e}|${responseFeed.tk}`].ltq : ''
                    !responseFeed.hasOwnProperty('oi') && tickers[`${responseFeed.e}|${responseFeed.tk}`].oi  ? responseFeed.oi = tickers[`${responseFeed.e}|${responseFeed.tk}`].oi : ''

                    !responseFeed.hasOwnProperty('bp1') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bp1  ? responseFeed.bp1 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bp1 : ''
                    !responseFeed.hasOwnProperty('bp2') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bp2  ? responseFeed.bp2 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bp2 : ''
                    !responseFeed.hasOwnProperty('bp3') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bp3  ? responseFeed.bp3 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bp3 : ''
                    !responseFeed.hasOwnProperty('bp4') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bp4  ? responseFeed.bp4 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bp4 : ''
                    !responseFeed.hasOwnProperty('bp5') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bp5  ? responseFeed.bp5 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bp5 : ''

                    !responseFeed.hasOwnProperty('bo1') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bo1  ? responseFeed.bo1 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bo1 : ''
                    !responseFeed.hasOwnProperty('bo2') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bo2  ? responseFeed.bo2 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bo2 : ''
                    !responseFeed.hasOwnProperty('bo3') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bo3  ? responseFeed.bo3 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bo3 : ''
                    !responseFeed.hasOwnProperty('bo4') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bo4  ? responseFeed.bo4 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bo4 : ''
                    !responseFeed.hasOwnProperty('bo5') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bo5  ? responseFeed.bo5 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bo5 : ''

                    !responseFeed.hasOwnProperty('bq1') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bq1  ? responseFeed.bq1 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bq1 : ''
                    !responseFeed.hasOwnProperty('bq2') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bq2  ? responseFeed.bq2 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bq2 : ''
                    !responseFeed.hasOwnProperty('bq3') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bq3  ? responseFeed.bq3 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bq3 : ''
                    !responseFeed.hasOwnProperty('bq4') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bq4  ? responseFeed.bq4 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bq4 : ''
                    !responseFeed.hasOwnProperty('bq5') && tickers[`${responseFeed.e}|${responseFeed.tk}`].bq5  ? responseFeed.bq5 = tickers[`${responseFeed.e}|${responseFeed.tk}`].bq5 : ''
                    
                    !responseFeed.hasOwnProperty('tbq') && tickers[`${responseFeed.e}|${responseFeed.tk}`].tbq  ? responseFeed.tbq = tickers[`${responseFeed.e}|${responseFeed.tk}`].tbq : ''

                    !responseFeed.hasOwnProperty('sp1') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sp1  ? responseFeed.sp1 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sp1 : ''
                    !responseFeed.hasOwnProperty('sp2') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sp2  ? responseFeed.sp2 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sp2 : ''
                    !responseFeed.hasOwnProperty('sp3') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sp3  ? responseFeed.sp3 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sp3 : ''
                    !responseFeed.hasOwnProperty('sp4') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sp4  ? responseFeed.sp4 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sp4 : ''
                    !responseFeed.hasOwnProperty('sp5') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sp5  ? responseFeed.sp5 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sp5 : ''

                    !responseFeed.hasOwnProperty('so1') && tickers[`${responseFeed.e}|${responseFeed.tk}`].so1  ? responseFeed.so1 = tickers[`${responseFeed.e}|${responseFeed.tk}`].so1 : ''
                    !responseFeed.hasOwnProperty('so2') && tickers[`${responseFeed.e}|${responseFeed.tk}`].so2  ? responseFeed.so2 = tickers[`${responseFeed.e}|${responseFeed.tk}`].so2 : ''
                    !responseFeed.hasOwnProperty('so3') && tickers[`${responseFeed.e}|${responseFeed.tk}`].so3  ? responseFeed.so3 = tickers[`${responseFeed.e}|${responseFeed.tk}`].so3 : ''
                    !responseFeed.hasOwnProperty('so4') && tickers[`${responseFeed.e}|${responseFeed.tk}`].so4  ? responseFeed.so4 = tickers[`${responseFeed.e}|${responseFeed.tk}`].so4 : ''
                    !responseFeed.hasOwnProperty('so5') && tickers[`${responseFeed.e}|${responseFeed.tk}`].so5  ? responseFeed.so5 = tickers[`${responseFeed.e}|${responseFeed.tk}`].so5 : ''

                    !responseFeed.hasOwnProperty('sq1') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sq1  ? responseFeed.sq1 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sq1 : ''
                    !responseFeed.hasOwnProperty('sq2') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sq2  ? responseFeed.sq2 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sq2 : ''
                    !responseFeed.hasOwnProperty('sq3') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sq3  ? responseFeed.sq3 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sq3 : ''
                    !responseFeed.hasOwnProperty('sq4') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sq4  ? responseFeed.sq4 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sq4 : ''
                    !responseFeed.hasOwnProperty('sq5') && tickers[`${responseFeed.e}|${responseFeed.tk}`].sq5  ? responseFeed.sq5 = tickers[`${responseFeed.e}|${responseFeed.tk}`].sq5 : ''

                    !responseFeed.hasOwnProperty('tsq') && tickers[`${responseFeed.e}|${responseFeed.tk}`].tsq  ? responseFeed.tsq = tickers[`${responseFeed.e}|${responseFeed.tk}`].tsq : ''
                    }
                    tickers[`${responseFeed.e}|${responseFeed.tk}`] = responseFeed
                    window.tickers = tickers
                    if (window.hasOwnProperty('watchFeedList') && window.watchFeedList[`${responseFeed.e}|${responseFeed.tk}`] && window.watchFeedList[`${responseFeed.e}|${responseFeed.tk}`].hasOwnProperty('callback') && typeof window.watchFeedList[`${responseFeed.e}|${responseFeed.tk}`].callback === 'function'){
                        window.watchFeedList[`${responseFeed.e}|${responseFeed.tk}`].callback(tickers[`${responseFeed.e}|${responseFeed.tk}`])
                    }
                    if (window.hasOwnProperty('pinHeader') && typeof window.pinHeader.callback === 'function'){
                        window.pinHeader.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('miniOptionchain') && typeof window.miniOptionchain.callback === 'function'){
                        window.miniOptionchain.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('optionchain') && typeof window.optionchain.callback === 'function'){
                        window.optionchain.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('holdings') && typeof window.holdings.callback === 'function'){
                        window.holdings.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('homeWatch') && typeof window.homeWatch.callback === 'function'){
                        window.homeWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }

                    if (window.hasOwnProperty('tickerTapeWatch') && typeof window.tickerTapeWatch.callback === 'function'){
                        window.tickerTapeWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('positionWatch') && typeof window.positionWatch.callback === 'function'){
                        window.positionWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('orderWatch') && typeof window.orderWatch.callback === 'function'){
                        window.orderWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('sectorWatch') && typeof window.sectorWatch.callback === 'function'){
                        window.sectorWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('screenerWatch') && typeof window.screenerWatch.callback === 'function'){
                        window.screenerWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('orderWindowWatch') && typeof window.orderWindowWatch.callback === 'function'){
                        window.orderWindowWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('basketWatch') && typeof window.basketWatch.callback === 'function'){
                        window.basketWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('floatingCardsWatch') && typeof window.floatingCardsWatch.callback === 'function'){
                        window.floatingCardsWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('etfWatch') && typeof window.etfWatch.callback === 'function'){
                        window.etfWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if(window.hasOwnProperty('chart') && typeof window.chart.callback === 'function'){
                        window.chart.callback(tickers, `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if(window.hasOwnProperty('popout') && typeof window.popout.callback === 'function'){
                        window.popout.callback(tickers, `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('indIndicesWatch') && typeof window.indIndicesWatch.callback === 'function'){
                        window.indIndicesWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('indicesDashWatch') && typeof window.indicesDashWatch.callback === 'function'){
                        window.indicesDashWatch.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('dashBoard_holdings') && typeof window.dashBoard_holdings.callback === 'function'){
                        window.dashBoard_holdings.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                    if (window.hasOwnProperty('pinHeader') && typeof window.pinHeader.callback === 'function'){
                        window.pinHeader.callback(tickers , `${responseFeed.e}|${responseFeed.tk}`)
                    }
                }
            }
        };

        socket.onerror = function (err) {
            socket.onclose()
            commit('setConnectionStatus', false)
        };

        socket.onclose = function (err) {
            socket.close()
            dispatch('reconnectRequest', 'connect')
        };
    },

    async send({ state, commit, dispatch }, msg) {
        if (!!socket.readyState && socket.readyState == 1) {
            try {
                socket.send(msg);
            } catch (err) {
                console.error(err);
            }
        } else if (!!socket.readyState && socket.readyState == 0) {
            setTimeout(() => { socket.send(msg); }, 900);

        }
    },

    async connectionRequest({ dispatch, rootGetters }) {
        var token = await rootGetters['login/getAccessToken'];
        var userId = await rootGetters['login/getUCC'];
        var encrcptToken = await sha256(sha256(token).toString()).toString();
        var initCon = {
            susertoken: encrcptToken,
            t: "c",
            actid: userId + `_${source}`,
            uid: userId + `_${source}`,
            source: source
        }
        dispatch("send", JSON.stringify(initCon));
    },

    async establishConnection({ state, dispatch }, payload) {
        if (state.connectionStatus == false) {
            await dispatch("connectionRequest");
        }
        let jsonString = JSON.stringify(payload);
        await dispatch("send", jsonString);
    },

    async websocketSubscription({ state, commit, dispatch, rootGetters, rootState }, payload) {
        if (state.connectionStatus) {
            let channel = ''
            let path = route.currentRoute.value.path
            let query = route.currentRoute.value.query
            let where = payload.where
            let validPath = (path == '/order' || path == '/position' || path == '/holdings' || path == '/dashboard' || path == '/sectorlist' || path == '/etflist' || path == '/funds' || path == '/settings' || path == '/chart' || path == '/popoutChart' || path == '/util' || path == '/optionchain' || path == '/screener'|| path == '/indicestable' || path == '/screener' || path == '/marketWatch')
            let preViousList = []
            if (validPath) {
                if(where != 'header'){
                    preViousList = [...rootGetters["pref/getPinnedScrips"]]
                }
                if (validPath && (where == 'holdings' || where == 'positions' || where == 'orders' || where == 'sector' || where == 'etfs' || where == 'indices' || where == 'mkWatch' || where == 'funds' || where == 'settings' || where == 'header')) {
                    if(rootGetters["pref/getMarketwatchType"] != 'Option Chain' && where != 'mkWatch' && where != 'optionchain') {
                        preViousList = [...preViousList , ...rootGetters['watchlist/getCurrentWatchList']]
                    }
                    if(rootGetters["pref/getMarketwatchType"] != 'Option Chain' && (path == '/dashboard' || path == '/holdings' ) && (where == 'mkWatch' ||  where == 'header')) {
                        preViousList =  [...preViousList, ...rootGetters['holdings/getHoldingsData']]
                    }
                }
                if(path == '/dashboard'){
                    preViousList =  [...preViousList, ...rootGetters['home/getHoldingdashData']]
                }
                if (path == '/order' && store.state.queries.order.query.tab == '0' && (where == 'mkWatch' || where == 'header')) {
                    preViousList = [...preViousList, ...rootGetters['order/getOpenOrders']]
                }
                if (path == '/position' && (where == 'mkWatch' || where == 'header' || where == 'optionchain')) {
                    if(store.state.queries.position.query.tab == '0') {
                        preViousList = [...preViousList, ...rootGetters['positions/getPositionsData']]
                    } else if(store.state.queries.position.query.tab == '1') {
                        preViousList = [...preViousList, ...rootGetters['positions/getNfoData']]
                    } else if(store.state.queries.position.query.tab == '3') {
                        preViousList = [...preViousList, ...rootGetters['positions/getMcxData']]
                    }
                    // preViousList = [...preViousList, ...rootGetters['positions/getPositionsData']]
                }
                if (path == '/holdings' && ((where == 'mkWatch' || where == 'header'))) {
                    preViousList = [...preViousList, ...rootGetters['holdings/getHoldingsData']]
                }
                if (path == '/sectorlist' && (where == 'mkWatch' || where == 'header')) {
                    preViousList = [...preViousList, ...rootGetters['pref/getCurrentSectorList']]
                }
                if (path == '/etflist' && where == 'etfs') {
                    preViousList = [...preViousList, ...rootGetters['home/getCurrentEtfList']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 0 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getTopGainersData']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 1 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getTopLosersData']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 2 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getYearHigh']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 3 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getYearLow']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 4 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getRiders']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 5 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getDraggers']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 6 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getVolumeTopper']]
                }
                if (path == '/screener' && store.state.queries.screener.query.tab == 7 ) {
                    preViousList = [...preViousList, ...rootGetters['sector/getMeanReVersion']]
                }
                if(rootGetters['pref/getIsTickerTape'] &&  Number(rootGetters['pref/getIsTickerTape']) == 1 && where != 'tickerTape'){
                    preViousList =[...preViousList ,...rootGetters['pref/getTickerTapeList']]
                }

                if((rootGetters['pref/getIsTickerTape'] &&  Number(rootGetters['pref/getIsTickerTape']) == 1 && where == 'tickerTape') || where == 'tickerTapeDestory'){
                    if(rootGetters['watchlist/getCurrentWatchList'].length > 0){
                        preViousList = [...preViousList ,...rootGetters['watchlist/getCurrentWatchList']]
                    }
                    if (path == '/order' && store.state.queries.order.query.tab == '0' && rootGetters['order/getOpenOrders'].length > 0) {
                        preViousList = [...preViousList ,...rootGetters['order/getOpenOrders']]
                    } else if (path == '/position' && rootGetters['positions/getPositionsData'].length > 0) {
                        if(store.state.queries.position.query.tab == 0) {
                            preViousList = [...preViousList, ...rootGetters['positions/getPositionsData']]
                        } else if(store.state.queries.position.query.tab == 1) {
                            preViousList = [...preViousList, ...rootGetters['positions/getNfoData']]
                        } else if(store.state.queries.position.query.tab == 3) {
                            preViousList = [...preViousList, ...rootGetters['positions/getMcxData']]
                        }
                        // preViousList  = [...preViousList ,...rootGetters['positions/getPositionsData']]
                    } else if ((path == '/holdings' || path == '/dashboard') && rootGetters['holdings/getHoldingsData'].length > 0) {
                        preViousList  = [...preViousList ,...rootGetters['holdings/getHoldingsData']] 
                    } else if (path == '/sectorlist' && rootGetters['pref/getCurrentSectorList'].length > 0) {
                        preViousList = [...preViousList ,...rootGetters['pref/getCurrentSectorList']]
                    } else if (path == '/etflist' && rootGetters['home/getCurrentEtfList'].length > 0) {
                        preViousList = [...preViousList ,...rootGetters['home/getCurrentEtfList']]
                    } else if (path == '/screener' && rootGetters['sector/getScreenerData'].length > 0 && store.state.queries.screener.query.tab == 0) {
                        preViousList = [...preViousList ,...rootGetters['sector/getTopGainersData']]
                    } else if (path == '/screener' && rootGetters['sector/getScreenerData'].length > 0 && store.state.queries.screener.query.tab == 1) {
                        preViousList = [...preViousList ,...rootGetters['sector/getTopLosersData']]
                    }
                }
                
                if(rootGetters["pref/getMarketwatchType"] == 'Option Chain' || where == 'optionchain' && payload.subscribe != '') {
                    channel += payload.subscribe;
                }
                if (path == '/indicestable' ) {
                    preViousList = [...preViousList, ...rootGetters['home/getIndianIndices']]
                }
                // else {
                    if(preViousList.length != 0) {
                        preViousList.forEach(element => {
                            channel += `${element['exchange']}|${element['token']}#`
                        })
                    }
                // }
            }
            payload.data.forEach(element => {
                channel += `${element['exchange']}|${element['token']}#`
            })
            channel = channel?.replaceAll('undefined','')
            if (route.currentRoute.value.path == '/chart' && payload.where == "mkWatch" && state.chartSubscribe) {
                channel += state.chartSubscribe
            }
            if (payload.where == 'tradingChart' ) {
                var previous = state.previousSubsription ? state.previousSubsription + '#' + payload.subscribe : payload.subscribe;
                channel += previous
            }
            if (channel != '' && !!channel) {
                let tempChannel = channel.substring(0, channel.length - 1)
                tempChannel = tempChannel.split('#')
                tempChannel = new Set(tempChannel);

                let uniqueChannel = ''
                let unique = [...tempChannel]

                unique.forEach(element => {
                    element ? uniqueChannel += element + '#' : ''
                })   
                uniqueChannel = uniqueChannel.substring(0, uniqueChannel.length - 1)
                let prevSplit = state.previousSubsription ? state.previousSubsription.split('#') : []
                commit('setPreviousSubsription', uniqueChannel)
                
                let sub_arr = unique.filter(x => !prevSplit.includes(x))
                let un_arr = prevSplit.filter(x => !unique.includes(x))

                let sub_Channel = ''
                let un_Channel = ''
                sub_arr.forEach(element => {
                    sub_Channel += element + '#'
                })
                un_arr.forEach(element => {
                    un_Channel += element + '#'
                })

                sub_Channel = sub_Channel.substring(0, sub_Channel.length - 1)
                un_Channel = un_Channel.substring(0, un_Channel.length - 1)
                
                if (un_arr.length > 0) {
                    let json = {
                        k: un_Channel,
                        t: 'u'
                    };
                    await dispatch('establishConnection', json)
                }

                if (sub_arr.length > 0) {
                    let json = {
                        k: sub_Channel,
                        t: 't'
                    };
                    await dispatch('establishConnection', json)
                }
                await dispatch('setToken', { sub_arr, un_arr });
            }
        }
    },

    async ortherSubscription({ state, commit, dispatch, rootGetters, rootState }, payload) {
        if (state.connectionStatus && payload.sub) {
            let prevArr = state.previousSubsription.split('#')
            let index = prevArr.indexOf(payload.sub)
            let task = index == -1 && payload.stage == 'initial' ? 't' : index == -1 && payload.stage == 'esc' ? 'u' : ''
            // let task = payload.stage == 'initial' ? 't' : payload.stage == 'esc' ? 'u' : ''
            if(index == -1) {
            let json = {
                k: payload.sub,
                t: task
            };
            let sub_arr = []
            let un_arr = []
            if (task == 'u') {
                !payload.sub.includes('#') ? un_arr.push(payload.sub) : un_arr = payload.sub.split('#')
            }
            if (task == 't') {
                !payload.sub.includes('#') ? sub_arr.push(payload.sub) : sub_arr = payload.sub.split('#')
            }
            if(sub_arr.length > 0 || un_arr.length > 0){
            await dispatch('establishConnection', json)
            await dispatch('setToken', { sub_arr, un_arr })
            }
            let prevSub
            if (payload.stage == 'initial') {
                state.otherSubscription ? prevSub = state.otherSubscription + '#' + payload.sub : prevSub = payload.sub
            } else {
                prevSub = null
            }
            commit('setOtherSubscription', prevSub)
        }
        }
    },

    async depthSubscription({ state, commit, dispatch, rootGetters }, payload) {
        if (state.connectionStatus) {
            commit('setPreDepthPayload', payload)
            if (state.preDepthSubsription) {
                let json = {
                    k: state.preDepthSubsription,
                    t: 'ud'
                };
                await dispatch('establishConnection', json)
            }
            !payload ? commit('setPreDepthSubsription', payload) : ''
            if (payload) {
                commit('setPreDepthSubsription', payload)
                let json = {
                    k: payload,
                    t: 'd'
                };
                await dispatch('establishConnection', json)
            }
        }
    },

    async reconnect({ state, commit, dispatch, rootGetters }, payload) {
        clearInterval(state.heartBeatInterval)
        let query = route.currentRoute.value.query
        commit('setPreviousSubsription', null)
        // let tempPreDepthSub = state.preDepthSubsription
        commit('setPreDepthSubsription', null)
        dispatch('pref/checkWebsocketStatus', 'header', { root: true })
        if(rootGetters["pref/getMarketwatchType"] != 'Option Chain') {
            dispatch('watchlist/checkSocketStatus', '', { root: true })
        } 
        if(route.currentRoute.value.path == '/optionchain' || rootGetters["pref/getMarketwatchType"] == 'Option Chain') {
            dispatch('oc/subsOptionChain', rootGetters['oc/OCDFull'], { root: true })
        }
        
        if (rootGetters['pref/getIsTickerTape'] &&  Number(rootGetters['pref/getIsTickerTape']) == 1) {
            dispatch('pref/checkWebsocketStatus', 'tickerTape', { root: true })
        }
        route.currentRoute.value.path == '/etflist' ? dispatch('pref/checkWebsocketStatus', 'etfs', { root: true }) : ''
        route.currentRoute.value.path == '/sectorlist' ?  dispatch('pref/checkWebsocketStatus', 'sector', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 0 ?  dispatch('pref/checkWebsocketStatus', 'Top Gainers', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 1 ?  dispatch('pref/checkWebsocketStatus', 'Top Losers', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 2 ?  dispatch('pref/checkWebsocketStatus', '52 High', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 3 ?  dispatch('pref/checkWebsocketStatus', '52 Low', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 4 ?  dispatch('pref/checkWebsocketStatus', 'Riders', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 5 ?  dispatch('pref/checkWebsocketStatus', 'Draggers', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 6 ?  dispatch('pref/checkWebsocketStatus', 'Volume Toppers', { root: true }) : ''
        route.currentRoute.value.path == '/screener' &&  store.state.queries.screener.query.tab == 7 ?  dispatch('pref/checkWebsocketStatus', 'Mean Reversion', { root: true }) : ''
        route.currentRoute.value.path == '/holdings' || route.currentRoute.value.path == '/dashboard' ? dispatch('holdings/checkWebsocketStatus', '', { root: true }) : ''
        route.currentRoute.value.path == '/position' ? dispatch('positions/checkWebsocketStatus', '', { root: true }) : ''
        route.currentRoute.value.path == '/indicestable' ? dispatch('home/checkWebsocketStatus', 'indices', { root: true }) : ''
        if(route.currentRoute.value.path == '/order' && store.state.queries.order.query.tab == 0) dispatch('order/checkWebsocketStatus', '', { root: true })
        if (state.preDepthSubsription) {
            await dispatch('depthSubscription', state.preDepthPayload)
        }
        if(state.chartSubscribe){
            var tempData = {
                data: [],
                subscribe: state.chartSubscribe,
                where: "tradingChart",
              };
              dispatch("websocketSubscription", tempData);
        }
        
    },

    async websocketClose({ state, commit, dispatch, rootGetters }, payload) {
        socket ? socket.close() : ''
        commit('reset_state')
    },

    async setToken({ state, commit, dispatch, rootGetters }, payload) {
        let tickers
        window.tickers  ? tickers = window.tickers : tickers = {}
        for (let item of payload.sub_arr) {
            

            let token = item
            tickers[token] = null
        }

        var obj = tickers
        for (let item of payload.un_arr) {
            let token = item
            
            for (let k in obj) {
                if (k == token) {
                    delete obj[k]
                }
            }
        }
        window.tickers = obj
    },

    async ochainSubs({ state, commit, dispatch, rootGetters }, payload) {
        if (state.connectionStatus && payload.sub) {
            let task = payload.stage == 'initial' ? 't' : payload.stage == 'esc' ? 'u' : ''
            let json = {
                k: payload.sub,
                t: task
            };
            let sub_arr = []
            let un_arr = []
            if (task == 'u' && payload.sub.length != 0) {
                un_arr = payload.sub.split('#')
            }
            if (task == 't' && payload.sub.length != 0) {
                sub_arr = payload.sub.split('#')
            }
            if(sub_arr.length > 0 || un_arr.length > 0){
                await dispatch('establishConnection', json)
                await dispatch('setToken', { sub_arr, un_arr })
            }
            
            commit('setOCSubs', payload.sub)
        }
    },

    async basketSubscription({ state, commit, dispatch, rootGetters }, payload) {
        if (state.connectionStatus && payload.sub) {
            let task = payload.stage == 'initial' ? 't' : payload.stage == 'esc' ? 'u' : ''
            let json = {
                k: payload.sub,
                t: task
            };
            let sub_arr = []
            let un_arr = []
            if (task == 'u' && payload.sub.length != 0) {
                un_arr = payload.sub.split('#')
            }
            if (task == 't' && payload.sub.length != 0) {
                sub_arr = payload.sub.split('#')
            }
            if(sub_arr.length > 0 || un_arr.length > 0){
            await dispatch('establishConnection', json)
            await dispatch('setToken', { sub_arr, un_arr })
            }
            commit('setBasketSubs', payload.sub)
        }
    },

    async floatingCardSubscription({ state, commit, dispatch, rootGetters }, payload) {
        if (state.connectionStatus && payload.sub) {
            let task = payload.stage == 'initial' ? 't' : payload.stage == 'esc' ? 'u' : ''
            let json = {
                k: payload.sub,
                t: task
            };
            let sub_arr = []
            let un_arr = []
            if (task == 'u' && payload.sub.length != 0) {
                un_arr = payload.sub.split('#')
            }
            if (task == 't' && payload.sub.length != 0) {
                sub_arr = payload.sub.split('#')
            }
            if(sub_arr.length > 0 || un_arr.length > 0){
            await dispatch('establishConnection', json)
            await dispatch('setToken', { sub_arr, un_arr })
            }
            commit('setFloatCardsSubs', payload.sub)
        }
    },

    async subsOrderUpdate({ state, commit, dispatch, rootGetters }) {
        let json = {
            t: 'o',
            actid: rootGetters['login/getUCC'],
        };
        await dispatch('establishConnection', json)
    },

    async unSubsOrderUpdate({ state, commit, dispatch, rootGetters }) {
        let json = {
            t: 'uo'
        };
        await dispatch('establishConnection', json)
    },

    async sendHB({ state, commit, dispatch, rootGetters }) {
        let json = {k: "","t":"h"}
        await dispatch('establishConnection', json)
    },

    async backGroundCheck({state, dispatch}, payload){
        if(navigator.onLine){
            if(payload == 'connect' && socket && socket.readyState != 1){
                await  dispatch('websocketClose')
                await dispatch('reconnectRequest', 'backGroundCheck')
            }
        
        }else{
          console.error(`You Don't have network, Please check your network connection...`)
        }
      },
      reconnectRequest({state, commit, dispatch, rootGetters}, payload){
        commit("setWebSocketCount", state.webSocketCount + 1);
          if (
            rootGetters['login/getAccessToken'] &&
            route.currentRoute.value.path !== "/" &&
            state.webSocketCount < 3
          ) {
            commit("setConnectionStatus", false);
            commit("setReconnect", true);
            dispatch("connect");
          }
      }

}

const mutations = {
    setPreviousSubsription(state, payload) {
        state.previousSubsription = payload;
    },
    setConnectionStatus(state, payload) {
        state.connectionStatus = payload
    },
    setPreDepthSubsription(state, payload) {
        state.preDepthSubsription = payload
    },
    setPreDepthPayload(state, payload) {
        state.preDepthPayload = payload
    },
    setReconnect(state, payload) {
        state.isReconnect = payload
    },
    setOtherSubscription(state, payload) {
        state.otherSubscription = payload
    },
    setValidToken(state, payload) {
        state.validToken = payload
    },
    setChartSubscribe(state, payload) {
        state.chartSubscribe = payload
    },
    setOCSubs(state, payload) {
        state.ocSubs = payload
    },
    setBasketSubs(state, payload) {
        state.basketSubs = payload
    },
    setFloatCardsSubs(state, payload) {
        state.floatCardsSubs = payload
    },
    reset_state(state) {
        Object.assign(state, {
            connectionStatus: false,
            previousSubsription: null,
            preDepthSubsription: '',
            preDepthPayload: '',
            isReconnect: false,
            isValidSession: false,
            isConnect: false,
            otherSubscription: '',
            validToken: '',
            chartSubscribe: null,
            ocSubs: [], basketSubs: [], floatCardsSubs: [],
            webSocketCount : 0
        });
    },
    setWebSocketCount(state, payload){
        state.webSocketCount = payload
    }
}

const getters = {
    getConnectionStatus: (state) => state.connectionStatus,
    getOtherSubscription: (state) => state.otherSubscription,
    getPreviousSubscription: (state) => state.previousSubsription,
    getCToken: (state) => state.validToken,
    getOcSubs: (state) => state.ocSubs,
    getBasketSubs: (state) => state.basketSubs,
    getFloatCardsSubs: (state) => state.floatCardsSubs,
}

const ws = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default ws