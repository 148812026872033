import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'

const state = {
    brokerageDetails: [],
    loader: false,
    isBrokerageWindow: false,
    brokeragePayload: ''
}

const actions = {
    
};

const mutations = {
    setBrokerageDetails(state, payload) {
        state.brokerageDetails = payload
        let rSet = payload
        state.brokerageDetails.turnOver = rSet.turnOver ? parseFloat(rSet?.turnOver).toFixed(2) : 0
        state.brokerageDetails.brokerage = rSet?.brokerage ? parseFloat(rSet?.brokerage).toFixed(2) : 0
        state.brokerageDetails.stt = rSet?.stt ? Number(Math.round(rSet?.stt)) : 0
        state.brokerageDetails.transactionCharge = rSet?.transactionCharge ? parseFloat(rSet?.transactionCharge).toFixed(2): 0
        state.brokerageDetails.sebi = rSet?.sebi ? parseFloat(rSet?.sebi).toFixed(2) : 0
        state.brokerageDetails.stampCharges = rSet?.stampCharges ? Number(Math.round(rSet?.stampCharges)) : 0
        state.brokerageDetails.cmCharges = rSet?.cmCharges ? parseFloat(rSet?.cmCharges).toFixed(2) : 0
        state.brokerageDetails.gst = rSet?.gst ? parseFloat(rSet?.gst).toFixed(2) : 0
        state.brokerageDetails.ipft = rSet?.ipft ? parseFloat(rSet?.ipft).toFixed(2) : 0

        state.brokerageDetails.totalClone = Number(state.brokerageDetails.brokerage) + Number(state.brokerageDetails.stt) + Number(state.brokerageDetails.transactionCharge) + Number(state.brokerageDetails.sebi) + Number(state.brokerageDetails.stampCharges) + Number(state.brokerageDetails.cmCharges) + Number(state.brokerageDetails.gst) + Number(state.brokerageDetails.ipft)
        state.brokerageDetails.totalClone = parseFloat(state.brokerageDetails.totalClone).toFixed(2)
    },
    setIsBrokerageWindow(state, payload) {
        state.isBrokerageWindow = payload
    },
    setLoader(state, payload) {
        state.loader = payload
    },
    setBrokeragePayload(state, payload) {
        state.brokeragePayload = payload.data
        state.brokeragePayload.scripName = payload.ci.formattedInsName
        state.brokeragePayload.exchange = payload.ci.exchange
        state.brokeragePayload.priceType = payload?.priceType == 'MKT' ? 'Market' : payload?.priceType == 'LMT' ? 'Limit' : payload.priceType
    },
    reset_state(state) {
        Object.assign(state, {
            brokerageDetails: [],
            loader: false,
            isBrokerageWindow: false,
            brokeragePayload: ''
        });
    },
};

const getters = {
    brokerageData: state => state.brokerageDetails,
    isBrokerageWindow: state => state.isBrokerageWindow,
    isLoading: state => state.loader,
    brokeragePayload: state => state.brokeragePayload
};

const brokerage = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default brokerage