import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import router from '../../../router/index'
import { useNotification } from "@kyvg/vue3-notification";
import { env, service_return, endpoint } from "../../../_helpers"
import commonChart from '../../../views/home/chart/commonChart';
const { notify } = useNotification()

const state = {
    userPref: '',
    isTickerTape: '0',
    mwListPosition: '0',
    isStickyOW: '0',
    isTheme: '0',
    sectors: [],
    currentSectorList: [],
    sectorsTabs: [],
    userDetails: '',
    currentSectorObject:'',
    tickerTapeList: [],
    tapLoader: false,
    tickerObjectList:'',
    settingsTab: [
        // { name: 'Personal Profile', id: 0, active: true },
        { name: 'Account Info', id: 0, active: false },
        { name: 'Trade Settings', id: 1, active: true },
        { name: 'Security Settings', id: 2, active: false },
        { name:'Markets', id:3, active:false },
    ],
    marketwatchType: 'My Watchlist',
    securityInfo : '',
    prefLoader: false,
    owType: '0',
    changePassErrorMsg: '',
    pinnedScrips: [],
    sortOrder: 1,
    setPinUpChannel:'',
    sortPosition: '0',
    watchlistView: '0',
    screenerTabs: [
        { name: 'Top Gainers', id: 0, active: true },
        { name: 'Top Losers', id: 1, active: false },
        { name: '52 Week High', id: 2, active: false },
        { name: '52 Week Low', id: 3, active: false },
        { name: 'Riders', id: 4, active: false },
        { name: 'Draggers', id: 5, active: false },
        { name: 'Volume Toppers', id: 6, active: false },
        { name: 'Mean Reversion', id: 7, active: false },
    ],
}

const actions = {
    async getUserPref({ state, commit, dispatch, rootGetters }, payload) {
        if(payload.from == 'router' || payload.from == 'login' || payload.from == 'vendor') {
            commit('login/setOtpLoader', true, { root: true })
        }
        commit('login/setLoader', true, { root: true })
        let json = {
                method: env().HTTP_method.GET,
                module: env().module.client,
                path: endpoint.getUserPref,
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_2',
                from: payload.from
        }
        let response = await service_return(json)
        if (response && (response.data.status == "Ok" || response.data.status == "not_ok" || response.data.status == "Not ok") && (response.data.message == "Success" || response.data.message == "Guest User")) {
                commit('setUserPref', response.data.result)
                if(payload && payload.from){
                    if(!rootGetters['login/getSsoQuery']){
                    payload.from == 'login' ? dispatch("callNotifyPop", '', { root: true }) : ''
                    payload.from == 'login' || payload.from == 'router' ? router.push('/dashboard').catch(failure => {}) : ''
                    payload.from == 'login' || payload.from == 'router' || payload.from == 'main' ? await dispatch('getProfileData') : ''
                    }
                    if(rootGetters['login/getSsoQuery'] && rootGetters['login/getSsoQuery'].appcode){
                        dispatch('login/checkVendorAuthorize', rootGetters['login/getSsoQuery'].appcode , {root: true})
                    }else{
                        commit('login/setOtpLoader', false, { root: true })
                    }
                }
        } 
        commit('login/setOtpLoader', false, { root: true })
    },

    async addUserPref({ commit, rootGetters }, payload) {
        try {
            let defaultPref = []
            if (payload.type == 'single') {
                defaultPref.push({ 'tag': 'SettingsWeb',
                    'keyVariable': payload.key, 
                    'value': 'false', "userId" :  rootGetters['login/getUCC'] })
            } else {
                defaultPref = [
                    {
                        "tag": "SettingsWeb",
                        "keyVariable": "WatchListSwitch",
                        "value": "false",
                        "userId" :  rootGetters['login/getUCC']
                    },
                    {
                        "tag": "SettingsWeb",
                        "keyVariable": "IsTickerTable",
                        "value": "true",
                        "userId" :  rootGetters['login/getUCC']
                    },
                    {
                        "tag": "SettingsWeb",
                        "keyVariable": "StickyOrderWindow",
                        "value": "false",
                        "userId" :  rootGetters['login/getUCC']
                    }
                ]
            }
            let resp = await commonService.addUserPref(defaultPref)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                commit('setUserPref', resp.data.result)
            } else {
                commit('setUserPref', [])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async updateUserPref({ state, commit, dispatch, rootGetters }, payload) {
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.client,
                path: endpoint.updateUserPref,
                params: {
                    "tag": payload.keyVariable,
                    "value": payload.value
                },
                loaderMutation: '',
                axiosType: 'AXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response.data.status == "Ok" && response.data.message == "Success") {
                if(response.data.result) {
                    commit('setUserPref', response.data.result)
                } else {
                    dispatch('getUserPref', { from: 'update' })
                }
            }
    },

    async getProfileData({ state, commit, dispatch, rootGetters }, payload) {
            let json = {
                method: env().HTTP_method.GET,
                module: env().module.client,
                path: endpoint.getProfileData,
                params: '',
                loaderMutation: '',
                axiosType: 'AXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response?.data?.status == "Ok" && response?.data?.message == "Success" && response?.data?.result) {
                commit('login/setIsValidSession', true, { root: true })
                commit('setUserDetails', response.data.result[0])
            } else {
                commit('setUserDetails', [])
            }
    },

    async checkWebsocketStatus({state, commit, dispatch, rootGetters}, payload){
        if (rootGetters['ws/getConnectionStatus'] && (payload == 'sector' || payload == 'tickerTape' || payload == 'tickerTapeDestory' || payload == 'etfs' || payload == 'header' || payload == 'Top Losers' || payload == 'Top Gainers' || payload == '52 High' || payload == '52 Low' || payload == 'Riders' || payload == 'Draggers' || payload == 'Volume Toppers' || payload == 'Mean Reversion'  )) {
            var tempData = {
                data: payload == 'sector' ? state.currentSectorList : payload == 'tickerTape' ? state.tickerTapeList : payload == 'etfs' ? rootGetters['home/getCurrentEtfList'] : payload == 'header' ? state.pinnedScrips : payload == 'Top Gainers' ? rootGetters['sector/getTopGainersData'] :  payload == 'Top Losers' ? rootGetters['sector/getTopLosersData'] : payload == '52 High' ? rootGetters['sector/getYearHigh'] : payload == '52 Low' ? rootGetters['sector/getYearLow'] : payload == 'Riders' ? rootGetters['sector/getRiders'] : payload == 'Draggers' ? rootGetters['sector/getDraggers'] : payload == 'Volume Toppers' ? rootGetters['sector/getVolumeTopper'] : payload == 'Mean Reversion' ? rootGetters['sector/getMeanReVersion'] : [],
                where: payload
            }
            await  dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },

    async getTickerTapeList({state, commit, dispatch, rootGetters}, payload){
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.watchlist,
                path: endpoint.getTickerTapeList,
                params: {
                    "userId": rootGetters['login/getUCC'],
                },
                loaderMutation: '',
                axiosType: 'AXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response.data.status == "Ok" && response.data.message == "Success" && response.data.result) {
                let temp = {}
                for(let item of response.data.result) {
                    item.wsToken = `${item.exchange}|${item.token}`
                    let tickers = window.tickers
                    item.lp = tickers && tickers[item.wsToken] && tickers[item.wsToken].lp ? tickers[item.wsToken].lp :  item.pdc
                    item.pc = tickers && tickers[item.wsToken] && tickers[item.wsToken].pc && tickers[item.wsToken].pc != 'inf' ? tickers[item.wsToken].pc :  '0.00'
                    temp[item.wsToken]= item
                }
        
                commit('setTickerTapeList', response.data.result)
                commit('setObjectTickerList',  response.data.result.length > 0 ? temp : '')
                dispatch('checkWebsocketStatus', 'tickerTape')
            } else {
                commit('setTickerTapeList', [])
                commit('setObjectTickerList', '')
            }
    },

    async subsFloatCards({ state, rootGetters, dispatch }) {
        if (rootGetters['ws/getConnectionStatus']) {
            var tempData = {
                sub: 'NSE|26000#NSE|26009',
                stage: 'initial'
            }
            dispatch("ws/floatingCardSubscription", tempData, { root: true })
        }
    },

    async unSubsFloatCards({ state, rootGetters, dispatch }) {
        if (rootGetters['ws/getConnectionStatus']) {
            var tempData = {
                sub: rootGetters['ws/getFloatCardsSubs'],
                stage: 'esc'
            }
            dispatch("ws/floatingCardSubscription", tempData, { root: true })
        }
    },

    async getSecurityInfo({ state,commit, rootGetters, dispatch }, payload) {
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.scrips,
                path: endpoint.getSecurityInfo,
                params: {
                    "exch": payload.exchange,
                    "token" : payload.token
                },
                loaderMutation: '',
                axiosType: 'AXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response.data.status == "Ok" && response.data.message == "Success" && response.data.result) {
                commit('setSecurityInfo', response.data.result[0])
            } else {
                commit('setSecurityInfo', [])
            }
    },

    async changePassword({ state,commit, rootGetters, dispatch }, payload) {
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.changePassword,
                params: {
                    "userId": rootGetters['login/getUCC'],
                    "password": payload.currentPass,
                    "newPassword": payload.confirmPass
                },
                loaderMutation: '',
                axiosType: 'AXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response.data.status == "Ok" && response.data.message == "Password changed successfully") {
                notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
    },

    async getPinnedScrips({ state, commit, dispatch }) {
        try {
            let resp = await commonService.getPinnedScrips()
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data?.result?.length != 0) {
                commit('setPinnedScrips', resp.data.result)
            } else {
                commit('setPinnedScrips', [])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async pinToHeader({ state, commit, dispatch }, payload) {
        try {
            commit('setSortOrder', payload?.sortOrder )
            let resp = await commonService.pinToHeader(payload)
            if (resp.data.status == "Ok" && resp.data.message == "Success") {
                dispatch('getPinnedScrips')
            } 
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },
};

const mutations = {
    setUserPref(state, payload) {
        state.userPref = payload
        localStorage.setItem('preference', JSON.stringify(state.userPref))

        state.userPref.forEach(el => {
            if (el.tag == "tkt") {
                this.commit('pref/setIsTickerTape', el.value)
            }
            if (el.tag == "sow") {
                this.commit('pref/setIsStickyOW', el.value)
            }
            if (el.tag == "mwp") {
                this.commit('pref/setMwListPosition', el.value)
            }
            if(el.tag == "mwo") {
                this.commit('pref/setIsMarketwatch', el.value)
                
            }
            if(el.tag == "thm") {
                this.commit('pref/setIsTheme', el.value)
            }
            if(el.tag == "owt") {
                this.commit('pref/setOwType', el.value)
            }
            if(el.tag == "dep") {
                this.commit('pref/setWatchlistView', el.value)
            }
            if(el.tag == "mwt") {
                this.commit('pref/setSortPosition', el.value )
            }
        });
    },

    setIsTickerTape(state, payload) {
        state.isTickerTape = payload
    },

    setIsStickyOW(state, payload) {
        state.isStickyOW = payload
    },

    setMwListPosition(state, payload) {
        state.mwListPosition = payload
    },

    setIsTheme(state, payload) {
        state.isTheme = payload
        if(state.isTheme == '1') {
            document.documentElement.classList.add('dark')
        } else {
          document.documentElement.classList.remove('dark')
        }
        if(router.currentRoute.value.path == "/chart" || router.currentRoute.value.path == "/popoutChart"){
            commonChart.methods.chartListener({data: {id: 'themeUpdate'}}) 
        }
        
    },

    setOwType(state, payload) {
        state.owType = window.width < 1024 ? '0' : payload
    },

    setIsMarketwatch(state, payload) {
        state.marketwatchType = payload == '0' ? 'My Watchlist'
                                : payload == '1' ? 'Predefined Watchlist' 
                                : payload == '2' ? 'Option Chain' : 'My Watchlist'
    this.commit('watchlist/setMwTabs', state.marketwatchType)
    localStorage.setItem('activeMwNameTab', JSON.stringify(payload))
    },

    setSectors(state, payload) {
        state.sectors = payload
    },

    setCurrentSectorList(state, payload) {
        state.currentSectorList = payload.data
        state.currentSectorObject = payload.object
        payload.where == 'sector' ? this.dispatch('pref/checkWebsocketStatus', payload.where) : ''
    },

    setSectorsTabs(state, payload) {
        let arr = []
        payload.data.forEach((el, idx) => {
            arr.push({
                'name': `${el.sector}`,
                'showname': `${el.sector} (${el.scrips?.length})`,
                'active': el.sector == payload.sname ? true : false,
                'id': idx
            })
        });
        state.sectorsTabs = arr
    },

    setUserDetails(state, payload) {
        state.userDetails = payload
    },

    setTickerTapeList(state, payload) {
        state.tickerTapeList = payload
    },

    setObjectTickerList(state, payload) {
        state.tickerObjectList = payload
    },

    setSettingsTab(state, payload) {
        let arr = []
        payload.data.forEach((el, idx) => {
            arr.push({
                'name': el.name,
                'active': el.name == payload.sname ? true : false,
                'id': idx
            })
        });
        state.settingsTab = arr
    },

    setSecurityInfo(state, payload) {
        state.securityInfo = payload
    },

    setPrefLoader(state, payload) {
        state.prefLoader = payload
    },
    setChangePassErrorMsg(state, payload) {
        state.changePassErrorMsg = payload
    },

    setPinnedScrips(state, payload) {
        let tickers = window.tickers
        let subscribe = "";
        payload.forEach(el => {
            el.tradingSymbol = el?.symbol
            el.ltp = '0.00'
            el.pc = '0.00'
            el.chg = '0.00'
            el.wsToken = `${el.exchange}|${el.token}`
            if(tickers) {
                el.ltp = tickers[el.wsToken]?.lp ? tickers[el.wsToken]?.lp : el.ltp
                el.pc = tickers[el.wsToken]?.pc ? tickers[el.wsToken]?.pc : el.pc
            }
            // chart keys
            if (el.segment && el.segment.includes('_idx')) {
                el.exchIndex = `${el.exchange}::index`
            }else{
                el.exchIndex = el.exchange  
            }
            subscribe += `${el.exchange}|${el.token}#`;
        });
        
        payload.sort((a, b) => a.sortOrder - b.sortOrder);
        state.pinnedScrips = payload
        this.commit("pref/setPinUpChannel", subscribe); 
        this.dispatch('pref/checkWebsocketStatus', 'header')
    },

    setPinUpChannel(state, payload) {
        state.setPinUpChannel = payload;
    },

    setSortOrder(state, payload) {
        state.sortOrder = payload
    },
    setSortPosition(state, payload) {
        state.sortPosition = payload
    },
    setWatchlistView(state, payload) {
        state.watchlistView = payload
    },
    setScreenerTabs(state, payload) {
        state.screenerTabs.forEach(el => {
            el.active = el.id === payload
        })
    },
    reset_state(state) {
        Object.assign(state, {
            userPref: '',
            isTickerTape: '0',
            mwListPosition: '0',
            isStickyOW: '0',
            isTheme: '0',
            sectors: [],
            currentSectorList: [],
            sectorsTabs: [],
            userDetails: '',
            currentSectorObject:'',
            tickerTapeList: [],
            tapLoader: false,
            tickerObjectList:'',
            settingsTab: [
                // { name: 'Personal Profile', id: 0, active: true },
                { name: 'Account Info', id: 0, active: false },
                { name: 'Trade Settings', id: 1, active: false },
                { name: 'Security Settings', id: 2, active: false },
                { name:'Markets', id:3, active:false },
            ],
            marketwatchType: 'My Watchlist',
            securityInfo : '',
            prefLoader: false,
            owType: '0',
            changePassErrorMsg: '',
            pinnedScrips: [],
            sortOrder: 1,
            setPinUpChannel:'',
            sortPosition: '0',
            watchlistView: '0',
            screenerTabs: [
                { name: 'Top Gainers', id: 0, active: true },
                { name: 'Top Losers', id: 1, active: false },
                { name: '52 Week High', id: 2, active: false },
                { name: '52 Week Low', id: 3, active: false },
                { name: 'Riders', id: 4, active: false },
                { name: 'Draggers', id: 5, active: false },
                { name: 'Volume Toppers', id: 6, active: false },
                { name: 'Mean Reversion', id: 7, active: false },
            ],
        });
    },
};

const getters = {
    getUserPref: state => state.userPref,
    getIsTickerTape: state => state.isTickerTape,
    getMwListPosition: state => state.mwListPosition,
    getIsStickyOW: state => state.isStickyOW,
    // get sectors
    getSectors: state => state.sectors,
    getCurrentSectorList: state => state.currentSectorList,
    getSectorsTabs: state => state.sectorsTabs,
    getUserDetails : (state) => state.userDetails,
    getTickerTapeList: state => state.tickerTapeList,
    getSettingsTab: state => state.settingsTab,
    getMarketwatchType: state => state.marketwatchType,
    getSecurityInfo: state => state.securityInfo,
    getIsTheme: state => state.isTheme,
    isPrefLoader: state => state.prefLoader,
    isOwType: state => state.owType,
    changePassErrorMsg: state => state.changePassErrorMsg,
    getPinnedScrips: state => state.pinnedScrips,
    getSortOrder: state => state.sortOrder,
    getPinupChannel : state => state.setPinUpChannel,
    getSortPosition : state => state.sortPosition,
    getWatchlistView: state => state.watchlistView,
    getScreenerTabs: state => state.screenerTabs,
    getScreenerTabs: state => state.screenerTabs
};

const pref = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default pref