<template>
    <div v-if="!getIsMobileView">
        <div v-if="getBasketList.length != 0 && !getLoader">
            <div class="flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative overflow-hidden overflow-y-auto rounded-b-[8px]">
                        <table id="basket_table" class="min-w-full table-fixed rounded-b border-[#ededed] dark:border-[#232325] table-common">
                            <thead >
                                <tr>
                                    <th scope="col" class="px-2 py-3.5 text-left text-xs font-semibold secondary-color" id="basket_th_createdon">
                                        Created On</th>
                                    <th scope="col" class="min-w-[12rem] py-3.5 px-2 text-left text-xs font-semibold secondary-color" id="basket_th_basketname">
                                        Basket Name</th>
                                    <th scope="col" class="px-2 py-3.5 text-center text-xs font-semibold secondary-color" id="basket_th_items">
                                        Items</th>
                                    <th scope="col" class="px-2 py-3.5 text-center text-xs font-semibold secondary-color" id="basket_th_status">
                                        Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :id="`basketlist_${idx}`" v-for="(item, idx) in this.getSearch == '' ? getBasketList : filterData" :key="idx" @mouseover="currentIndex = idx"
                                    @mouseleave="currentIndex = -1; showDropDown = false">
                                    <td class="text-left px-2 py-4 text-sm primary-color" :id="`basket_td_${idx}_createdon`">
                                        {{ $common.getFormatedDate(item?.createdOn) }}
                                    </td>
                                    <td class=" px-2 py-4 text-sm relative primary-color">
                                        <span :id="`${item?.basketName}_basketname`" :name="item?.basketName">{{ item?.basketName }}</span>
                                        <span class="absolute right-0" v-if="currentIndex == idx">
                                            <button :id="`${item?.basketName}_view_btn`" @click="getBasketScrips(item)" class="px-3 py-1 text-xs text-white mr-2 green-bg rounded">View</button>
                                            <button :id="`${item?.basketName}_del_btn`" @click="deleteBasket(item)"
                                                class="px-3 py-1 text-xs text-white mr-2 red-bg rounded">Delete</button>
                                        </span>
                                    </td>
                                    <td class="text-center px-2 py-4 text-sm font-semibold primary-color" :id="`${item?.basketName}_items`">
                                        {{ item?.scripCount }}
                                    </td>
                                    <td class="text-center px-2 py-4 text-sm primary-color" :id="`${item?.basketName}_status`">
                                        <button class="px-3 text-[10px] rounded min-w-[100px] cursor-default tracking-[0.4px] min-h-[24px]" :class="item?.isExecuted == 0 ? 'gray-btn-c' : 'green-btn-c'">{{ item?.isExecuted == 0 ? 'OPEN' : 'EXECUTED' }}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div v-else-if="!getLoader" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
            <nodata type="Basket"/>
        </div>
    </div>
    <mobileView v-else-if="getBasketList.length" :listData="getBasketList" @call_action="mobileActions"/>
    <div v-else-if="!getLoader" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
        <nodata type="Basket"/>
    </div>
    <delete_scrip deleteType="basket" :data="currentDeleteItem" v-if="getDeleteScripDialog"/>
</template>

<script>
import { mapGetters } from 'vuex'
import table_head_section from '../../../component/table-head-section.vue'
import nodata from '../../no-data.vue'
import delete_scrip from './basket-delete-dialog.vue'
import mobileView from "./list-mobile-view.vue"
export default {
    data() {
        return {
            currentIndex: -1,
            showDropDown: false,
            currentDeleteItem: ''
        }
    },
    computed: {
        ...mapGetters('basket', ['getBasketList']),
        ...mapGetters(['getSearch','getDeleteScripDialog','getLoader','getIsMobileView']),
        
        filterData() {
            return this.getBasketList.filter((post) => {
                return (
                    post.basketName?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.createdOn?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) || 
                    post.isExecuted?.toLowerCase()?.includes(this.getSearch?.toLowerCase()) ||
                    String(post.scripCount)?.toLowerCase()?.includes(this.getSearch?.toLowerCase())
                );
            });
        },
    },
    components: { table_head_section, nodata, delete_scrip, mobileView },
    methods: {
        handleClick() {
            setTimeout(() => {
                this.showDropDown = false
            }, 50);
        },

        invertDropdown() {
            this.showDropDown = !this.showDropDown
        },

        getClass(status) {
            let cn = '';
            let stat = status?.toLowerCase()
            if (stat == 'open') {
                cn = 'orange-btn-c'
            } else if (stat == 'completed') {
                cn = 'bg-green-50 text-green-700'
            } else if (stat == 'after market order req received') {
                cn = 'gray-btn-c'
            } else if (stat == 'rejected') {
                cn = 'red-btn-c'
            } else {
                cn = 'gray-btn-c'
            }
            return cn
        },

        deleteBasket(item) {
            this.currentDeleteItem = item
            this.$store.commit('setBasketDeleteType','basketDelete')
            this.$store.commit('setBasketDeleteData', item)
            this.$store.commit('setDeleteScripDialog', true)
        },

        async getBasketScrips(item) {
            this.$store.commit('basket/setCurrentBasketData', item)
            // this.$modal.show("basket-list");
            this.$store.commit('basket/setIsBasket', true)
            this.$store.dispatch('basket/getBasketScrips', item)
        },

        mobileActions(data) {
            let item = data.data.item
            if(data.action == 'View') {
                this.getBasketScrips(item)
            } else if(data.action == 'del') {
                this.deleteBasket(item)
            } 
        }
    },
}
</script>