<template>
    <div class="progress-container">
      <div class="progress-line"></div>
    </div>
</template>

<style>
.progress-container {
  width: 100%;
  height: 2px;
  overflow: hidden;
  background-color: #e9ecef;
  position: relative;
}

.progress-line {
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, transparent, #753ED7);
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  animation: loading 0.9s ease-in-out infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>