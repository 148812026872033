<template>
    <div class="">
        <div class="my-4">
            <img :src="nodatasvg" alt="nodata" class="min-w-[280px]">
        </div>
        <div id="no_data_text" class="primary-color dark:text-[#94A3B8] text-center mb-4" v-if="type != 'Comming Soon' && type != 'Basket' && type != 'Alert'"> {{`No ${type || `Data`} Found`}}
        </div>
        <div class="primary-color" :id="type == 'Basket' ? 'create_bas_text' :type == 'GTT Orders'? 'create_gtt_btn': 'create_alert_text'" v-if="type == 'Basket' || type == 'Alert' || type == 'GTT Orders'">You do not have any {{ type }} created</div>
            <div class="my-4 flex justify-center">
                <button :id="type == 'Basket' ? 'create_bas_btn' :type == 'GTT Orders'? 'create_gtt_btn' : 'create_alert_btn'" @click="createAlertOrBasket()" v-if="type == 'Basket' || type == 'Alert' || type == 'GTT Orders'" 
                    class="border-[#753ED7] violet-bg text-white
                    font-medium text-sm rounded hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none 
                    focus:ring-0 active:bg-indigo-800 h-[40px] px-4 mx-2 dark:border-gray-500"> <span>Create </span><span>a </span><span>{{ type }}</span></button>
            </div>
    </div>
</template>

<script>
import nodatasvg from '../../assets/img/no_data.svg'
export default {
    data() {
        return {
            nodatasvg
        }
    },
    props: {
        type: String
    },
    methods: {
        createAlertOrBasket() {
            if (this.type == 'Alert') {
                this.$store.commit('alert/setCurrentAlertData', '')
                this.$store.commit('alert/setIsAlertDialog', { boolean: true, type: 'Create' })
            } else if(this.type == 'Basket') {
                this.$store.commit('setCreateBaskettDialog', true)
            } else if(this.type == 'GTT Orders') {
                this.$store.commit('gtt/setGttInitialDialog', true)
            }
        }
    },
}
</script>