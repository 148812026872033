<template>
  <form @submit.prevent="cancelOrderFromApi">
    <DraggableDiv :wwidth="getIsSmallView ? '340' : '600'" name="cancel_order" class="bg-gray-50 dark:bg-zinc-800 rounded-md shadow-2xl">
      <template v-slot:header>
        <h3 class="text-lg font-bold leading-6 p-3 primary-color" id="cancel_order_model">{{ !selectedData.snoOrderNumberCancel ? 'Cancel' : 'Exit' }} {{ selectedData?.length> 1 ? 'Orders' : 'Order'}}
          <span v-if="selectedData?.length > 1">({{ selectedData?.length }})</span>
        </h3>
      </template>
      <template v-slot:main>
        <!-- cancel orders TABLE -->
        <div class="mx-3 border dark:border-[#3c3c3e] rounded-md max-h-[300px]">
          <div class="lg:flex flex-col hidden">
            <div class="max-h-[300px] tableoverflow overflow-y-auto overflow-x-auto">
              <div class="inline-block min-w-full align-middle">
                <table id="cancel_order_tbl" class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-white dark:bg-zinc-800 border-b dark:border-[#3c3c3e] sticky top-0 rounded-t-md">
                    <tr>
                      <th scope="col" class="py-3.5 px-3 text-left text-xs font-semibold secondary-color rounded-tl-md" id="cancel_order_instrument">Instrument</th>
                      <th scope="col" class="py-3.5 px-3 text-right text-xs font-semibold secondary-color" id="cancel_order_oty">Qty</th>
                      <th scope="col" class="py-3.5 px-3 text-right text-xs font-semibold secondary-color" id="cancel_order_price">Price
                      </th>
                      <th scope="col" class="py-3.5 px-3 text-left text-xs font-semibold secondary-color" id="cancel_order_type">Type
                      </th>
                      <th scope="col" class="py-3.5 px-3 text-center text-xs font-semibold secondary-color" id="cancel_order_product">Product
                      </th>
                      <th scope="col" class="py-3.5 px-3 text-center text-xs font-semibold secondary-color rounded-tr-md" id="cancel_order_validity">Validity</th>
                    </tr>
                  </thead>
                  <tbody class="overflow-y-auto">
                    <tr v-for="(item, idx) in selectedData" :key="idx" class="border-b border-[#ededed] dark:border-[#3c3c3e]">
                      <td class="whitespace-nowrap py-4 px-3 text-sm primary-color" :id="`cancel_order_tradsym_${idx}`">{{ item.tradingSymbol }}</td>
                      <td class="whitespace-nowrap text-right py-4 px-3 text-sm primary-color" :id="`cancel_order_qty_${idx}`">{{ item.qty }}</td>
                      <td class="whitespace-nowrap text-right py-4 px-3 text-sm primary-color" :id="`cancel_order_price_${idx}`">{{ item.price }}
                      </td>
                      <td class="whitespace-nowrap text-center py-4 px-3 text-sm primary-color" :id="`cancel_order_pricetype_${idx}`">{{
                        item.priceType
                      }}</td>
                      <td class="whitespace-nowrap text-center py-4 px-3 text-sm primary-color" :id="`cancel_order_pcode_${idx}`">
                        <button  class="px-3 py-2 text-[10px] rounded cursor-default" :class="$common.getPcodeClass(item.product)" :style="item.netQty == '0'? 'background-color:#ededed;color:#a2a2a2' : ''">{{ $common.getPcode(item) }}</button>
                      </td>
                      <td class="whitespace-nowrap text-center py-4 px-3 text-sm primary-color" :id="`cancel_order_ret_${idx}`">{{ item.ret }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="flex flex-col lg:hidden">
              <div v-for="(item, idx) in selectedData" :key="idx" class="px-2 py-3 space-y-2 border-b last:border-0">
                  <div class="flex justify-between">
                      <span class="whitespace-nowrap text-sm primary-color">{{ item.tradingSymbol }}</span>
                      <span class="whitespace-nowrap text-right text-sm primary-color">{{ item.price }}</span>
                  </div>
                  <div class="flex justify-between">
                    <span class="whitespace-nowrap text-sm primary-color">{{ item.qty }} qty.</span>
                    <span><button  class="px-3 py-2 text-[10px] rounded cursor-default" :class="$common.getPcodeClass(item.product)" :style="item.netQty == '0'? 'background-color:#ededed;color:#a2a2a2' : ''">{{ $common.getPcode(item) }}</button></span>
                  </div>
                  <div class="flex justify-between">
                    <span>{{ item.priceType }}</span>
                    <span>{{ item.ret }}</span>
                  </div>
              </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="bg-gray-50 dark:bg-zinc-800 p-3 sm:flex sm:flex-row-reverse rounded-b-lg">
          <button :id="`cancel_order_confirm_btn`" type="submit"
            class="h-[34px] inline-flex items-center w-full justify-center rounded-md border border-transparent violet-bg px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">Confirm</button>
          <button :id="`cancel_order_cancel_btn`" @click="$store.commit('setCancelOrderDialog', false)" type="button"
            class="mt-3 h-[34px] inline-flex w-full justify-center items-center rounded-md border border-gray-300 bg-white dark:bg-gray-600 px-4 py-2 text-base font-medium primary-color shadow-sm hover:text-[#56585A] focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
        </div>
      </template>
    </DraggableDiv>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import DraggableDiv from '../../../components/dragable.vue'
export default {
components: {
  DraggableDiv
},
props: {
  selectedData: { type: Array },
},
methods: {
  async cancelOrderFromApi() {
    let cancelArr = []
    let boCoArr = []
    for await (const el of this.selectedData) {
      if (!el.snoOrderNumber) {
        cancelArr.push({ 'orderNo': el['orderNo'], "deviceNumber": this.getDeviceNumber  })
      } else if (el.snoOrderNumber) {
        boCoArr.push({
          orderNo: el.snoOrderNumber,
          orderType: el.orderType,
          deviceNumber: this.getDeviceNumber
        });
      }
    }
    if(cancelArr?.length) {
      this.$store.dispatch('order/cancelOrder', cancelArr)
    }
    if(boCoArr?.length) {
      this.$store.dispatch('order/exitBoCoPosition', boCoArr)
    }
  }
},
computed:{
    ...mapGetters(['getIsSmallView', 'getIsMobileView']),
    ...mapGetters('login', ['getDeviceNumber'])
},
}
</script>