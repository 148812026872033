<template>
    <ol class="flex flex-col border-t border-[#ededed] dark:border-[#252323] px-2">
        <li v-for="(item, idx) in listData" :key="idx"
            class="p-2 w-full flex flex-col gap-2 border-b border-[#ededed] dark:border-[#252323]"
            @click="toggleBottomSheet(item, idx)">
            <section class="flex justify-between primary-color">
                <span class="primary-color text-sm">{{ item.formattedInsName }}</span>
                <!-- <span class="text-right text-sm dark:text-[#94A3B8]">{{ item.scripName }}</span> -->
                <div class="flex justify-start items-center space-x-2">
                    <button class="w-[40px] cursor-default primary-color text-sm">{{ item.typeOfOrder }}</button>
                    <button :class="$common.getTypeTdClass(item.transType)" class="px-3 py-1 rounded cursor-default">
                        {{ item.transType == 'B' ? 'BUY' : 'SELL' }}
                    </button>
                </div>

            </section>
            <section class="flex items-center justify-between text-xs">
                <span class="primary-color text-sm">
                    Qty {{ item.qty }}
                </span>
                <span class="text-right text-sm primary-color">
                    <div v-if="item.typeOfOrder == 'Single'">
                        Price : {{ item.price }}
                    </div>
                    <div v-if="item.typeOfOrder == 'OCO'">
                        SL : {{ item.price }} /
                        Tar : {{ item.placeOrderParamsleg2.price }}
                    </div>
                </span>
            </section>
            <section class="flex justify-between text-xs">
                <span class="text-right text-sm primary-color">Created On {{ item?.orderTime.split(' ')[1] }}</span>
                <span class="text-right text-sm primary-color">
                    <div v-if="item.typeOfOrder == 'Single'">Trigger Price : {{ item.gttValue }}</div>
                    <div v-if="item.typeOfOrder == 'OCO'">
                        SL : {{ item.gttValue }} /
                        Tar : {{ item.placeOrderParamsleg2.triggerPrice }}
                    </div>
                </span>
            </section>
        </li>
    </ol>
    <BottomSheet page="holdings" v-if="isOpenBottomSheet" :isOpen="isOpenBottomSheet" @close="closeBottomSheet"
        :options="sheetOptions" :sheetData="sheetData" @call_action="callAction" />
</template>

<script setup lang="ts">
import { PropType, ref, defineAsyncComponent } from "vue"
const BottomSheet = defineAsyncComponent(() => import("../../../component/bottom-sheet.vue"))
defineProps({
    listData: {
        type: Array as PropType<any[]>,
        required: true
    }
})
const isOpenBottomSheet = ref(false)
const sheetData = ref()
const toggleBottomSheet = async (data: any, index: Number) => {
    await setBottomSheetOptions()
    sheetData.value = { data, index }
    isOpenBottomSheet.value = !isOpenBottomSheet.value
}
const sheetOptions = ref()
const setBottomSheetOptions = async () => {
    sheetOptions.value = ["Modify", "Cancel"]
}
const closeBottomSheet = () => {
    isOpenBottomSheet.value = false
}
const emits = defineEmits(['call_action'])
const callAction = (data: any) => {
    emits('call_action', data)
}
</script>