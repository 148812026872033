import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification()

const state = {
    freezeStatus:'',
    otpLoader:false,
    stage: 'freeze_acc'
}

const actions = {
    async accFreezeStatus({state, commit, dispatch, rootGetters }) {
        try {
            state.freezeStatus = ''
            let json = {
                "userId": rootGetters['login/getUCC'],
            }
            let resp = await commonService.accFreezeStatus(json)
            if (resp.data.status == "Ok" && resp.data.message && resp.status == 200) {
                state.freezeStatus = resp.data.message
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async sendFreezeOTP({state, commit, dispatch, rootGetters }) {
        commit('setOtpLoader', true)
        try {
            let json = {
                "userId": rootGetters['login/getUCC'],
                "source": "WEB",
                "freeze": state?.freezeStatus?.toLowerCase() == 'active' ? 1 : 0
            }
            let resp = await commonService.sendFreezeOTP(json)
            if (resp.data.status == "Ok" && resp.data.message && resp.status == 200) {
                commit('setStage', 'freeze_otp')
            }else{
                notify({ group: 'auth', type: 'error', title: `${resp.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        commit('setOtpLoader', false)
    },

    async freezeOTPVerify({state, commit, dispatch, rootGetters }, payload) {
        commit('setOtpLoader', true)
        try {
            let json = {
                "userId": rootGetters['login/getUCC'],
                "source": "WEB",
                "freeze": state?.freezeStatus?.toLowerCase() == 'active' ? 1 : 0,
                "reason": payload.reason,
                "otp": payload.otp
            }
            let resp = await commonService.freezeOTPVerify(json)
            if (resp.data.status == "Ok" && resp.data.message && resp.status == 200) {
                await dispatch('accFreezeStatus')
                commit('setStage', 'freeze_acc')
            }else{
                notify({ group: 'auth', type: 'error', title: `${response.data.message}` })
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        commit('setOtpLoader', false)
    },
};

const mutations = {
    setOtpLoader(state, payload){
        state.otpLoader = payload
    },
    setStage(state, payload){
        state.stage = payload
    }
};

const getters = {
};

const freeze = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default freeze