<template>
    <ol class="flex flex-col border-t border-[#ededed] dark:border-[#252323]">
        <li v-for="(item, idx) in listData" :key="idx"
            class="p-2 w-full flex flex-col gap-2 border-b border-[#ededed] dark:border-[#252323]"
            @click="toggleBottomSheet(item, idx)">
            <section class="flex items-center justify-between">
                <div class=" gap-2  justify-start items-center">
                    <div class="">{{ item.symbol }}
                        <span class="ml-1 text-[10px] text-blue-600">{{ item.exchange }}</span>
                    </div>
                    <div class="flex absolute right-0 bottom-3" v-if="currentIndex == idx">
                        <!--<button @click="callOW(item, 0, 'buy')" 
                                                class="rounded text-[14px] green-bg text-white h-6 mr-2 min-w-[34px]">B</button>
                                                <button @click="callOW(item, 1, 'sell')"
                                                class="rounded red-bg text-white text-[14px] h-6 mr-2 min-w-[34px]">S</button> -->
                    <button id="mw_list_chart" type="button" class="h-6 w-8 mr-2 inline-block bg-white dark:bg-zinc-900 border-1 border-[#753ED7] text-[#753ED7] font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    transition
                    duration-150
                    ease-in-out
                    hover:bg-[#753ED7]
                    highlightHover
                    border
                  " v-tippy="{ content: `Charts`, classes: 'tooltipCusClass' }" @click="$common.callChart(item)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="w-5 mx-auto changeColorHover">
                                <path
                                    d="M11.25 4.5V5.625H14.9546L10.125 10.4546L7.71019 8.03981C7.6047 7.93436 7.46165 7.87512 7.3125 7.87512C7.16335 7.87512 7.0203 7.93436 6.91481 8.03981L1.125 13.8296L1.92037 14.625L7.3125 9.23287L9.72731 11.6477C9.8328 11.7531 9.97585 11.8124 10.125 11.8124C10.2742 11.8124 10.4172 11.7531 10.5227 11.6477L15.75 6.42037V10.125H16.875V4.5H11.25Z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="flex items-end gap-1">
                    <span>Day Change :</span>
                    <span class="text-end"
                        :class="{ 'green-color': item['pchg'] > 0, 'red-color': item['pchg'] < 0 }">{{ item.pchg ?
                            item.pchg : '0.00' }}%</span>
                </div>
            </section>
            <section class="flex justify-between items-center text-xs">
                <span class="primary-color flex gap-1">
                    <div class="flex space-x-2">
                        <div>
                            <span class="primary-color">O : </span>
                            <span class="primary-color"> {{
                                $common.ruppesFormat(parseFloat(item.o).toFixed(item.exchange ==
                                    "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                        </div>
                        <div>
                            <span class="primary-color">H : </span>
                            <span>{{ $common.ruppesFormat(parseFloat(item.h).toFixed(item.exchange ==
                                "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                        </div>
                    </div>
                    <!-- <span class="flex gap-1">
                        <span>LTP :</span>
                        <span> {{ item.lp ? item.lp : '0.00' }}</span>
                    </span> -->
                </span>
                <span class="primary-color flex gap-1">
                    <div class="flex gap-1">
                        <div class="flex space-x-2">
                            <div>
                                <span class="primary-color">L : </span>
                                <span>{{ $common.ruppesFormat(parseFloat(item.l).toFixed(item.exchange ==
                                    "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                            </div>
                            <div>
                                <span class="primary-color">C : </span>
                                <span>{{ $common.ruppesFormat(parseFloat(item.c).toFixed(item.exchange ==
                                    "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                            </div>
                        </div>
                    </div>
                </span>
            </section>
            <section class="flex justify-between items-center text-xs">
                <span class="primary-color flex gap-1">
                    <span class="flex gap-1">
                        <span>LTP :</span>
                        <span>{{ $common.ruppesFormat(parseFloat(item.lp).toFixed(item.exchange ==
                            "CDS" || item.exchange == "BCD" ? 4 : 2)) }}</span>
                    </span>
                </span>
                <span class="primary-color flex gap-1">
                    <div class="flex gap-1">
                        <div class="flex space-x-2">
                            <div>
                                <span class="primary-color">V : </span>
                                <span class="primary-color">{{ $common.ruppesFormat(item.v) }}</span>
                            </div>
                        </div>
                    </div>
                </span>
            </section>
        </li>
    </ol>
    <BottomSheet page="Etf" v-if="isOpenBottomSheet" :isOpen="isOpenBottomSheet" @close="closeBottomSheet"
        :options="sheetOptions" :sheetData="sheetData" @call_action="callAction" />
</template>

<script setup lang="ts">
import { defineAsyncComponent, PropType, ref } from "vue";
defineProps({
    listData: {
        type: Array as PropType<any[]>,
        required: true
    }
});
const BottomSheet = defineAsyncComponent(() => import("../../component/bottom-sheet.vue"))
const sheetData = ref();
const currentIndex = ref(-1);
const isOpenBottomSheet = ref(false);

const toggleBottomSheet = async (data: any, index: Number) => {
    await setBottomSheetOptions()
    sheetData.value = { data, index }
    isOpenBottomSheet.value = !isOpenBottomSheet.value
}
const sheetOptions = ref()
const setBottomSheetOptions = async () => {
    sheetOptions.value = ['Buy', 'Sell', 'Chart'];
}
const closeBottomSheet = () => {
    isOpenBottomSheet.value = false
}
const emits = defineEmits(['call_action']);
// const getAmount = (data: any) => {
//     if (!Array.isArray(data.bids)) return 0
//     const sum = data.bids.reduce((a: any, b: any) => a + Number(b.amount), 0)
//     return sum
// }
const callAction = (data: any) => {
    emits('call_action', data);
}
</script>