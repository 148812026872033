<template>
    <div v-if="!getIsMobileView">
        <div v-if="gttList.length != 0 && !getLoader">
        <!-- <table_head_section class="mt-[2px]" :isSearch="true" :isCreate="true" :data="gttList" :name="`GTT ${gttList.length ? `(${gttList.length})` : ''}`">
        </table_head_section> -->
        <div  class="flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative ring-black ring-opacity-5 overflow-y-auto" >
                        <table class="min-w-full table-fixed divide-y divide-gray-300 dark:divide-[#232325] rounded-b border-t border-[#ededed] dark:border-[#232325] table-common">
                            <thead class="border-b dark:border-[#9c9cb1]">
                                <tr>
                                    <th id="gtt_th_created_on" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Created On</th>
                                    <th id="gtt_th_name" scope="col" class="p-3 text-center text-xs font-semibold secondary-color">Type</th>
                                    <th id="gtt_th_symbol" scope="col" class="p-3 text-left text-xs font-semibold secondary-color">Instrument</th>
                                    <th id="gtt_th_status" scope="col" class="p-3 text-right text-xs font-semibold secondary-color">Qty.</th>
                                    <th id="gtt_th_gtt_type" scope="col" class="p-3 text-right text-xs font-semibold secondary-color">Price</th>
                                    <th id="gtt_th_status" scope="col" class="p-3 text-right text-xs font-semibold secondary-color">Trigger Price</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="(gtt, idx) in getSearch == '' ? gttList : filterData" :key="idx" @mouseover="currentIndex = idx"
                                    @mouseleave="currentIndex = -1;" class="border-b border-[#ededed] dark:border-[#232325] h-[56px]">
                                    <td :id="`gtt_td_created_on_${idx}`" class="p-3 text-left text-sm relative primary-color">{{ gtt.orderTime.split(' ')[1] }}</td>
                                    <td :id="`gtt_td_name_${idx}`" :name="gtt?.gttName" class="p-3 text-center text-sm relative primary-color">
                                        <button class="mx-2 w-[40px] cursor-default">{{ gtt.typeOfOrder }}</button>
                                        <button :class="$common.getTypeTdClass(gtt.transType)" class="px-3 py-1 rounded cursor-default">{{  gtt.transType == 'B' ? 'BUY' : 'SELL' }}</button>
                                    </td>
                                    <td :id="`gtt_td_symbol_${idx}`" class="p-3 text-left text-sm primary-color relative">
                                        <span class="mb-2">{{ gtt.formattedInsName }}</span>
                                        <span class="absolute right-0 whitespace-nowrap" v-if="currentIndex == idx">
                                            <button :id="`gtt_td_modify_${idx}`" @click="modifyGtt(gtt)"
                                                class="px-3 py-1 text-xs text-white mr-2 green-bg rounded">Modify</button>
                                            <button :id="`gtt_td_cancel_${idx}`" @click="deleteGtt(gtt)"
                                                class="px-3 py-1 text-xs text-white mr-2 red-bg rounded">Delete</button>
                                        </span>
                                    </td>
                                    <td class="text-right p-3 text-sm primary-color">
                                        <!-- {{ gtt.qty }} -->
                                        <div v-if="gtt.typeOfOrder == 'Single'">{{ gtt.qty }}</div>
                                        <div v-if="gtt.typeOfOrder == 'OCO'">
                                            {{ gtt.placeOrderParamsleg2.quantity }} / 
                                            {{ gtt.placeOrderParams.quantity }}
                                        </div>
                                    </td>
                                    <td class="text-right p-3 text-sm relative primary-color">
                                        <div v-if="gtt.typeOfOrder == 'Single'">{{ gtt.price }}</div>
                                        <div v-if="gtt.typeOfOrder == 'OCO'">
                                            SL : {{ gtt.placeOrderParamsleg2.price }} / 
                                            Tar :  {{ gtt.placeOrderParams.price }}
                                        </div>
                                    </td>
                                    <td class="text-right p-3 text-sm primary-color">
                                        <div v-if="gtt.typeOfOrder == 'Single'">{{ gtt.gttValue }}</div>
                                        <div v-if="gtt.typeOfOrder == 'OCO'">
                                            SL : {{ gtt.placeOrderParamsleg2.triggerPrice }} / 
                                            Tar :  {{ gtt.placeOrderParams.triggerPrice }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div v-else-if="!getLoader && gttList.length == 0" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
            <nodata type="GTT Orders"/>
        </div>
    </div>
    <mobileView v-else-if="gttList.length" :listData="gttList" @call_action="mobileActions"/>
    <div v-else-if="!getLoader" class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
        <nodata type="GTT Order"/>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import table_head_section from '../../../component/table-head-section.vue'
import nodata from '../../no-data.vue'
import mobileView from './mobile-view.vue'
export default {
    data() {
        return {
            currentIndex: -1,
        }
    },
    computed: {
        ...mapState('gtt', ['gttList']),
        ...mapGetters(['getSearch', 'getLoader','getIsMobileView']),
        filterData() {
            return this.gttList.filter((post) => {
                return (
                    post.formattedInsName?.toLowerCase()?.includes(this.getSearch?.toLowerCase())
                );
            });
        },
    },
    components: { table_head_section, nodata,mobileView },
    methods: {
        deleteGtt(data) {
            this.$store.commit("gtt/setIsCancelGtt", true)
            this.$store.commit('gtt/setGttCancelScrip', data)
        },
        async modifyGtt(item) {
            this.$store.commit('ow/setCurrentPage', 'MkWatch')
            this.$store.state.buysellpopup.bsselected = item.transType == 'B' ? 0 : 1,
                this.$store.commit('ow/setIsAddOrExit', 'gttModify')
            this.$store.commit('ow/setIsLocalPreference', false)
            this.$store.commit('ow/setCurrentScripData', item)
            await this.$store.dispatch('ow/getContractInfo', { exchange: item.exchange, token: item.token, from: 'gtt' })
            this.$store.dispatch('ow/setDefaultValues')
        },
        mobileActions(data) {
            let item = data.data.item
            if(data.action == 'Modify') {
                this.modifyGtt(item, 'modify')
            } else if(data.action == 'Cancel') {
                this.deleteGtt(item, 'cancel')
            } 
        }
    }
}
</script>

<style>
.gtt-btn {
    @apply px-3 py-2 text-[12px] rounded cursor-default min-w-[100px] font-medium
}
.gtt-btn.enabled {
    @apply bg-lime-50 text-lime-500
}
.gtt-btn.disabled {
    @apply bg-orange-50 text-orange-500
}
</style>