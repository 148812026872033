<style>
/* canvas.height{
  height: 60px;
} */
</style>
<template>
  <div>
    <!-- chart -->
    <div class="w-full">
      <canvas
        id="chart-area"
        width="100%"
        class="h-[60px]"
        ref="myChart"
      ></canvas>
    </div>
    <!-- radio group   -->
    <div>
      <fieldset class="mt-4">
        <div
          class="
            space-y-4
            sm:flex sm:items-center sm:space-y-0 sm:space-x-5
            justify-end
          "
        >
          <div
            v-for="(item, id) in chartType"
            :key="id"
            class="flex items-center"
          >
            <label
              :for="item.id"
              class="
                text-xs
                font-medium
                secondary-color
                flex
                items-center
                cursor-pointer
              "
            >
              <input
                v-model="chartTypeModel"
                @change="setChartData"
                :value="item.value"
                :id="item.id"
                name="notification-method"
                type="radio"
                class="
                  cursor-pointer
                  mr-2
                  h-4
                  w-4
                  text-sm
                  border-gray-300
                  violet-color
                  accent-[#753ED7]
                "
              />
              {{ item.title }}</label
            >
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<script>
import {
  Chart,
  registerables,
  BarElement,
  BarController,
  CategoryScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from "chart.js";
Chart.register(...registerables);
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
Chart.register(
  TreemapController,
  TreemapElement,
  BarElement,
  BarController,
  CategoryScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);
import { mapGetters, mapState } from "vuex";
import ChartH from 'chart.js/auto';

function colorFromRaw(ctx) {
  if (ctx.type !== "data") {
    return "transparent";
  }
  const value = ctx.raw.v;
  let alpha = (1 + Math.log(value)) / 5;
  const color = [
    [45, 174, 247],
    [255, 153, 0],
    [255, 87, 97],
    [167, 98, 255],
    [17, 219, 247],
  ];
  const r = color[ctx.dataIndex][0],
    g = color[ctx.dataIndex][1],
    b = color[ctx.dataIndex][2];
  let colorCode = "rgba(" + r + "," + g + "," + b + "," + 1 + ")";
  return colorCode;
}
let chartCanvas;
export default {
  data() {
    return {
      height: "60px",
      chartType: [
        { id: "Current value", title: "Current value", value: "currentValue" },
        {
          id: "Investment value",
          title: "Investment value",
          value: "investmentValue",
        },
        { id: "P&L", title: "PNL", value: "pnl" },
      ],
      chartTypeModel: "currentValue",
    };
  },
  props: ["holdings"],
  methods: {
    colorFromRaw(ctx) {
      if (ctx.type !== "data") {
        return "transparent";
      }
      const color = this.holdingsChartData['color']
      const r = color[ctx.dataIndex][0],
        g = color[ctx.dataIndex][1],
        b = color[ctx.dataIndex][2];
      let colorCode = "rgba(" + r + "," + g + "," + b + "," + 1 + ")";
      return colorCode;
    },

    setChartData() {
      if (chartCanvas) {
        chartCanvas.destroy();
      }
      var ctx = document.getElementById("chart-area");
      let DATA = this.holdingsChartData[this.chartTypeModel]
      let label = this.chartTypeModel == 'investmentValue' ? 'Investment Value' : this.chartTypeModel == 'currentValue' ? 'Current value' : 'P&L'
      // new impl
      const config = {
        type: "treemap",
        data: {
          datasets: [
            {
              label: label,
              tree: DATA,
              key: 'value',
              borderWidth: 0,
              // borderRadius: 4,
              spacing: -0.5,
              // backgroundColor(ctx) {
              //   if (ctx.type !== 'data') {
              //     return 'transparent';
              //   }
              //   return ctx.raw._data.color;
              // },
              backgroundColor(ctx) {
                if (ctx.type !== 'data') {
                   return 'transparent';
                }
                return ctx.raw._data.value1 >= 0 ? ctx.raw._data.color : 'rgb(227,66,66)';
              },
              labels: {
                display: false,
                formatter(ctx) {
                  if (ctx.type !== 'data') {
                    return;
                  }
                  return [ctx.raw._data.name + ` (${ctx.raw.v})`];
                },
                font: [{ size: 10,}],
              },
              rtl: false
            }
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title() {
                  return label
                },
                label(item) {
                  const dataItem = item.raw;
                  const obj = dataItem._data;
                  const name = dataItem._data.name;
                  const value = dataItem._data.value1;
                  return name + ': ' + value;
                }
              }
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                }
              },
              pan: {
                enabled: true,
              }
            },
            scales: {
              x: {
                display: false
              },
              y: {
                display: false
              }
            }
          },
        }
      };

      chartCanvas = new Chart(ctx,config)
    },

    getRandomColor() {
      const myArray = ['rgb(0, 43, 91)', 'rgb(26, 95, 122)', 'rgb(21, 152, 149)', 'rgb(87, 197, 182)','rgb(65,105,225)','rgb(8,146,208)','rgb(96,80,220)'];
      const randomIndex = Math.floor(Math.random() * myArray.length);
      const randomElement = myArray[randomIndex];
      return randomElement;
    }
   },
  unmounted() {},
  computed: {
    ...mapState("holdings", ["holdingsChartData"]),
  },
  mounted() {
    this.setChartData();
  },
  created() {},
};
</script>

<style>
#chart-area {
  opacity: 0.8;
}
</style>
